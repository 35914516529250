import{ useContext, useState, useEffect, useCallback } from 'react';
import { usePrevious } from '../../hooks.js';
import AppContext from '../../AppContext.js';
import api from '../../api.js';

import { Time, Form, FilterForSelect } from '../../types/types';

const useFetchCustomEvents = () => {
  const {
    query,
  } = useContext(AppContext);

  const [availableCustomEvents, setAvailableCustomEvents] = useState<string[] | undefined>();
  const [customEventsLoading, setCustomEventsLoading] = useState(false);
  const [customEventsError, setCustomEventsError] = useState<string | null>(null);

  const {
    time,
    form,
    filters,
  }: {
    time:Time,
    form: Form,
    filters: FilterForSelect[],
  } = query || {};

  const prevForm = usePrevious(form);
  const prevTime = usePrevious(time);
  const prevFilters = usePrevious(filters);

  const fetchCustomEvents = useCallback(async () => {
    try {
      setCustomEventsLoading(true);
      setCustomEventsError(null);

      const {data: {events: availableCustomEvents}} = await api.get('/visualisations/custom-events-chart', { // TODO: consider a new endpoint to fetch custom event types only
        params: {
          formUuid: form?.uuid,
          startTime: time?.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          endTime: time?.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          filter: filters?.reduce((acc, {key, label: value}) => {
            if (!acc.hasOwnProperty(key)) acc[key] = [];
            acc[key].push(value);
            return acc;
          }, {}),
        },
      });
      setAvailableCustomEvents(
        availableCustomEvents
          .map(event => ({label: event, value: event}))
      );
    } catch (e) {
      setCustomEventsError('Error when fetching custom events');
    } finally {
      setCustomEventsLoading(false);
    }
  }, [form, time, filters]);

  // Initial load
  useEffect(() => {
    // First loaded page after login - so wait for default time to be set
    if ((prevForm?.uuid && form?.uuid && (prevForm.uuid === form.uuid)) && (!prevTime && time?.start)) {
      fetchCustomEvents();
    }

    // Moved to page from another
    if ((!prevForm && form?.uuid) && (!prevTime && time?.start)) {
      fetchCustomEvents();
    }
  }, [form, time, fetchCustomEvents, prevForm, prevTime]);

  // Form changed
  useEffect(() => {
    // Form changed
    if (form?.uuid && prevForm?.uuid && (prevForm.uuid !== form.uuid)) {
      fetchCustomEvents();
    }
  }, [form?.uuid, fetchCustomEvents, prevForm]);

  // Time changed
  useEffect(() => {
    if (time?.start && time?.end && prevTime?.start && prevTime?.end &&
      (prevTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') ||
      prevTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'))
    ) {
      fetchCustomEvents();
    }
  }, [time, fetchCustomEvents, prevTime]);

  // Filters changed
  useEffect(() => {
    if (form?.uuid && time?.start && // Required
    ((!prevFilters && filters?.length) || // Filters first set
    (prevFilters?.length && !filters?.length) || // Filters have been removed
    (!prevFilters?.length && filters?.length) || // Filters have been added
    (prevFilters?.length !== filters?.length) // Filters have changed
    )) {
      fetchCustomEvents();
    }

  }, [form?.uuid, time?.start, filters, prevFilters, fetchCustomEvents]);

  return {customEventsLoading, customEventsError, availableCustomEvents};
};

export default useFetchCustomEvents;
