import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import BuilderContext from './Context';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import SubmissionsTable from './SubmissionsTable';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Form from 'react-bootstrap/Form';
import moment from 'moment-timezone';
import { DateTime } from 'luxon';
import api from '../api';
import {
  useHistory,
  Link,
} from "react-router-dom";
import AppAlerts from '../Components/AppAlerts';
import { VscCheck, VscWarning, VscInfo } from 'react-icons/vsc';
import { GrDocumentCsv } from 'react-icons/gr';
import SubmissionsExportModal from './SubmissionsExportModal';
import FeedbackRow from '../Components/FeedbackRow';
import { keyToUse } from './form-components/utils';
import { addressFieldIds } from './form-components/components/fields/Address';
import { slugify } from './form-components/utils';
import DOMPurify from 'dompurify';
import { forbidConfig } from './form-components/helpers/sanitizer';
import { orgDetailsForMixpanel } from '../utils';

import './Submissions.scss';

const Submissions = ({mixpanel}) => {
  const {
    form, formsError, setForm,
  } = useContext(BuilderContext);
  const [tableLoading, setTableLoading] = useState(true);
  const [tableLoadingProgress, setTableLoadingProgress] = useState(20);
  const [tableError, setTableError] = useState(null);
  const [table, setTable] = useState();
  const [totalCurrentMonthSubmissions, setTotalCurrentMonthSubmissions] = useState();
  const [showExportDetails, setShowExportDetails] = useState();
  const [deleteSubmissionsError, setDeleteSubmissionsError] = useState();
  const [deleteSubmissionsSuccess, setDeleteSubmissionsSuccess] = useState();
  const [isDeletingSubmissions, setIsDeletingSubmissions] = useState();
  const [queue, setQueue] = useState([]);
  const [formIsSaving, setFormIsSaving] = useState();
  const [emailToggleError, setEmailToggleError] = useState();
  const history = useHistory();


  const handleRemoveDeleteSubmissionsMessages = useCallback(() => {
    setDeleteSubmissionsError(null);
    setIsDeletingSubmissions(false);
  }, []);

  const handleDeleteSubmissions = useCallback(async ({submissions}) => {
    const newTableData = [...table.data];

    try {
      setDeleteSubmissionsError(null);
      setDeleteSubmissionsSuccess(null);
      setIsDeletingSubmissions(true);

      // get submissions array to up date
      const responses = await Promise.allSettled(submissions
        .map(async ({savedAtInUnix}) => {
          return await api.delete(`/builder/forms/${form.uuid}/submissions`, { params: {
            savedAt: savedAtInUnix,
          }});
        }));

      const rejectedResponses = responses.filter(r => r.status === 'rejected');
      const successResponses = responses.filter(r => r.status === 'fulfilled');


      if (successResponses.length) {
      // Remove all deleted submissions
        const deletedSubmissionsSavedAt = successResponses.map(r => r.value.config.params.savedAt);
        setTable(prev => ({...prev, data: newTableData.filter(d => !deletedSubmissionsSavedAt.includes(d.savedAtInUnix))}));
      }
      if (!rejectedResponses.length) {
        setDeleteSubmissionsSuccess(true);
      }
      if (rejectedResponses.length === submissions.length) {
        setDeleteSubmissionsError('Sorry, something went wrong. No submissions were deleted. Please try again.');
      }
      if (rejectedResponses.length && successResponses.length) {
        setDeleteSubmissionsError('Sorry, something went wrong. Not all submissions were deleted. Please refresh the page and try again.');
      }
      mixpanel.track('Deleted Submissions', {page: 'Submissions'});
    } catch (e) {
      setDeleteSubmissionsError('Sorry, something went wrong deleting submissions. Please try again.');
    } finally {
      setIsDeletingSubmissions(false);
    }
  }, [table, form?.uuid, mixpanel]);

  const handleSubmissionEmailToggle = useCallback(async () => {
    const updatedForm = JSON.parse(JSON.stringify(form));
    updatedForm.submissionReceivedEmailEnabled = !updatedForm.submissionReceivedEmailEnabled;
    setQueue(prev => (prev || []).concat({
      updatedForm,
    }));

    setForm(prev => ({...prev, submissionReceivedEmailEnabled: !prev.submissionReceivedEmailEnabled}));
  }, [form, setForm]);

  // Process the queue of changes
  useEffect(() => {
    if (queue?.length > 0 && !formIsSaving) {
      const {
        updatedForm,
      } = queue[0];

      (async () => {
        try {
          setEmailToggleError(null);
          setFormIsSaving(true);

          await api.put(`/builder/forms/${form?.uuid}`, {
            builderForm: updatedForm,
          });

          mixpanel.track('Changed Submission Received Email', {page: 'Submissions', ...orgDetailsForMixpanel(form?.organisation)});
        } catch (e) {
          const defaultError = 'Something went wrong saving the email notification setting.';
          if (e?.response) {
            switch (e.response?.status) {
            case 401:
              setEmailToggleError('Not logged in');
              break;
            case 403:
              history.replace('/builder/forms');
              break;
            default:
              setEmailToggleError(defaultError);
            }
          } else {
            setEmailToggleError(defaultError);
          }
        } finally {
          setQueue(prev => prev.slice(1));
          setFormIsSaving(false);
        }
      })();
    }
  }, [formIsSaving, queue, form?.uuid, history, mixpanel, form?.organisation]);

  useEffect(() => {
    if (form?.uuid && form.uuid !== 'new') (async () => {
      const progressID = setInterval(() => setTableLoadingProgress((prevProgress) => prevProgress + 30), 50);
      try {
        setTableError(null);
        setTableLoading(true);

        const { data: { submissions = [] } } = await api.get(`/builder/forms/${form.uuid}/submissions`);

        // Get all expected question keys
        const allQuestionKeys = form.builderSteps?.map(step => step.builderQuestions?.filter(q => q.componentType !== 'paragraphText')
          .map((question) => question.key || question.title)).flat();

        // Make sure only unique question keys are then provided
        const uniqueOrderedQuestionKeyLabels = Object.fromEntries(
          form.builderSteps
            .flatMap((s, stepIndex) => s.builderQuestions?.filter(q => q.componentType !== 'paragraphText')
              .flatMap((q, questionIndex) => {
                const key = keyToUse({key: q.key, title: q.title, questionKeys: allQuestionKeys, questionIndex, stepIndex});
                // Address component input ids use additional characters
                switch (q.componentType) {
                case 'address':
                  const [line1Id, line2Id, townCityId, postcodeId] = addressFieldIds(key);
                  return [
                    [line1Id, q.subLabels?.addressLine1 || 'Address 1'],
                    [line2Id, q.subLabels?.addressLine2 || 'Address 2'],
                    [townCityId, q.subLabels?.addressLevel1 || 'Town or city'],
                    [postcodeId, q.subLabels?.postalCode || 'Postcode/ZIP code']
                  ];
                case 'checkbox':
                  return [[slugify(key), DOMPurify.sanitize(decodeURI(q.title || ''), forbidConfig)]];
                default:
                  return [[slugify(key), q.title]];
                }
              })));

        setTableLoadingProgress(100);

        const currentMonth = moment.utc().month();
        let currentMonthSubmissionsCount = 0;
        for (const s of submissions) {
          if (moment(Number(s.saved_at)).utc().month() === currentMonth) currentMonthSubmissionsCount += 1;
        }
        setTotalCurrentMonthSubmissions(currentMonthSubmissionsCount);

        const customColumns = submissions.reduce((acc, item) => {
          const keys = Object.keys(item);
          for(const key of keys) {
            if (!acc.includes(key)) acc.push(key);
          }
          return acc;
        }, []).filter(c => (c !== 'saved_at' && c !== 'form_uuid'));

        setTable({
          data: submissions
            .map(({
              form_uuid, // Don't need formUuid to be displayed
              saved_at, // NB. We can't filter on formatted value but sort on original value. So we only allow sorting on original value
              ...rest}
            ) => ({...rest, savedAt: saved_at, savedAtInUnix: saved_at})),
          columns: [
            {
              Header: 'Saved at (UTC)',
              accessor: 'savedAt',
              Cell: (cell) => (cell.value === null || cell.value === undefined) ? cell.value :
                DateTime.fromMillis(Number(cell.value)).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS),
            },
            ...[...new Set([...Object.keys(uniqueOrderedQuestionKeyLabels), ...customColumns])].map(key => ({
              Header: uniqueOrderedQuestionKeyLabels[key] || key, // Display the label
              accessor: key,
            })),
          ],
        });
      } catch (e) {
        setTableLoadingProgress(100);
        const defaultError = 'Sorry, something went wrong loading submissions. Please refresh the page.';
        if (e?.response) {
          switch (e.response?.status) {
          case 401:
            setTableError('Not logged in');
            break;
          case 403:
            history.replace('/builder/forms');
            break;
          default:
            setTableError(defaultError);
          }
        } else {
          setTableError(defaultError);
        }
      } finally {
        setTableLoadingProgress(0);
        clearInterval(progressID);
        setTableLoading(false);
      }
    })();
    if (form?.uuid === 'new') setTableLoading(false);
  }, [form?.uuid, form?.builderSteps, history]);

  useEffect(() => {
    mixpanel?.track('Page View', { page: 'Submissions', product: 'builder' });
  }, [mixpanel]);

  return (
    <div className="submissions-page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Form Builder | Submissions</title>
      </Helmet>
      <Col className="center-column">
        <div className="pt-2">
          <FeedbackRow
            classList={['allow-scroll-under-nav']}
            mixpanel={mixpanel}
            page={'Submissions'}
            messageContent={'submissions'} />
        </div>
        <AppAlerts showBuilderOrgAlert={true} />
        {form && !form.published &&
          <Alert variant="info" className="mt-3" closeVariant="white">
            <div className="alert-svg-icon my-auto"><VscInfo size="20px" className="me-2"/></div>
            <p className="alert-text m-0">Please first pubilsh your form from the <Link to={`/builder/forms/${form.uuid}/build`}>Build page</Link>.</p>
          </Alert>}
        {emailToggleError &&
          <Row className="g-0">
            <Alert variant="danger" dismissible className="mb-3" closeVariant="white" onClose={() => setEmailToggleError(null)}>
              <div className="alert-svg-icon my-auto"><VscWarning size="20px"/></div>
              <p className="alert-text m-0">{emailToggleError}</p>
            </Alert>
          </Row>}
        <Card className="mt-3">
          <Card.Body>
            <Row className="g-0 card-title-row justify-content-between">
              <Col className="p-0 col-auto">
                <Card.Title as="h3">Submissions</Card.Title>
              </Col>
              <Col className="p-0 d-inline-flex align-items-center justify-content-end col-auto">
                <GrDocumentCsv size="18px" title="Download data as CSV" className="csv-export-icon data-loaded" onClick={() => setShowExportDetails(true)} />
              </Col>
            </Row>
            <div className="card-content d-flex flex-column">
              <Row className="g-0 justify-content-between">
                <Col className="p-0">
                  <p>View and filter all submissions received for your form.</p>
                </Col>
                <Col className="col-auto p-0 d-inline-flex email-toggle-container">
                  <Form.Label className="mb-0" htmlFor="email-toggle">Receive an email notification for each submission</Form.Label>
                  <Form.Switch
                    type="switch"
                    aria-label="Receive email for each submission"
                    className="p-0"
                    id="email-toggle"
                    checked={form?.submissionReceivedEmailEnabled}
                    onChange={() => handleSubmissionEmailToggle()}></Form.Switch>
                </Col>
              </Row>
              {formsError && <p className="p-3 text-center">{formsError}</p>}
              {tableError &&
                <div className="d-flex data-message align-items-center justify-content-center">
                  <p>{tableError}</p>
                </div>}
              {!tableError && !formsError &&
                <Alert variant="outline-success" show={deleteSubmissionsSuccess ? true : false}>
                  <div className="alert-svg-icon my-auto"><VscCheck size="26px"/></div>
                  <p className="alert-text m-0">Submissions deleted</p>
                </Alert>}
              {!formsError && tableLoading &&
              <div className="progress-area d-flex flex-grow-1">
                <ProgressBar className="my-auto" animated now={tableLoadingProgress}/>
              </div>}
              {!tableLoading && !tableError && !table?.data.length > 0 &&
              <div className="d-flex flex-column data-message align-items-center justify-content-center">
                <p className="mb-1">No submissions received for this form yet.</p>
                <p>Note: a submission can take around a minute to show in the table.</p>
              </div>}
              {!tableLoading && table?.data.length > 0 &&
                <SubmissionsTable
                  columns={table?.columns}
                  data={table?.data}
                  totalCurrentMonthSubmissions={totalCurrentMonthSubmissions}
                  handleDeleteSubmissions={handleDeleteSubmissions}
                  isDeletingSubmissions={isDeletingSubmissions}
                  deleteSubmissionsSuccess={deleteSubmissionsSuccess}
                  deleteSubmissionsError={deleteSubmissionsError}
                  handleRemoveDeleteSubmissionsMessages={handleRemoveDeleteSubmissionsMessages}
                />}
              <SubmissionsExportModal
                formUuid={form?.uuid}
                show={showExportDetails}
                setShow={setShowExportDetails}
                mixpanel={mixpanel}
              />
            </div>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};

export default Submissions;
