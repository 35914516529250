import React, { useState, useEffect, useContext } from 'react';
import AppContext from '../../AppContext';
import { SessionsContextType, ExplorerSession } from '../../types/sessions';
import { useAppQuery } from '../../hooks';
import api from '../../api';
import {
  labelForField,
  htmlAttrsForIdentifier,
} from '../../utils';

export const ExplorerContext = React.createContext<SessionsContextType | null>(null);

const ExplorerProvider: React.FC<{children: React.ReactNode}> = ({ children }) => {
  const [sessions, setSessions] = useState<ExplorerSession[]>();
  const [sessionsLoading, setSessionsLoading] = useState<boolean>(true);
  const [sessionsLoadingProgress, setSessionsLoadingProgress] = useState(20);
  const [sessionsError, setSessionsError] = useState<string | null>(null);
  const [customEventsInSessions, setCustomEventsInSessions] = useState(null);

  const {
    query,
    prevQuery
  } = useAppQuery();
  const {
    formsLoading,
    formsLoadingError,
  } = useContext(AppContext);

  const defaultError = 'Sorry, something went wrong loading the sessions data. Please refresh the page.';
  const noDataError = 'No data in selected timeframe, or with selected filters';

  // Fetch all sessions
  useEffect(() => {
    if (
      query?.form?.uuid && query?.time?.start && query?.time?.end && // Required
      ((prevQuery && ( // Differences relevant to this context
        (prevQuery?.form?.uuid !== query?.form?.uuid) ||
        (JSON.stringify(prevQuery.filters) !== JSON.stringify(query.filters)) ||
        (JSON.stringify(prevQuery.sessionOutcomes) !== JSON.stringify(query.sessionOutcomes)) ||
        (!prevQuery.time || (prevQuery.time.start !== query.time.start) ||
          (prevQuery.time &&
            (JSON.stringify(prevQuery.time.start) !== JSON.stringify(query.time.start) ||
            (JSON.stringify(prevQuery.time.end) !== JSON.stringify(query.time.end))))) ||
        (prevQuery.sessionFilters && (
          (prevQuery.sessionFilters.abandonedFieldIdentifier !== query.sessionFilters.abandonedFieldIdentifier) ||
          (prevQuery.sessionFilters.interactedWithFieldIdentifiers !== query.sessionFilters.interactedWithFieldIdentifiers) || // TODO: improve this match
          (prevQuery.sessionFilters.customEventTypes !== query.sessionFilters.customEventTypes) || // TODO: improve this match
          (prevQuery.sessionFilters.metrics !== query.sessionFilters.metrics) // TODO: improve this match
        )) ||
        (!prevQuery.sessionExplorer || (prevQuery.sessionExplorer && (
          prevQuery.sessionExplorer.includeCustomEvents !== query.sessionExplorer.includeCustomEvents))
        ))
      ))
    ) {
      (async () => {
        const progressID = setInterval(() =>
          setSessionsLoadingProgress((prevProgress) => prevProgress <= 100 ? prevProgress + 20 : prevProgress), 200);
        try {
          setSessionsLoading(true);
          setSessionsError(null);
          setCustomEventsInSessions(null);
          setSessions([]);

          const {
            form, time, filters, sessionOutcomes,
            sessionExplorer: {
              includeCustomEvents = null,
            },
            sessionFilters: {
              interactedWithFieldIdentifiers = null, abandonedFieldIdentifier = null, metrics = null, customEventTypes = null,
            } = {},
          } = query;
          const { data } = await api.get('/data/sessions', {
            params: {
              formUuid: form.uuid,
              time: {
                start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
                end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
              },
              limit: 30,
              attributes: filters?.reduce((acc, {key, label: value}) => {
                if (!acc.hasOwnProperty(key)) acc[key] = [];
                acc[key].push(value);
                return acc;
              }, {}),
              sessionOutcome: sessionOutcomes?.map(({value}) => value),
              ...abandonedFieldIdentifier && {abandonedFieldIdentifier},
              ...interactedWithFieldIdentifiers && {interactedWithFieldIdentifiers},
              ...metrics && {metrics},
              ...customEventTypes && {customEventTypes},
              ...includeCustomEvents && {include: ['flurries.custom_events']},
            }
          });
          const sessions: Session[] = data.sessions;
          setSessionsLoadingProgress(100);
          if (!sessions.length) setSessionsError(noDataError);

          const defaultAttributes = [
            'deviceType',
            'Operating System',
            'browserFamily',
            'Visitor Type',
            'autofillTriggered',
            'trafficMedium',
          ];

          setSessions(sessions.map(session => {
            session.defaultAttributes = defaultAttributes.reduce((acc, attribute) => {
              if (session.attributes[attribute]) acc[attribute] = session.attributes[attribute];
              return acc;
            }, {});
            session.customAttributes = Object.keys(session.attributes).sort()
              .filter(attribute => !defaultAttributes.includes(attribute))
              .reduce((acc, attribute) => {
                acc[attribute] = session.attributes[attribute];
                return acc;
              }, {});
            session.flurries = session.flurries
              .map(field => {
                if (field.type !== 'field') return field;
                field.label = field.fieldLabel;
                field = {...field, ...htmlAttrsForIdentifier(field.identifier)};
                return {...field, label: labelForField(field)};
              })
              .filter(field => field.hasOwnProperty('hidden') ? !field.hidden : true);
            return session;
          }).sort(({startTime: timeA}, {startTime: timeB}) => {
            return timeA - timeB;
          }));
          // TODO: use active duration instead of full duration

          if (includeCustomEvents && sessions.length > 0) setCustomEventsInSessions(sessions.some(s => s.flurries.some(f => f.type === 'custom_event')));
        } catch (e) {
          setSessionsLoadingProgress(100);
          switch (e?.response?.status) {
          case 401:
            setSessionsError('Not logged in');
            break;
          case 404:
            setSessionsError('Form not found');
            break;
          default:
            setSessionsError(defaultError);
          }
        } finally {
          clearInterval(progressID);
          setSessionsLoadingProgress(0);
          setSessionsLoading(false);
        }
      })();
    }
  }, [prevQuery, query]);

  useEffect(() => {
    if (!formsLoading && formsLoadingError) {
      setSessionsLoading(false);
      setSessionsError(formsLoadingError);
    }
  }, [formsLoading, formsLoadingError]);

  return <ExplorerContext.Provider value={{ sessions, setSessions, sessionsLoading, sessionsLoadingProgress, sessionsError, customEventsInSessions }}>{children}</ExplorerContext.Provider>;
};

export default ExplorerProvider;