import React, { useEffect, useContext, useState, useRef, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Select from 'react-select';
import { useHistory, useLocation, Link, useParams } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import TabContent from 'react-bootstrap/TabContent';
import TabPane from 'react-bootstrap/TabPane';
import TabContainer from 'react-bootstrap/TabContainer';
import Nav from 'react-bootstrap/Nav';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Table from 'react-bootstrap/Table';
import Modal from 'react-bootstrap/Modal';
import Popover from 'react-bootstrap/Popover';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import moment from 'moment-timezone';
import isCidr from 'is-cidr';
import { VscWarning, VscCheck, VscInfo } from "react-icons/vsc";
import { FaSpinner, FaInfoCircle } from "react-icons/fa";

import AppContext from '../../AppContext';
import AppAlerts from '../../Components/AppAlerts';
import FeedbackRow from '../../Components/FeedbackRow';
import OrgDetailsForm from '../../Components/OrgDetailsForm';
import OrgAgencyDetailsForm from '../../Components/OrgAgencyDetailsForm';
import OrgAgencyClientDetailsForm from '../../Components/OrgAgencyClientDetailsForm';
import OrgEditClientsTab from './OrgEditClientsTab';
import OrgEditSessionLimitsTab from './OrgEditSessionLimitsTab';
import ReadOnlyOrgItems from './ReadOnlyOrgItems';
import RoleSelect from './RoleSelect';
import NavBar from '../../NavBar';
import api from '../../api';
import { usePrevious } from '../../hooks';
import { getSession, orgDetailsForMixpanel } from '../../utils';
import Forms from '../../forms';

import './OrgEdit.scss';

// TODO: import FA and LA icons globally to avoid the square initial icon
import {faPlus, faCircleNotch, faTrashAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const validTabs = ['#details', '#users', '#forms', '#ip-filters', '#clients', '#sessionLimits'];
export const invalidTimeZones = ['ACT', 'AET', 'AGT', 'ART', 'AST', 'BET', 'BST', 'CAT', 'CNT', 'CST', 'CTT', 'EAT',
  'ECT', 'EST', 'HST', 'IET', 'IST', 'JST', 'MIT', 'MST', 'NET', 'NST', 'PLT', 'PNT', 'PRT', 'PST', 'SST', 'VST'];  // Invalid for ElasticSearch timezone data searches

const OrgEdit = ({mixpanel}) => {
  const {
    currentUser,
    currentOrg,
    setCurrentOrg,
    formsGroupedByOrg,
    query,
    setQuery, // Used to update time and form if an org's timezone is updated
  } = useContext(AppContext);
  const history = useHistory();
  const location = useLocation();
  const { uuid: orgUuid } = useParams();
  const prevLocationKey = usePrevious(location.key);

  const [orgError, setOrgError] = useState(null);
  const [orgLoading, setOrgLoading] = useState(null);
  const [org, setOrg] = useState(null);
  const [orgsLoading, setOrgsLoading] = useState(false);
  const [orgsError, setOrgsError] = useState(null);
  const [orgsByUuid, setOrgsByUuid] = useState({});
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [showErrorMessagesForField, setShowErrorMessagesForField] = useState();
  const [errorMessagesForField, setErrorMessagesForField] = useState(); // Object response with error messages per field
  const [errorMessages, setErrorMessages] = useState(); // Array of messages
  const [errorMessage, setErrorMessage] = useState(); // Standard catch all single message
  const [selectedTab, setSelectedTab] = useState(validTabs.includes(location.hash) ? location.hash : '#details');
  const [users, setUsers] = useState([]);
  const [usersLoading, setUsersLoading] = useState(false);
  const [usersError, setUsersError] = useState(null);
  const [showConfirmRemove, setShowConfirmRemove] = useState(false);
  const [userToRemove, setUserToRemove] = useState(null);
  const [userToRemoveError, setUserToRemoveError] = useState(null);
  const [showNewUserInput, setShowNewUserInput] = useState(false);
  const [newUser, setNewUser] = useState();
  const [newUserError, setNewUserError] = useState(null);
  const [userSuccessMessage, setUserSuccessMessage] = useState();
  const [forms, setForms] = useState([]);
  const [formsLoading, setFormsLoading] = useState(false);
  const [formsError, setFormsError] = useState(null);
  const [ipFilters, setIpFilters] = useState([]);
  const [originalIpFilters, setOriginalIpFilters] = useState([]);
  const [ipFiltersLoading, setIpFiltersLoading] = useState(false);
  const [ipFiltersError, setIpFiltersError] = useState(null);
  const [editedIpFilters, setEditedIpFilters] = useState(false);
  const [showIpFiltersSuccessMessage, setShowIpFiltersSuccessMessage] = useState(false);
  const [ipFiltersUpdateError, setIpFiltersUpdateError] = useState(null);
  const [showUnsavedIpFiltersMsg, setShowUnsavedIpFiltersMsg] = useState(false);
  const [sessionLimitsSuccessMessage, setSessionLimitsSuccessMessage] = useState(false);
  const [sessionLimitsErrorMessage, setSessionLimitsErrorMessage] = useState(false);
  const [currentUserIsStandard, setCurrentUserIsStandard] = useState();
  const [agencyForClient, setAgencyForClient] = useState();

  const pageTitleRef = useRef();

  const prevOrg = usePrevious(org);

  const handleResetErrorMessages = useCallback(() => {
    if (errorMessage) setErrorMessage(null);
    if (errorMessages) setErrorMessages(null);
    if (errorMessagesForField) setErrorMessagesForField(null);
    if (sessionLimitsErrorMessage) setSessionLimitsErrorMessage(null);
  },[errorMessage, errorMessages, errorMessagesForField, sessionLimitsErrorMessage]);

  const handleResetErrorMessagesForField = useCallback((field) => {
    if (errorMessagesForField?.[field]) setErrorMessagesForField(messages => {
      const newMessages = {...messages};
      delete newMessages[field];
      return newMessages;
    });
  },[errorMessagesForField]);

  const handleResetSuccessMessage = useCallback(() => {
    if (successMessage) setSuccessMessage(null);
    if (sessionLimitsSuccessMessage) setSessionLimitsSuccessMessage(null);
  },[successMessage, sessionLimitsSuccessMessage]);


  const clearOrgRelatedState = useCallback(() => {
    // Clear all input related to previous Org on change
    setNewUser(null);
    setShowNewUserInput(false);
    setNewUserError(null);
    setUserToRemoveError(null);
    setUserToRemove(null);
    setShowConfirmRemove(false);
    setUserSuccessMessage(null);
    handleResetErrorMessages();
    handleResetSuccessMessage();
    setShowIpFiltersSuccessMessage(false);
    setIpFiltersUpdateError(null);
    setAgencyForClient(null);
  }, [handleResetErrorMessages, handleResetSuccessMessage]);

  const displayType = (type) => {
    switch (type) {
    case 'Agency':
      return 'Agency';
    case 'AgencyClient':
      return 'Client';
    default:
      return 'Organisation';
    }
  };

  const orgDisplayType = displayType(org?.type);

  const handleSaveSessionLimits = useCallback(async (sessionLimitsByClient) => {
    try {
      setSessionLimitsErrorMessage(null);
      setSessionLimitsSuccessMessage(null);

      await Promise.all(Object.keys(sessionLimitsByClient)
        .map((orgUuid) => api.put(`/organisations/${orgUuid}`, {
          organisation: {
            sessionLimit: sessionLimitsByClient[orgUuid] || 0,
          },
        })));
      setOrg(prev => ({...prev,
        clients: [...prev.clients]
          .map(c => ({...c, sessionLimit: sessionLimitsByClient[c.uuid]})),
      }));

      setSessionLimitsSuccessMessage('Session limits saved.');
      mixpanel.track('Saved Session Limits', {
        page: 'OrgEdit',
        'Organisation Name': org.name,
        'Organisation Uuid': org.uuid,
        'Organisation Contract Type': org.contractType,
      });
    } catch (e) {
      setSessionLimitsErrorMessage('Something went wrong saving session limits. Please try again.');
    }
  }, [mixpanel, org?.name, org?.uuid, org?.contractType]);


  useEffect(() => {
    mixpanel.identify(currentUser.email);
    if (org && (!prevOrg || (prevOrg?.uuid !== org.uuid))) {
      mixpanel.track('Page View', {
        page: 'OrgEdit',
        'Organisation Name': org.name,
        'Organisation Uuid': org.uuid,
        'Organisation Contract Type': org.contractType,
      });
    }
  }, [mixpanel, currentUser.email, org, prevOrg]);

  // Fetch requested Org details
  useEffect(() => {
    const fetchOrg = async () => {
      try {
        setOrgLoading(true);
        setOrgError(null);
        const { data: { organisation, roles } } = await api.get(`/organisations/${orgUuid}`, {
          params: {
            include: ['clients'],
          },
        });
        // Consider any non-AM user without an 'admin' role as a 'standard' user
        setCurrentUserIsStandard(!currentUser.accountManager && roles.findIndex(r => (r.resourceUuid === orgUuid && r.name === 'admin')) < 0);
        setOrg(organisation);
      } catch (e) {
        setOrgError((e.response && (e.response.status === 404)) ? 'Organisation not found' :
          (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
      } finally {
        setOrgLoading(false);
      }
    };
    fetchOrg();
  }, [orgUuid, currentUser?.accountManager]);

  useEffect(() => {
    if (org) {
      // Set the app-wide organisation
      if ((!currentOrg && org) || (currentOrg?.uuid && org.uuid && (org.uuid !== currentOrg.uuid))) setCurrentOrg(org);
    }
  },[org, currentOrg, setCurrentOrg, formsGroupedByOrg]);

  // Load all available orgs for the user
  useEffect(() => {
    const fetchOrgs = async () => {
      try {
        setOrgsLoading(true);
        setOrgsError(null);
        const { data: { organisations } } = await api.get('/organisations');

        const orgsByUuid = {};
        for (const org of organisations) {
          if (!orgsByUuid.hasOwnProperty(org.uuid)) orgsByUuid[org.uuid] = org;
        }
        setOrgsByUuid(orgsByUuid);
        setOrgsLoading(false);
      } catch (e) {
        setOrgsError('Something went wrong.');
        setOrgsLoading(false);
      }
    };

    fetchOrgs();
  }, [currentUser]);

  // If this is an agency client, get the agency if the user an agency user
  useEffect(() => {
    if (org?.uuid && org?.type === 'AgencyClient') {
      // Find all Agencies for the user
      const agencies = currentUser.organisations.filter(o => o.type === 'Agency');

      // Fetch their clients to find which Agency this client belongs to
      if (agencies.length) (async () => {
        try {
          const responses = await Promise.all(agencies.map((a) => api.get(`/organisations/${a.uuid}`, {
            params: {
              include: ['clients'],
            },
          })));

          const clientsByUuid = {};

          for (const response of responses) {
            const agency = response.data.organisation;
            const clients = agency.clients;

            for (const client of clients) {
              clientsByUuid[client.uuid] = {...client, agency};
            }
          }
          setAgencyForClient(clientsByUuid[org.uuid].agency);
        } catch (e) { /* Do nothing for now. */ }
      })();
    }
  }, [currentUser, org?.uuid, org?.type]);

  // To support back/forward navigation
  useEffect(() => {
    if (!prevLocationKey || (prevLocationKey && (location.key !== prevLocationKey))) {
      clearOrgRelatedState();

      // If the hash has changed, select the tab
      if (location.hash) {
        setSelectedTab(validTabs.includes(location.hash) ? location.hash : '#details');
      } else {
        setSelectedTab('#details');
      }
    }
  }, [location.key, prevLocationKey, location.hash, clearOrgRelatedState]);

  // Fetch an organisation's users
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setUsersLoading(true);
        setUsersError(null);
        let { data: { users } } = await api.get(`/organisations/${orgUuid}/users`);
        users.sort((a,b) => {
          if(!a.name && b.name) return a.email.localeCompare(b.name);
          if(!a.name && !b.name) return a.email.localeCompare(b.email);
          return a.name.localeCompare(b.name);
        });

        setUsersLoading(false);
        setUsers(users);
      } catch (e) {
        setUsersLoading(false);
        setUsersError((e.response && (e.response.status === 404)) ? 'Organisation not found' :
          (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
      }
    };
    fetchUsers();
  }, [orgUuid]);

  // Fetch an organisation's forms
  useEffect(() => {
    const fetchForms = async () => {
      try {
        setFormsLoading(true);
        setFormsError(null);
        const { data: { forms } } = await api.get(`/organisations/${orgUuid}/forms`);

        setFormsLoading(false);
        setForms(forms);
      } catch (e) {
        setFormsLoading(false);
        setFormsError((e.response && (e.response.status === 404)) ? 'Organisation not found' :
          (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
      }
    };
    fetchForms();
  }, [orgUuid]);

  // Fetch an organisation's ip filters
  useEffect(() => {
    const fetchIpFilters = async () => {
      try {
        setIpFiltersLoading(true);
        setIpFiltersError(null);
        const { data: { ipFilters } } = await api.get(`/organisations/${orgUuid}/ip_filters`);
        setIpFiltersLoading(false);

        setIpFilters(ipFilters);
        setOriginalIpFilters(ipFilters);
      } catch (e) {
        setIpFiltersLoading(false);
        setIpFiltersError((e.response && (e.response.status === 404)) ? 'Organisation not found' :
          (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
      }
    };
    fetchIpFilters();
  }, [orgUuid]);

  // Org has just been created
  useEffect(() => {
    if (location.state && location.state.newOrg && org?.uuid) {
      setShowSuccessMessage(true);
      setSuccessMessage(`${orgDisplayType} created.`);
    }
  }, [location, org?.uuid, orgDisplayType]);

  const handleOrgChange = (org) => {
    clearOrgRelatedState();

    mixpanel.track('Selected Organisation', { page: 'OrgEdit', type: orgDisplayType });

    history.push(`/organisations/${org.uuid}/edit`);
  };

  const handleSubmit = async ({
    name, sessionLimit, contractType, timeZone, notes, signUpProduct, replayEnabled,
  }) => {
    setShowSuccessMessage(false);
    setSuccessMessage(null);
    if (location?.state) {
      delete location.state.newOrg;
      history.replace({...history.location, state: location.state});
    }
    try {
      handleResetErrorMessages();
      const { data: { organisation: updatedOrg } } = await api.put(`/organisations/${orgUuid}`, {
        organisation: {
          ...currentUser.accountManager && {
            sessionLimit,
            contractType,
            notes,
            signUpProduct,
            replayEnabled,
          },
          name,
          timeZone,
        },
      });

      setOrg(prev => ({...prev, ...updatedOrg}));
      setOrgsByUuid({...orgsByUuid, [updatedOrg.uuid]: {...updatedOrg}});
      setShowSuccessMessage(true);
      setSuccessMessage(`${displayType(updatedOrg?.type)} updated.`);
      window.scrollTo({ behavior: 'smooth', top: pageTitleRef?.current?.offsetTop });

      // Update the query time, and form timezone
      const { timeframe: sessionTime } = getSession() || {};

      if (updatedOrg.timeZone !== org.timeZone && (query?.time || sessionTime)) {
        setQuery((prevQuery) => {
          if (prevQuery) {
            return {...prevQuery,
              time: {
                start: moment.tz(prevQuery?.time?.start.format('YYYY-MM-DDTHH:mm:ss'), updatedOrg.timeZone),
                end: moment.tz(prevQuery?.time?.end.format('YYYY-MM-DDTHH:mm:ss'), updatedOrg.timeZone),
              },
              ...prevQuery?.form && {form: {...prevQuery.form, organisation: {...prevQuery.form.organisation, timeZone: updatedOrg.timeZone}}},
            };
          }

          if (!prevQuery && sessionTime) {
            return {
              time: {
                start: moment.tz(moment.tz(sessionTime.start, org.timeZone).format('YYYY-MM-DDTHH:mm:ss'), updatedOrg.timeZone),
                end: moment.tz(moment.tz(sessionTime.end, org.timeZone).format('YYYY-MM-DDTHH:mm:ss'), updatedOrg.timeZone),
              }
            };
          };
        });
      }

      Forms.reload(); // Trigger re-loading of forms list (app-wide)
      setCurrentOrg(updatedOrg);

      mixpanel.track('Updated Organisation', {
        page: 'OrgEdit',
        'Organisation Name': updatedOrg.name,
        'Organisation Uuid': updatedOrg.uuid,
        'Organisation Contract Type': updatedOrg.contractType,
      });
    } catch (e) {
      switch (e?.response?.status) {
      case e?.response?.data?.errors && 400:
      case e?.response?.data?.errors && 422:
        if (Array.isArray(e.response.data.errors)) {
          setErrorMessages(e.response.data.errors);
        } else {
          setErrorMessagesForField(e.response.data.errors);
          setShowErrorMessagesForField(true);
        }
        break;
      case 401:
        setOrgError('Not logged in');
        break;
      case 403:
        history.replace('/');
        break;
      default:
        setErrorMessage('Something went wrong. The organisation has not been updated, please try again.');
      }

      window.scrollTo({ behavior: 'smooth', top: pageTitleRef?.current?.offsetTop });
    }
  };

  const handleRemoveUser = async () => {
    setNewUserError(null);
    setUserToRemoveError(null);
    setUserSuccessMessage(null);
    try {
      await api.delete(`/organisations/${orgUuid}/users/${userToRemove.uuid}`);
      setUsers(prev => prev.filter(u => u.uuid !== userToRemove.uuid));
      setUserToRemove(null);
      setUserSuccessMessage('User removed.');
      mixpanel.track('Removed User', {
        page: 'OrgEdit',
        ...org && {
          'Organisation Name': org.name,
          'Organisation Uuid': org.uuid,
          'Organisation Contract Type': org.contractType,
        }
      });
    } catch (e) {
      setUserToRemoveError((e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong removing the user, please try again.');
    } finally {
      setShowConfirmRemove(false);
      window.scrollTo({ behavior: 'smooth', top: pageTitleRef?.current?.offsetTop });
    }
  };

  const handleAddUser = async (e) => {
    e.preventDefault();
    setNewUserError(null);
    setUserToRemoveError(null);
    setUserSuccessMessage(null);
    try {
      const { data: { user } } = await api.post(`/organisations/${orgUuid}/users`, {
        user: {
          email: newUser.email,
          role: newUser.roleValue,
        },
      });
      setUsers(prev => prev.concat(user));
      setNewUser(null);
      setShowNewUserInput(false);
      setUserSuccessMessage('User invited.');
      mixpanel.track('Added User', {
        page: 'OrgEdit',
        ...org && {
          'Organisation Name': org.name,
          'Organisation Uuid': org.uuid,
          'Organisation Contract Type': org.contractType,
        }
      });
    } catch (e) {
      if (e.response && e.response.status === 400 && e.response.data.errors && e.response.data.errors.length > 0) {
        setNewUserError(e.response.data.errors.map(e => e.message ? e.message === 'Duplicate emails' ? 'This user has already been added to this Organisation.' : e.message :
          'Something went wrong adding the user, please try again.'));
      } else {
        setNewUserError((e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong adding the user, please try again.');
      }
    } finally {
      window.scrollTo({ behavior: 'smooth', top: pageTitleRef?.current?.offsetTop });
    }
  };

  const handleSaveUserRole = async ({uuid, roleValue}) => {
    try {
      setUserSuccessMessage(null);
      const { data: { user } } = await api.put(`/organisations/${orgUuid}/users/${uuid}`, {
        user: {
          role: roleValue,
        },
      });
      setUsers(prev => prev.map(u => (u.uuid === uuid ? user : u)));
      setUserSuccessMessage('User role updated.');
      mixpanel.track('Updated User', {
        page: 'OrgEdit',
        ...org && {
          'Organisation Name': org.name,
          'Organisation Uuid': org.uuid,
          'Organisation Contract Type': org.contractType,
        }
      });
    } catch (e) {
      switch (e?.response?.status) {
      case 401:
        setOrgError('Not logged in');
        break;
      case 403:
        history.replace('/');
        break;
      default:
        setErrorMessage('Something went wrong. The user has not been updated, please try again.');
      }
    } finally {
      window.scrollTo({ behavior: 'smooth', top: pageTitleRef?.current?.offsetTop });
    }
  };

  const handleSaveNewUser = ({roleValue}) => {
    setNewUser(prev => ({...prev, roleValue}));
  };

  const multipleOrgs = (currentUser.accountManager || Object.keys(orgsByUuid).length > 1);

  const updateRuleName = ({value: name, i}) => {
    setShowIpFiltersSuccessMessage(false);
    const rules = ipFilters.slice();
    if (name.length > 0 && name.length <= 255) {
      const errors = Object.assign({}, rules[i].errors);
      delete errors.name;
      rules[i] = Object.assign({}, rules[i], {errors});
    } else if (name.length === 0) {
      rules[i] = Object.assign({}, rules[i], {
        errors: Object.assign({name: 'Please add a name.'}, rules[i].errors),
      });
    } else if (name.length > 255) {
      rules[i] = Object.assign({}, rules[i], {
        errors: Object.assign({name: 'Name is too long.'}, rules[i].errors),
      });
    }
    rules[i].name = name;
    setIpFilters(rules);
    setEditedIpFilters(true);
  };

  const updateRuleCidr = ({value: cidr, i}) => {
    setShowIpFiltersSuccessMessage(false);
    const rules = ipFilters.slice();
    if (isCidr(cidr)) {
      const errors = Object.assign({}, rules[i].errors);
      delete errors.cidr;
      rules[i] = Object.assign({}, rules[i], {errors});
    } else {
      rules[i] = Object.assign({}, rules[i], {
        errors: Object.assign({cidr: 'Please add a valid CIDR.'}, rules[i].errors),
      });
    }
    rules[i].cidr = cidr;
    setIpFilters(rules);
    setEditedIpFilters(true);
  };

  const removeRule = ({i: indexToRemove}) => {
    setShowIpFiltersSuccessMessage(false);
    setIpFiltersUpdateError(null);
    setIpFilters(ipFilters.filter((rule, i) => i !== indexToRemove));
    setEditedIpFilters(true);
  };

  const addRule = () => {
    setShowIpFiltersSuccessMessage(false);
    setIpFiltersUpdateError(null);
    setIpFilters(ipFilters.concat([{
      name: '',
      cidr: '',
    }]));
  };

  const saveRules = async () => {
    setIpFiltersUpdateError(null);
    setShowIpFiltersSuccessMessage(false);
    setShowUnsavedIpFiltersMsg(false);

    try {
      await api.put(`/organisations/${orgUuid}/ip_filters`, {ipFilters});
      setOriginalIpFilters(ipFilters);
      setEditedIpFilters(false);
      setShowIpFiltersSuccessMessage(true);
      mixpanel.track('Updated Ip Filters', {
        page: 'OrgEdit',
        ...org && {
          'Organisation Name': org.name,
          'Organisation Uuid': org.uuid,
          'Organisation Contract Type': org.contractType,
        }
      });
    } catch (e) {
      if (e.response && (e.response.status === 400 || e.response.status === 422) && e.response.data.errors && e.response.data.errors.length > 0) {
        setIpFiltersUpdateError(e.response.data.errors.map(e => e.message ? e.message : 'Something went wrong adding the IP Filter, please try again.'));
      } else {
        setIpFiltersUpdateError((e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong adding the IP Filter, please try again.');
      }
    }
  };

  const checkAllRulesValid = () => {
    for (const rule of ipFilters) {
      if (!rule.name || !rule.cidr || (rule.errors && (rule.errors.cidr || rule.errors.name))) return false;
    }
    return true;
  };

  const handleBlockIfUnsaved = (e) => {
    if (editedIpFilters) {
      e.preventDefault();
      e.returnValue = true;
      setShowUnsavedIpFiltersMsg(true);
    }
  };

  const handleCancelIpFilterChanges = () => {
    setEditedIpFilters(false);
    setShowUnsavedIpFiltersMsg(false);
    setIpFilters(originalIpFilters);
  };

  const handleBillingLinkClick = async () => {
    const {data: {portalSession: {url: targetUrl}}} =
      await api.post(`/organisations/${org.uuid}/customer_portal_sessions`);
    window.location.href = targetUrl;
  };

  const handleTransitionToInvoice = async () => {
    try {
      const { data: { organisation: upatedOrg } } = await api.post(`/organisations/${orgUuid}/transition_from_subscription_to_invoice`);
      setOrg({...org, ...upatedOrg});
      setSuccessMessage('Subscription status removed.');
      setShowSuccessMessage(true);
    } catch (e) {
      if (e.response && e.response.status === 400 && e.response.data.errors && Object.keys(e.response.data.errors).length > 0) {
        // TODO: is this is the best way to handle array or object error responses?
        if (Array.isArray(e.response.data.errors)) {
          setErrorMessages(e.response.data.errors);
        } else {
          setErrorMessagesForField(e.response.data.errors);
          setShowErrorMessagesForField(true);
        }
      } else {
        setErrorMessages({'Something went wrong.': 'The transition failed, please try again'});
      }
    } finally {
      window.scrollTo({ behavior: 'smooth', top: pageTitleRef?.current?.offsetTop });
    }
  };

  return (
    <Container fluid className="org-edit page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Edit {orgDisplayType}</title>
      </Helmet>
      <div className={`nav-wrapper ${!multipleOrgs ? 'shadow-none' : ''}`}>
        <NavBar mixpanel={mixpanel} handleClick={handleBlockIfUnsaved}/>
        {multipleOrgs && <Row className="g-0 nav-primary">
          <Col md={3} className="pt-0 pb-2 mt-1" id="organisation-select">
            <Select
              styles={{
                control: (styles, state) => ({...styles,
                  border: '1px solid #EBEDF2',
                }),
                option: (styles, state) => ({...styles,
                  color: '#3f4047',
                  backgroundColor: state.selectProps.value && (state.selectProps.value.uuid === state.value) ? "#E2E5EC" : null,
                  '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
                }),
                menu: (styles, state) => ({...styles,
                  marginTop: '1px',
                  borderRadius: '4px',
                  border: '1px solid #EBEDF2',
                  boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                }),
                dropdownIndicator: (styles, state) => ({...styles,
                  cursor: 'pointer',
                  transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                  transition: 'transform .5s ease',
                }),
              }}
              options={Object.values(orgsByUuid).length ? Object.values(orgsByUuid).map((org) => ({...org, value: org.uuid, label: org.name})) : orgsError || (!orgsLoading && !Object.keys(orgsByUuid).length) ? [] :
                [{ selectable: false, value: null, label: <><i className="fa fa-circle-o-notch fa-spin fa-fw" /> Loading...</>}]}
              isOptionDisabled={option => option.hasOwnProperty('selectable') && !option.selectable}
              onChange={handleOrgChange}
              value={org && org.name && {...org, label: org.name, value: org.uuid}}
            />
          </Col>
        </Row>}
      </div>
      <div className="main-content">
        <Col className="center-column justify-content-md-center">
          <FeedbackRow
            classList={['allow-scroll-under-nav']}
            mixpanel={mixpanel}
            page={'OrgEdit'}
            org={org}
            messageContent={`editing an ${orgDisplayType.toLocaleLowerCase()}`} />
          <AppAlerts showOrgAlerts={true} />
          <Row className="title-row g-0" ref={pageTitleRef}>
            <Col className="p-0">
              <h1 id="form-title" data-testid="page-title">
                {org && org.name && org.name}
              </h1>
            </Col>
          </Row>
          <Card className="edit-card">
            <Card.Body>
              <TabContainer activeKey={selectedTab} onSelect={(t) => {
                if (editedIpFilters) {
                  setShowUnsavedIpFiltersMsg(true);
                } else {
                  history.push(`/organisations/${orgUuid}/edit${t}`);
                  mixpanel.track('Changed Tab', {
                    page: 'OrgEdit',
                    tab: `${t}`,
                    ...orgDetailsForMixpanel(org),
                  });
                  if (showSuccessMessage) setShowSuccessMessage(false);
                  if (showNewUserInput) {
                    setShowNewUserInput(false);
                    setNewUser(null);
                  }
                  if (newUserError) {
                    setNewUserError(null);
                  }
                  if (userToRemoveError) {
                    setUserToRemoveError(null);
                  }
                  if (userSuccessMessage) {
                    setUserSuccessMessage(null);
                  }
                  if (showUnsavedIpFiltersMsg) {
                    setShowUnsavedIpFiltersMsg(false);
                  }
                }
              }}>
                <Row className="g-0 card-title-row justify-content-between">
                  <Col className="p-0">
                    <Card.Title as="h3">Edit {orgDisplayType}</Card.Title>
                  </Col>
                  <Col className="p-0 col-auto">
                    <Nav variant="tabs" className="justify-content-end" id="card-header-nav-tabs" activeKey={selectedTab}>
                      <Nav.Item>
                        <Nav.Link eventKey="#details">Details</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="#users">Users</Nav.Link>
                      </Nav.Item>
                      {org && org?.type !== 'Agency' &&
                      <Nav.Item>
                        <Nav.Link eventKey="#forms">Forms</Nav.Link>
                      </Nav.Item>}
                      {org && org?.type !== 'Agency' &&
                      <Nav.Item>
                        <Nav.Link eventKey="#ip-filters">IP Filters</Nav.Link>
                      </Nav.Item>}
                      {org && org?.type === 'Agency' && <>
                        <Nav.Item>
                          <Nav.Link eventKey="#clients">Clients</Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="#sessionLimits">Manage Session Limits</Nav.Link>
                        </Nav.Item>
                      </>}
                      {
                        org && org.type !== 'AgencyClient' && (
                          (org.subscriptionStatus && org.subscriptionStatus === 'canceled') ||
                          (org.subscriptionStatus && org.subscriptionStatus === 'incomplete_expired') ||
                          (org.contractType && org.contractType === 'trial')
                        ) &&
                        (currentUserIsStandard ?
                          <OverlayTrigger overlay={
                            <Popover id="standard-access-msg-popover">
                              <Popover.Body>
                                <p>Only admin users can complete sign up.</p>
                                <p>Please contact one of your admin users or email us on support@zuko.io.</p>
                              </Popover.Body>
                            </Popover>}>
                            <div className="d-inline-block">
                              <Button className="me-0 sign-up-btn" disabled={currentUserIsStandard}>Sign Up</Button>
                            </div>
                          </OverlayTrigger>:
                          <Link
                            to={{
                              pathname: `/organisations/${org.uuid}/choose-plan`,
                              state: {organisation: {name: org.name}},
                            }}><Button className="me-0 sign-up-btn">Sign Up</Button>
                          </Link>)
                      }
                      {
                        org && org.type !== 'AgencyClient' &&
                        org.stripeCustomerId && org.subscriptionStatus && org.subscriptionStatus !== 'canceled'
                          && org.subscriptionStatus !== 'incomplete_expired' && org.contractType !== 'trial' &&
                        <Nav.Item className="pe-2">
                          {currentUserIsStandard ?
                            <OverlayTrigger overlay={
                              <Popover>
                                <Popover.Body>
                                  An admin user in your {orgDisplayType.toLocaleLowerCase()} can access billing.
                                </Popover.Body>
                              </Popover>}>
                              <div className="d-inline-block">
                                <Nav.Link id="billing-link" disabled={currentUserIsStandard}>Billing</Nav.Link>
                              </div>
                            </OverlayTrigger> :
                            <Nav.Link id="billing-link" onClick={handleBillingLinkClick}>Billing</Nav.Link>}
                        </Nav.Item>
                      }
                    </Nav>
                  </Col>
                </Row>
                <Row className="g-0 card-content">
                  <TabContent>
                    {orgError && <div id="org-load-error"><h3>{orgError}</h3></div>}
                    {currentUserIsStandard && selectedTab !== '#forms' && <Row className="alert-row g-0" id="user-role-info-alert">
                      <Alert dismissible={false} variant={'info'}>
                        <div className="page-alert-svg-icon d-flex"><VscInfo size="100%"/></div>
                        <p className="alert-text m-0">
                          Please inform an admin user if you wish to make any changes to this {orgDisplayType.toLocaleLowerCase()}{org?.type !== 'Agency' && <>, or to manage users.</>}{org?.type === 'Agency' && <>, manage users, or to manage clients.</>}
                        </p>
                      </Alert>
                    </Row>}
                    <TabPane eventKey="#details" className="min-height-tab">
                      {showSuccessMessage && successMessage &&
                        <Row className="alert-row g-0">
                          <Alert dismissible variant="success" closeVariant="white"
                            onClose={() => setShowSuccessMessage(false)}>
                            <div className="alert-svg-icon my-auto"><VscCheck size="100%"/></div>
                            <p className="alert-text m-0">{successMessage}</p>
                          </Alert>
                        </Row>}
                      {showErrorMessagesForField && (errorMessagesForField && Object.keys(errorMessagesForField).length > 0) &&
                        <Row className="alert-row g-0">
                          <Alert dismissible variant="danger" closeVariant="white"
                            onClose={() => setShowErrorMessagesForField(false)}>
                            <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                            <div>
                              {Object.keys(errorMessagesForField)
                                .map((field, i) => (
                                  <p key={`field${i}`} className="alert-text m-0 py-1">{field} {errorMessagesForField[field]?.join(', ')}.</p>))}
                            </div>
                          </Alert>
                        </Row>
                      }
                      {errorMessages?.length > 0 &&
                        <Row className="alert-row g-0">
                          <Alert dismissible variant="danger" closeVariant="white"
                            onClose={() => setErrorMessages(null)}>
                            <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                            <p className="alert-text m-0">{errorMessages.map((e) => e.message)}</p>
                          </Alert>
                        </Row>
                      }
                      {errorMessage &&
                        <Row className="alert-row g-0">
                          <Alert dismissible variant="danger" closeVariant="white"
                            onClose={() => setErrorMessage(null)}>
                            <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                            <p className="alert-text m-0">{errorMessage}</p>
                          </Alert>
                        </Row>
                      }
                      {!orgError && <>
                        {orgLoading &&
                          <Row className="form-title-row flex-nowrap top-form-row">
                            <Col>
                              <FaSpinner size="18px" className="spinning-icon" title="Loading organisation..."/>
                            </Col>
                          </Row>}

                        {!orgLoading && <>
                          <div className="d-grid details-grid">
                            {!org?.type &&
                            <OrgDetailsForm
                              handleSubmit={handleSubmit}
                              type={'edit'}
                              org={org}
                              handleResetErrorMessagesForField={handleResetErrorMessagesForField}
                              errorMessagesForField={errorMessagesForField}
                              handleResetSuccessMessage={handleResetSuccessMessage}
                              handleTransitionToInvoice={handleTransitionToInvoice}
                              currentUserIsStandard={currentUserIsStandard}
                            />}

                            {org?.type === 'Agency' &&
                            <OrgAgencyDetailsForm
                              handleSubmit={handleSubmit}
                              type={'edit'}
                              org={org}
                              handleResetErrorMessagesForField={handleResetErrorMessagesForField}
                              errorMessagesForField={errorMessagesForField}
                              handleResetSuccessMessage={handleResetSuccessMessage}
                              handleTransitionToInvoice={handleTransitionToInvoice}
                              currentUserIsStandard={currentUserIsStandard}
                            />}

                            {org?.type === 'AgencyClient' &&
                            <OrgAgencyClientDetailsForm
                              handleSubmit={handleSubmit}
                              type={'edit'}
                              org={org}
                              handleResetErrorMessagesForField={handleResetErrorMessagesForField}
                              errorMessagesForField={errorMessagesForField}
                              handleResetSuccessMessage={handleResetSuccessMessage}
                              currentUserIsStandard={currentUserIsStandard}
                              agencyForClient={agencyForClient}
                            />}
                            <ReadOnlyOrgItems org={org} agencyForClient={agencyForClient} />
                          </div>
                        </>}
                      </>}

                    </TabPane>
                    <TabPane eventKey="#users">
                      {usersLoading && <p><i className="fa fa-circle-o-notch fa-spin fa-fw"/> Loading...</p>}
                      {!orgError && usersError && <div id="org-load-error"><h3>{usersError}</h3></div>}
                      {userToRemoveError &&
                        <Row className="alert-row g-0">
                          <Alert dismissible variant="danger" closeVariant="white"
                            onClose={() => setUserToRemoveError(false)}>
                            <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                            <p className="alert-text m-0">{userToRemoveError}</p>
                          </Alert>
                        </Row>
                      }
                      {newUserError &&
                        <Row className="alert-row g-0">
                          <Alert dismissible variant="danger" closeVariant="white"
                            onClose={() => setNewUserError(false)}>
                            <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                            <p className="alert-text m-0">{newUserError}</p>
                          </Alert>
                        </Row>
                      }
                      {userSuccessMessage &&
                        <Row className="alert-row g-0">
                          <Alert dismissible variant="success" closeVariant="white"
                            onClose={() => setUserSuccessMessage(null)}>
                            <div className="alert-svg-icon my-auto"><VscCheck size="100%"/></div>
                            <p className="alert-text m-0">{userSuccessMessage}</p>
                          </Alert>
                        </Row>}
                      {!orgError && !usersError && !usersLoading && <>
                        {!currentUserIsStandard &&
                        <Row className="alert-row g-0" id="users-info-alert">
                          <Alert dismissible={false} variant={'info'}>
                            <div className="page-alert-svg-icon d-flex"><VscInfo size="100%"/></div>
                            <p className="alert-text m-0">
                              {org?.type !== 'AgencyClient' && `All users listed here are part of this ${orgDisplayType.toLocaleLowerCase()}. `}Adding new users will give them access to this {orgDisplayType.toLocaleLowerCase()}, {org?.type === 'Agency' ? <>all of the agency <Link to="#clients">clients</Link> and their forms</> : <>and their <Link to="#forms">forms</Link></>}.
                            </p>
                          </Alert>
                        </Row>}
                        <Table responsive id="users-table">
                          <thead>
                            <tr>
                              <th className="w-25">Name</th>
                              <th className="w-50">Email</th>
                              <th className="ps-0">Role
                                <OverlayTrigger placement="left"
                                  overlay={
                                    <Popover id="matched-fields-popover">
                                      <Popover.Body>
                                        You can assign a role to the users in this {orgDisplayType.toLocaleLowerCase()}.
                                        <br/><br/>
                                        An <i>admin</i> user can:
                                        <ul>
                                          <li>Manage billing</li>
                                          <li>Add/remove users and edit their roles</li>
                                          <li>Edit this {orgDisplayType.toLowerCase()}'s details
                                            {org?.type !== 'Agency' && ' and IP filters'}
                                          </li>
                                          <li>Complete all tasks that <i>standard</i> users can (see below)</li>
                                          {org?.type === 'Agency' && <li>Manage client session limits</li>}
                                        </ul>

                                        A <i>standard</i> user can do everything else in the app, including:
                                        <ul>
                                          <li>Add/manage forms</li>
                                          <li>Manage fields</li>
                                          <li>Manage alerts</li>
                                        </ul>
                                      </Popover.Body>
                                    </Popover>}>
                                  <span className="pe-1 pt-2"><FaInfoCircle size="12px" className="column-info-icon info"/></span>
                                </OverlayTrigger></th>
                              <th className="ps-1"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {users && users.length > 0 && users.map(({email, uuid, roles, name}, i) => (
                              <tr key={`user-${email}`}>
                                <td className="w-25">{name}</td>
                                <td className="w-50">{email}</td>
                                <td className="ps-0 role-select-col">
                                  <div className="role-container">
                                    <RoleSelect
                                      onChange={handleSaveUserRole}
                                      uuid={uuid}
                                      roleValue={roles?.find(r => r.resourceUuid === orgUuid)?.name}
                                      currentUserIsStandard={currentUserIsStandard} />
                                  </div>
                                </td>
                                <td className="p-1 text-end">
                                  {!currentUserIsStandard && <Button className="action-btn mx-0" disabled={currentUserIsStandard}
                                    onClick={() => {setShowConfirmRemove(true); setUserToRemove({uuid});}}>{currentUser.email === email ? 'Leave' : 'Remove User'}</Button>}</td>
                              </tr>
                            ))}
                            <tr>
                              <td></td>
                              <td className="pb-0 pe-1 text-end" colSpan="3">
                                {showNewUserInput && <>
                                  <Row className="alert-row g-0" id="new-user-info-alert">
                                    <Alert dismissible={false} variant={'info'}>
                                      <div className="page-alert-svg-icon d-flex"><VscInfo size="100%"/></div>
                                      <p className="alert-text m-0">New users will receive an email to invite them to create an account.</p>
                                    </Alert>
                                  </Row>
                                  <Form onSubmit={handleAddUser} className="new-user-form">
                                    <div className="d-flex">
                                      <div className="d-flex align-items-center w-100">
                                        <Form.Control autoFocus className="new-user-input me-3" type="email" value={newUser?.email || ''} name="email" id="new-user-email" autoComplete="email" required placeholder="user@example.com" maxLength={255} onChange={({target: {value}}) => {
                                          setNewUser(prev => ({...prev, email: value}));
                                        }}/>
                                      </div>
                                      <div className="d-flex align-items-center">
                                        <div className="role-container">
                                          <RoleSelect
                                            onChange={handleSaveNewUser}
                                            roleValue={newUser?.roleValue}
                                            currentUserIsStandard={currentUserIsStandard} />
                                        </div>
                                        <Button className="invite ms-1 me-0" type="submit">Send Invite</Button>
                                      </div>
                                    </div>
                                  </Form>
                                </>}
                                {!showNewUserInput && !currentUserIsStandard && <>
                                  <Button className="invite mx-0" onClick={() => {setShowNewUserInput(true); setNewUser({roleValue: 'admin'});}}>Add User</Button>
                                </>}
                              </td>
                            </tr>
                          </tbody>
                        </Table></>}
                      {userToRemove && <Modal show={showConfirmRemove} onHide={() => setShowConfirmRemove(false)}>
                        <Modal.Header>
                          <Modal.Title>Remove User: {userToRemove.email}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>Are you sure to remove {currentUser.email === userToRemove.email ? 'yourself from this Organisation' : 'this user'}?</Modal.Body>
                        <Modal.Footer className="justify-content-between">
                          <Button variant="outline-secondary" className="cancel" onClick={() => setShowConfirmRemove(false)}>Cancel</Button>
                          <Button onClick={handleRemoveUser}>Yes, Remove</Button>
                        </Modal.Footer>
                      </Modal>}
                    </TabPane>
                    <TabPane eventKey="#forms">
                      {formsLoading && <p><i className="fa fa-circle-o-notch fa-spin fa-fw"/> Loading...</p>}
                      {!orgError && formsError && <div id="org-load-error"><h3>{formsError}</h3></div>}
                      {!orgError && !formsError && !formsLoading && <>
                        <Table responsive id="forms-table">
                          <thead>
                            <tr>
                              <th className="w-25">Label</th>
                              <th className="w-50">URL</th>
                              <th className="link-col"></th>
                              <th className="link-col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {forms && forms.length > 0 && forms.map(({url, label, uuid}, i) => (
                              <tr key={`form-${i}`}>
                                <td className="w-25">{label}</td>
                                <td className="w-50"><a href={url} target="_blank" rel="noopener noreferrer">{url}</a></td>
                                <td className="link-col"><Link to={`/forms/${uuid}/edit`} data-testid={`edit-form-${uuid}`}>Edit</Link></td>
                                <td className="link-col"><Link to={`/form_aggregate?form[uuid]=${uuid}`} data-testid={`view-form-${uuid}`}>View Data</Link></td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        <Row className="g-0 card-bottom-tools">
                          <Col className="p-0 d-flex justify-content-end">
                            <Link to={'/forms/new'}>
                              <Button className="action-btn me-0">Add New Form</Button>
                            </Link>
                          </Col>
                        </Row>
                      </>}
                    </TabPane>
                    <TabPane eventKey="#ip-filters">
                      {ipFiltersLoading && <p><FontAwesomeIcon icon={faCircleNotch} spin fixedWidth/> Loading...</p>}
                      {!orgError && ipFiltersError && <div id="org-load-error"><h3>{ipFiltersError}</h3></div>}
                      {showIpFiltersSuccessMessage &&
                        <Row className="alert-row g-0">
                          <Alert dismissible variant="success" closeVariant="white"
                            onClose={() => setShowIpFiltersSuccessMessage(false)}>
                            <div className="alert-svg-icon my-auto"><VscCheck size="100%"/></div>
                            <p className="alert-text m-0">IP Filters updated.</p>
                          </Alert>
                        </Row>}
                      {ipFiltersUpdateError &&
                        <Row className="alert-row g-0">
                          <Alert dismissible variant="danger" closeVariant="white"
                            onClose={() => setIpFiltersUpdateError(false)}>
                            <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                            <p className="alert-text m-0">{ipFiltersUpdateError}</p>
                          </Alert>
                        </Row>}
                      {showUnsavedIpFiltersMsg &&
                        <Row className="alert-row g-0">
                          <Alert variant="danger">
                            <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                            <p className="alert-text m-0">You have unsaved changes to IP Filters - please Save or Cancel.</p>
                          </Alert>
                        </Row>}
                      {!orgError && !ipFiltersError && !ipFiltersLoading && <>
                        <Col md={9} className="mx-auto">
                          <Table id="ip-filters-table">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>CIDR</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {ipFilters && ipFilters.length > 0 && ipFilters.map(({name, cidr, errors}, i) => (
                                <tr key={`ipFilter-${i}`}>
                                  <td>
                                    <Form.Control type="text" value={name} required placeholder="Head Office" maxLength={255} readOnly={currentUserIsStandard}
                                      className={((showUnsavedIpFiltersMsg && !name) || (errors && errors.name)) && "invalid-input"}
                                      onChange={({target: {value}}) => updateRuleName({value, i})}/>
                                    {errors && (errors.name || errors.cidr) && <p className="validationFailureMessage">{errors.name}</p>}
                                  </td>
                                  <td>
                                    <Form.Control type="text" value={cidr} required placeholder="1.2.3.0/24" readOnly={currentUserIsStandard}
                                      className={((showUnsavedIpFiltersMsg && !cidr) || (errors && errors.cidr)) && "invalid-input"}
                                      onChange={({target: {value}}) => updateRuleCidr({value, i})}/>
                                    {errors && (errors.name || errors.cidr) && <p className="validationFailureMessage">{errors.cidr}</p>}
                                  </td>
                                  <td><FontAwesomeIcon icon={faTrashAlt} className="editing" onClick={() => removeRule({i})} data-testid={`delete-ip-filter${i}`}/></td>
                                </tr>
                              ))}
                              <tr>
                                <td></td>
                                <td></td>
                                <td>
                                  <Button disabled={currentUserIsStandard} onClick={addRule} data-testid="add-ip-filter" variant="light">
                                    <FontAwesomeIcon icon={faPlus} className="editing"/></Button>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                          <Row className="g-0 card-bottom-tools">
                            <Col className="p-0 d-flex justify-content-end">
                              {showUnsavedIpFiltersMsg && <Button variant="outline-secondary" className="cancel me-1" type="reset" onClick={handleCancelIpFilterChanges}>Cancel</Button>}
                              <Button className="mx-0 save ms-1" type="submit" disabled={!editedIpFilters || !checkAllRulesValid() || currentUserIsStandard} onClick={saveRules}>Save</Button>
                            </Col>
                          </Row>
                        </Col>
                      </>}
                    </TabPane>
                    <TabPane eventKey="#clients">
                      <OrgEditClientsTab org={org} currentUserIsStandard={currentUserIsStandard} />
                    </TabPane>
                    <TabPane eventKey="#sessionLimits">
                      <OrgEditSessionLimitsTab
                        org={org}
                        onSaveSessionLimits={handleSaveSessionLimits}
                        successMessage={sessionLimitsSuccessMessage}
                        handleResetSuccessMessage={handleResetSuccessMessage}
                        errorMessage={sessionLimitsErrorMessage}
                        handleResetErrorMessage={handleResetErrorMessages}
                        currentUserIsStandard={currentUserIsStandard} />
                    </TabPane>
                  </TabContent>
                </Row>
              </TabContainer>
            </Card.Body>
          </Card>
        </Col>
      </div>
    </Container>
  );
};

export default OrgEdit;
