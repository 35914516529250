import React from 'react';
import { useTable, useGlobalFilter, useSortBy } from 'react-table';
import Table from 'react-bootstrap/Table';
import Row from 'react-bootstrap/Row';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import ReplayLinkGridItem from './ReplayLinkGridItem';
import { emptySessionFilters } from '../Page/SessionReplay/SessionsQueryNav';

import './DataTable.scss';

// Define a default UI for filtering
function GlobalFilter({globalFilter, setGlobalFilter}) {
  const [value, setValue] = React.useState(globalFilter);
  const onChange = (value) => setGlobalFilter(value || undefined);
  return (
    <span className="datatable-search-filter">
      <label className="me-2">Search:</label>
      <input
        value={value || ""}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
    </span>
  );
}

function DataTable({ columns, data, testId, type,
  setShowSessionReplayCTAModal,
  query,
  flowField,
  flowFieldIdentifier,
}) {
  if (type === 'fieldData') {
  // Add custom sort to ensure null/'' orders are positioned at the bottom of the table
    const fieldOrder = columns.find((col) => col.accessor === 'fieldOrder');
    fieldOrder.sortType = (rowA, rowB, columnID, desc) => {
      const a = rowA.values[columnID];
      const b = rowB.values[columnID];
      return desc ? (b === null || b === '') - (a === null || a === '') : (a === null || a === '') - (b === null || b === '');
    };
  }

  // Use the state and functions returned from useTable to build the table
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    allColumns,
    state,
    setGlobalFilter,
    toggleHideColumn,
  } = useTable({
    columns,
    data,
    initialState: {
      ...(type === 'fieldData' || type === 'fieldFlow') && { hiddenColumns: ['htmlTagName', 'htmlType', 'htmlName', 'htmlId'] },
      ...(type === 'fieldData') && { sortBy: [{ id: 'fieldOrder', desc: false }] },
      ...(type === 'fieldFlow') && { sortBy: [{ id: 'flow', desc: true }] },
      ...(type === 'customEventsOverview') && { hiddenColumns: ['abandonedCount', 'completedCount', 'abandonedProportion', 'completedProportion', 'abandonedMeanTimesPerSession', 'completedMeanTimesPerSession'] },
      ...(type === 'customEventsOverview') && { sortBy: [{ id: 'combinedCount', desc: true }] },
    },
  },
  useGlobalFilter,
  useSortBy,
  );

  // Render the UI
  return (
    <>
      <div className="d-flex justify-content-end table-search-row browser-only align-items-center pb-2">
        <GlobalFilter globalFilter={state.globalFilter} setGlobalFilter={setGlobalFilter}/>
        {(type === 'fieldData' || type === 'fieldFlow') &&
          <OverlayTrigger placement="bottom" trigger={'click'} rootClose overlay={<Popover id="datatable-column-toggle">
            <Popover.Body>
              {allColumns.map((column) => {
                const hidden = ['htmlTagName', 'htmlType', 'htmlName', 'htmlId'];
                const hiddenDisplayName = {
                  htmlTagName: 'HTML Tag Name',
                  htmlType: 'HTML Type',
                  htmlName: 'HTML Name',
                  htmlId: 'HTML ID',
                };

                if (!hidden.includes(column.id)) return null;
                return (
                  <div key={column.id}>
                    <label>
                      <input type="checkbox" {...column.getToggleHiddenProps()} />{' '}
                      {hiddenDisplayName[column.id]}
                    </label>
                  </div>
                );
              })}
            </Popover.Body>
          </Popover>}>
            <p className="mb-0"><span className="btn column-toggle-btn">Toggle columns</span></p>
          </OverlayTrigger>}

        {(type === 'customEventsOverview') &&
          <OverlayTrigger placement="bottom" trigger={'click'} rootClose overlay={<Popover id="datatable-column-toggle">
            <Popover.Body>
              <div>
                <label>
                  <input type="checkbox" onClick={() => {
                    toggleHideColumn('abandonedCount');
                    toggleHideColumn('abandonedProportion');
                    toggleHideColumn('abandonedMeanTimesPerSession');
                  }} />{' '}Abandoned
                </label>
              </div>
              <div>
                <label>
                  <input type="checkbox" onClick={() => {
                    toggleHideColumn('completedCount');
                    toggleHideColumn('completedProportion');
                    toggleHideColumn('completedMeanTimesPerSession');
                  }}/>{' '}Completed
                </label>
              </div>
            </Popover.Body>
          </Popover>}>
            <p className="mb-0"><span className="btn column-toggle-btn">Toggle columns</span></p>
          </OverlayTrigger>}
      </div>
      {type === 'fieldData' && data && data.some(r => r.significantDiff) &&
          <Row className="g-0 significant-diff-legend-row align-items-center">
            <div className="d-flex justify-content-center w-100">
              <dl>
                <dt className="d-inline"><span className="abandoned-colour-bar"></span></dt>
                <dd className="d-inline pe-2 mb-0">Abandoned sessions return to this field significantly more than completed sessions</dd>
                <dt className="d-inline"><span className="completed-colour-bar"></span></dt>
                <dd className="d-inline mb-0">Completed sessions return to this field significantly more than abandoned sessions</dd>
              </dl>
            </div>
          </Row>}
      <div className="table-wrap">
        <Table className="datatable" striped hover borderless {...getTableProps()} data-testid={testId}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr className="table-header-row" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => {
                  return (<th className={`header-row-${column.id}`} {...column.getHeaderProps(column.getSortByToggleProps())}  title="Click to sort">{column.render('Header')}
                    <span className="table-sort-icons">
                      {column.canSort ? column.isSorted && column.isSortedDesc ? <i className="fa fa-sort-down"></i> :
                        column.isSorted && !column.isSortedDesc ? <i className="fa fa-sort-up"></i> :
                          <i className="fa fa-sort browser-only"></i> : ''}
                    </span>
                  </th>);
                })}
              </tr>
            ))}
          </thead>
          <tbody className="table-body" {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                type === 'fieldFlow' && (row.original.type  === 'session_state' || row.original.type  === 'custom_event') ?
                  <tr {...row.getRowProps()} className="session-state-row">
                    {row.cells.map(cell => {
                      return (
                        <td className={`cell-header-${cell.column.id}`} {...cell.getCellProps()}>
                          {(cell.value === 'Abandoned') ?
                            <div className="d-grid replay-link-grid">
                              <div></div>
                              <span className={`${cell.value}-session-cell`}>{cell.render('Cell')}</span>
                              <div className="ps-3">
                                {flowField &&
                                  <ReplayLinkGridItem
                                    loading={false}
                                    tooltipText={<>Open <i>Session Replay</i> for sessions that abandoned on the {flowField.label}</>}
                                    setShowSessionReplayCTAModal={setShowSessionReplayCTAModal}
                                    replayEnabled={query?.form?.organisation?.replayEnabled}
                                    queryStringObj={(query?.form && query?.time) ? {form: query.form, time: query.time, filters: query.filters,
                                      sessionOutcomes: [{value: 'abandoned'}],
                                      sessionFilters: {
                                        ...emptySessionFilters,
                                        abandonedFieldIdentifier: flowField.identifier,
                                      }} : null}
                                  />}
                              </div>
                            </div> :
                            (cell.value === 'Completed') ? <span className={`${cell.value}-session-cell`}>{cell.render('Cell')}</span> :
                              (row.original.type  === 'custom_event' && cell.column.id === 'label') ? <span className={`custom-event-session-cell`}>{cell.render('Cell')}</span> :
                                cell.render('Cell')}</td>
                      );})}
                  </tr> :
                  type === 'fieldData' && row.original.significantDiff ?
                    <tr {...row.getRowProps()} className={row.original.zScore > 0 ? 'abandoned-significant-diff-row' : 'completed-significant-diff-row'}>
                      {row.cells.map(cell => {
                        return (
                          <td className={`cell-header-${cell.column.id}`} {...cell.getCellProps()}>
                            <span className={cell.column.parent?.Header === '% Sessions Returned' ?
                              (row.original.zScore > 0 && cell.column.Header === 'Abandoned') ? 'abandoned-cell' :
                                (row.original.zScore < 0 && cell.column.Header === 'Completed') ? 'completed-cell' : '' : null}>{cell.render('Cell')}</span>
                          </td>
                        );})}
                    </tr> :
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return (
                          <td className={`cell-header-${cell.column.id}`} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                        );})}
                    </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </>
  );
}

export default React.memo(DataTable);
