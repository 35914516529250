import React, { useState, useContext, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import CardGroup from 'react-bootstrap/CardGroup';
import Button from 'react-bootstrap/Button';
import ToggleButton from 'react-bootstrap/ToggleButton';
import ToggleButtonGroup from 'react-bootstrap/ToggleButtonGroup';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import commaNumber from 'comma-number';
import {loadStripe} from '@stripe/stripe-js';
import { TiTick } from "react-icons/ti";
import { SwitchTransition, CSSTransition, TransitionGroup } from 'react-transition-group';
import {faCircleNotch} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  useParams,
  useLocation,
} from "react-router-dom";

import AppAlerts from '../../Components/AppAlerts';
import FeedbackRow from '../../Components/FeedbackRow';
import AppContext from '../../AppContext';
import api from '../../api';
import '../../Page/ChoosePlan/ChoosePlan.scss';
import Card from 'react-bootstrap/Card';
import ChoosePlanCompanyDetailsForm from '../../Page/ChoosePlan/ChoosePlanCompanyDetailsForm';
import camelcaseKeys from 'camelcase-keys';

const zukoSlug = '2dbb44d4ce0c66f4';

const ChoosePlan = ({mixpanel}) => {
  const { currentUser } = useContext(AppContext);
  const { uuid } = useParams();
  const location = useLocation();

  const [availablePlans, setAvailablePlans] = useState();
  const [plans, setPlans] = useState();
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState('gbp');
  const [fixedCurrencyCode, setFixedCurrencyCode] = useState();
  const [selectedPlanInterval, setSelectedPlanInterval] = useState('year');
  const [showCreateCustomer, setShowCreateCustomer] = useState();
  const [customer, setCustomer] = useState();
  const [creatingCustomer, setCreatingCustomer] = useState();
  const [creatingCustomerError, setCreatingCustomerError] = useState();
  const [creatingSession, setCreatingSession] = useState();
  const [selectedPlan, setSelectedPlan] = useState({});
  const [selectedPlanId, setSelectedPlanId] = useState();
  const [loading, setLoading] = useState();
  const [planLoadingError, setPlanLoadingError] = useState();
  const [customerLoadingError, setCustomerLoadingError] = useState();

  const zukoRef = useRef();

  const getCurrencySymbolFromCode = (code) => {
    switch (code.toLowerCase()) {
    case 'gbp':
      return '£';
    case 'usd':
      return '$';
    case 'eur':
      return '€';
    default:
      setPlanLoadingError(`Unsupported currency code: '${code}'`);
    }
  };

  const otherPlanInterval = (interval) => {
    return interval.toLowerCase() === 'month' ? 'year' : 'month';
  };

  const togglePlanInterval = () => {
    setSelectedPlanInterval(otherPlanInterval(selectedPlanInterval));
  };

  const toggleCurrency = (selectedCurrencyValue) => {
    setSelectedCurrencyCode(selectedCurrencyValue);
  };

  const handlePlanSelect = (selectedPlan) => {
    setShowCreateCustomer(true);
    setSelectedPlan(selectedPlan);
    setSelectedPlanId(selectedPlan.id);

    mixpanel.track('Choose a plan', Object.assign({ page: 'ChoosePlan', product: 'builder', plan: selectedPlan.id }, location.state?.organisation?.name ? {
      'Organisation Name': location.state?.organisation.name,
      'Organisation Uuid': uuid,
    } : {}));
  };

  const handleCompanyInfoSubmit = async ({name, address}) => {
    if (process.env.NODE_ENV && process.env.NODE_ENV.toLowerCase() === 'production' &&
      !process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) throw new Error('REACT_APP_STRIPE_PUBLISHABLE_KEY is required');
    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

    try {
      setCreatingCustomer(true);
      setCreatingCustomerError(false);
      const { data: { customer } } = await api.post('/billing/customers', {
        organisationUuid: uuid,
        name,
        email: currentUser.email,
        address,
      });
      setCustomer(customer);
      setCreatingCustomer(false);
      setCreatingSession(true);

      zukoRef.current?.trackEvent({type: 'Confirmed company details'});

      mixpanel.track('Confirmed company details', Object.assign({ page: 'ChoosePlan', product: 'builder' }, location.state?.organisation?.name ? {
        'Organisation Name': location.state?.organisation.name,
        'Organisation Uuid': uuid,
      } : {}));

      const { data: {session: {id: sessionId}} } = await api.post('/builder/billing/checkout/sessions', {
        customerId: customer.id,
        selectedPlanId: selectedPlanId,
      });

      zukoRef.current?.trackEvent({type: 'Being redirected to Stripe Checkout'});

      mixpanel.track('Being redirected to Stripe Checkout', Object.assign({ page: 'ChoosePlan',  product: 'builder' }, location.state?.organisation?.name ? {
        'Organisation Name': location.state?.organisation.name,
        'Organisation Uuid': uuid,
      } : {}));

      // Zuko completion tracking
      zukoRef.current?.trackEvent(window.Zuko?.COMPLETION_EVENT);

      await stripe.redirectToCheckout({sessionId});
    } catch (e) {
      zukoRef.current?.trackEvent({type: `Error confirming company details: ${e.message}`});

      setCreatingCustomerError(true);
      setCreatingSession(false);
    } finally {
      setCreatingCustomer(false);
    }
  };

  const handleCancelCreateCustomer = () => {
    setShowCreateCustomer(false);
    setSelectedPlanId(null);
    setSelectedPlan({});
    setCreatingCustomerError(false);
  };

  useEffect(() => {
    mixpanel.identify(currentUser.email);
    mixpanel.track('Page View', Object.assign({ page: 'ChoosePlan', product: 'builder' }, location.state?.organisation?.name ? {
      'Organisation Name': location.state?.organisation.name,
      'Organisation Uuid': uuid,
      'Organisation Contract Type': location.state?.organisation?.contractType,
      product: 'builder',
    } : {}));
  }, [mixpanel, currentUser.email, uuid, location.state]);

  useEffect(() => {
    if (!window.Zuko) {
      const zukoScript = document.createElement('script');
      zukoScript.src = 'https://assets.zuko.io/js/v2/client.min.js';
      document.body.appendChild(zukoScript);
    }
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true);
        setCustomerLoadingError(null);

        let { data: { customer } } = await api.get(`/billing/customers/${uuid}`);
        // If the Stripe Customer has been deleted, then we want to allow the user to sign up again.
        // We pretend that there's been no Customer found in Stripe, so that a new one is created for the user.
        if (customer && customer.deleted) customer = null;

        // Make sure that the customer has an address object
        // Older Stripe customers sometimes have a null address
        if (customer) customer.address = customer.address || {};

        // Fix the currency already set for the customer
        if (customer?.currency) {
          const allowedCurrencies = ['gbp', 'usd', 'eur'];
          if (allowedCurrencies.includes(customer.currency)) setFixedCurrencyCode(customer.currency);
          if (!allowedCurrencies.includes(customer.currency)) setCustomerLoadingError('There are no available plans in the currency used on your account. Please contact Zuko support.');
        }

        try {
          setPlanLoadingError(null);

          let { data: { plans } } = await api.get('/builder/billing/plans');
          plans = camelcaseKeys(plans, { deep: true });
          setAvailablePlans(plans.reduce((acc, plan) => {
            const { currency, recurring: { interval } } = plan;
            if (!acc.hasOwnProperty(currency)) acc[currency] = {};
            if (!acc[currency].hasOwnProperty(interval)) acc[currency][interval] = [];
            acc[currency][interval].push(plan);
            return acc;
          }, {}));
          if (customer) setCustomer(customer);
        } catch (e) {
          setPlanLoadingError((e.response && (e.response.status === 401)) ? 'Not logged in' : 'Sorry, something went wrong loading the plans. Please try again.');
        } finally {
          setLoading(false);
        }
      } catch (e) {
        setCustomerLoadingError((e.response && (e.response.status === 401)) ? 'Not logged in' :
          (e.response && (e.response.status === 404)) ? 'Organisation not found' : 'Sorry, something went wrong. Please try again.');
      } finally {
        setLoading(false);
      }
    })();
  }, [uuid]);

  useEffect(() => {
    if (availablePlans && (fixedCurrencyCode || selectedCurrencyCode) && selectedPlanInterval) {
      setPlans(availablePlans[fixedCurrencyCode || selectedCurrencyCode][selectedPlanInterval]);
    }
  }, [fixedCurrencyCode, selectedCurrencyCode, selectedPlanInterval, availablePlans]);

  return (
    <div fluid className="choose-plan page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}><title>Choose a plan</title></Helmet>
      <div className="main-content">
        <Col className="center-column justify-content-md-center position-relative">
          <FeedbackRow
            classList={['allow-scroll-under-nav']}
            mixpanel={mixpanel}
            page={'ChoosePlan'}
            org={location.state?.organisation}
            messageContent={'choosing a plan'} />
          <AppAlerts />
          <Row className="title-row g-0">
          </Row>
          <SwitchTransition>
            <CSSTransition key={!showCreateCustomer ? 'showing-plans' : 'showing-billing'} timeout={100} classNames="step-fade">
              {!showCreateCustomer ?
                <div>
                  <Row className="g-0">
                    <Col className="p-0 text-center">
                      <h1 className="step-title">Form Builder | Choose Plan | Step 1 of 3</h1>
                    </Col>
                  </Row>
                  <Row className="tag-line-row g-0">
                    <Col className="p-0 text-center">
                      <p>You're just a few steps away from creating your first Zuko form.</p>
                    </Col>
                  </Row>
                  {loading &&
                    <Row className="g-0 justify-content-center">
                      <Col className="plans-middle-msg text-center">
                        <p><FontAwesomeIcon icon={faCircleNotch} spin fixedWidth/> Loading plans...</p>
                      </Col>
                    </Row>}
                  {!loading && (planLoadingError || customerLoadingError) &&
                      <Row className="g-0 justify-content-center">
                        <Col className="plans-middle-msg text-center">
                          <p>{(customerLoadingError || planLoadingError)}</p>
                        </Col>
                      </Row>}
                  {!loading && !planLoadingError && !customerLoadingError && <>
                    <Row className="g-0 d-flex justify-content-between mb-4">
                      <Col></Col>
                      <Col id="plan-length-toggles" className="p-0 d-flex justify-content-center">
                        <ToggleButtonGroup type="radio" name="plan-length" value={selectedPlanInterval} onChange={togglePlanInterval} defaultValue="month">
                          <ToggleButton variant="outline-secondary" id="month" value="month">Monthly</ToggleButton>
                          <ToggleButton variant="outline-secondary" id="year" value="year">Annual (20% off)</ToggleButton>
                        </ToggleButtonGroup>
                      </Col>
                      <Col id="currency-toggles" className="p-0 text-end">
                        {!fixedCurrencyCode &&
                            <ToggleButtonGroup type="radio" name="currency" value={selectedCurrencyCode} onChange={toggleCurrency} defaultValue="gbp">
                              <ToggleButton variant="outline-secondary" id="gbp" value="gbp" data-testid="currency-toggle-gbp">£</ToggleButton>
                              <ToggleButton variant="outline-secondary" id="usd" value="usd" data-testid="currency-toggle-usd">$</ToggleButton>
                              <ToggleButton variant="outline-secondary" id="eur" className="me-0" value="eur" data-testid="currency-toggle-eur">€</ToggleButton>
                            </ToggleButtonGroup>}
                      </Col>
                    </Row>
                    <CardGroup className="p-0 mb-4 col justify-content-center">
                      <TransitionGroup component={null}>
                        {plans?.length > 0 && [{id: 'free-plan'}].concat(plans).concat([{id: 'enterprise-plan'}]).map(({ id, unitAmount, product, currency }, i) => (
                          <CSSTransition key={i} appear={true} timeout={100} classNames="plan-fade">
                            <Col className="plan-card-col" data-testid={id}>
                              <div className={`card-border-${id === 'enterprise-plan' ? 'custom' : i}`}>
                                <Card key={i} className="m-0 px-3 py-4 text-center">
                                  <Card.Body className="p-0 d-flex flex-column justify-content-between">
                                    {id === 'free-plan' && <>
                                      <small>Up to</small>
                                      <p className="session-count-limit my-0">100</p>
                                      <small>submissions per month</small>
                                      <p className="monthly-change-amount my-0">{getCurrencySymbolFromCode(selectedCurrencyCode)}0</p>
                                      <small>&nbsp;</small>
                                    </>}
                                    {id === 'enterprise-plan' && <>
                                      <small>&nbsp;</small>
                                      <p className="session-count-limit my-0">Unlimited</p>
                                      <small>submissions per month</small>
                                      <p className="custom-package-text mt-2 px-2">Use Zuko Forms throughout your organisation.</p> </>}
                                    {(id !== 'free-plan' && id !== 'enterprise-plan') && <>
                                      <small>Up to</small>
                                      <p className="session-count-limit my-0">{commaNumber(product.metadata.submissionLimit)}</p>
                                      <small>submissions per month</small>
                                      <SwitchTransition>
                                        <CSSTransition key={selectedPlanInterval} timeout={100} classNames="amount-fade"><div>
                                          <SwitchTransition>
                                            <CSSTransition key={currency} timeo_t={100} classNames="amount-fade">
                                              <p className="monthly-change-amount my-0">{`${getCurrencySymbolFromCode(currency)}${commaNumber(((selectedPlanInterval === 'year' ? unitAmount/12 : unitAmount) / 100))}`}</p>
                                            </CSSTransition></SwitchTransition>
                                          <small>per month{selectedPlanInterval === 'year' && <span className="smaller-text text-nowrap"> (billed annually)</span>}</small>
                                        </div>
                                        </CSSTransition>
                                      </SwitchTransition>
                                    </>
                                    }
                                    <div className="features-list text-start d-flex justify-content-center">
                                      <ul className={`${id === 'free-plan' ? 'lite-list' : ''}`}>
                                        <li><TiTick size="18px" className="tick-icon"/> Unlimited custom built forms</li>
                                        {id === 'enterprise-plan' && <>
                                          <li><TiTick size="18px" className="tick-icon"/> Implementation support</li>
                                        </>}
                                        {id !== 'free-plan' && <>
                                          <li><TiTick size="18px" className="tick-icon"/> All features and analytics reports</li>
                                        </>}
                                      </ul>
                                    </div>
                                    {id === 'free-plan' ? <div style={{height: '36.25px'}}></div> :
                                      <div className="align-self-center">
                                        {id === 'enterprise-plan' ? <a href="mailto:sales@zuko.io?subject=Zuko Custom Package Request" className="choose-plan-btn btn btn-primary">Email Sales</a> :
                                          <Button variant="primary" className="choose-plan-btn justify-self-center" onClick={() => handlePlanSelect({id, submissionLimit: product.metadata.submissionLimit,unitAmount,currency})}>Select Plan</Button>}
                                      </div>}
                                  </Card.Body>
                                </Card>
                              </div>
                            </Col>
                          </CSSTransition>
                        ))}
                      </TransitionGroup>
                    </CardGroup>
                    <Row className="g-0 text-center">
                      <Col>
                        <p>By signing up to a paid plan with us, you hereby agree to our <a href="https://www.zuko.io/terms-and-conditions" target="_blank" rel="noopener noreferrer">terms and conditions</a>.</p>
                      </Col>
                    </Row>
                    <hr></hr>
                    <Row className="g-0">
                      <Col className="text-center">
                        <h1 className="step-title">FAQs</h1>
                      </Col>
                    </Row>
                    <Row className="g-0 faqs-row">
                      <Col md={4}>
                        <Card>
                          <Card.Body>
                            <Card.Title>I've used up my free submissions. How can I get more submissions?</Card.Title>
                            <Card.Text>You can upgrade to see all submissions that your form has received.</Card.Text>
                          </Card.Body>
                        </Card>
                        <Card>
                          <Card.Body>
                            <Card.Title>Can we pay by invoice / bank transfer?</Card.Title>
                            <Card.Text>If you are on a custom package or sign up for a 12 month period we can accept payment on invoice.</Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={4}>
                        <Card>
                          <Card.Body>
                            <Card.Title>Will my form still work if I've used all of my submissions?</Card.Title>
                            <Card.Text>Yes your form will continue to collect submissions, but you will only be able to access submissions within your plan limits.</Card.Text>
                            <Card.Text>To avoid this, you can upgrade at any time during the month.</Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col md={4}>
                        <Card>
                          <Card.Body>
                            <Card.Title>Which payment methods do you accept?</Card.Title>
                            <Card.Text>Payment for a selected plan is taken through Stripe which accepts credit cards, debit cards and many other payment methods from around the world.</Card.Text>
                          </Card.Body>
                        </Card>
                        <Card>
                          <Card.Body>
                            <Card.Title>Will I have to pay taxes on top of the cost?</Card.Title>
                            <Card.Text>If you are based in the UK, you will be charged VAT at the current rate. Businesses based outside the UK will not be charged any local taxes.</Card.Text>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </>}
                </div> :
                <div>
                  <Row className="title-row billing-title-row g-0">
                    <Col className="p-0 text-center">
                      <h1 className="step-title">Enter Billing Details | Step 2 of 3</h1>
                    </Col>
                  </Row>
                  <div className="border-company-details mb-3">
                    <Card className="billing-details-card">
                      <Card.Body>
                        <Row className="g-0 card-content">
                          {(creatingCustomer || creatingSession) &&
                                <Col className="transferring-msg-col d-flex justify-content-center align-items-center">
                                  <p className="text-center"><FontAwesomeIcon icon={faCircleNotch} spin fixedWidth/> Transferring to payment...</p>
                                </Col>}
                          {!creatingCustomer && !creatingSession &&
                              <Col>
                                <ChoosePlanCompanyDetailsForm
                                  zukoSlug={zukoSlug}
                                  zukoRef={zukoRef}
                                  product={'builder'}
                                  handleSubmit={handleCompanyInfoSubmit}
                                  customer={customer}
                                  selectedPlan={selectedPlan}
                                  selectedPlanInterval={selectedPlanInterval}
                                  getCurrencySymbolFromCode={getCurrencySymbolFromCode}
                                  creatingCustomerError={creatingCustomerError}
                                  handleCancelCreateCustomer={handleCancelCreateCustomer} />
                              </Col>
                          }
                        </Row>
                      </Card.Body>
                    </Card>
                  </div>
                  <Row className="g-0 text-center">
                    <Col>
                      <p>By signing up to a paid plan with us, you hereby agree to our <a href="https://www.zuko.io/terms-and-conditions" target="_blank" rel="noopener noreferrer">terms and conditions</a>.</p>
                    </Col>
                  </Row>
                </div>}
            </CSSTransition>
          </SwitchTransition>
        </Col>
      </div>
      <script src="https://js.stripe.com/v3/"/>
    </div>
  );
};

export default ChoosePlan;
