import React, { useState, useEffect, useContext } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { Link } from "react-router-dom";
import BuilderContext from './Context';
import { Helmet } from 'react-helmet';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { MdContentCopy } from 'react-icons/md';
import { FaSpinner } from 'react-icons/fa';
import { VscInfo } from 'react-icons/vsc';
import { getIframeCodeBlock } from './share-utils.js';
import FeedbackRow from '../Components/FeedbackRow';
import AppAlerts from '../Components/AppAlerts';

import './Share.scss';

const Share = ({mixpanel, hostedFormUrl, handleUnpublishForm}) => {
  const {
    formsError, form,
  } = useContext(BuilderContext);
  const [codeCopyMsg, setCodeCopyMsg] = useState();
  const [showCodeCopyMsg, setShowCodeCopyMsg] = useState(false);
  const [urlCopyMsg, setUrlCopyMsg] = useState(false);
  const [showUrlCopyMsg, setShowUrlCopyMsg] = useState(false);
  const iframeCode = (form && hostedFormUrl) && renderToStaticMarkup(getIframeCodeBlock({hostedFormUrl, formUuid: form.uuid, formTitle: form.title}));

  const handleCodeCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(iframeCode);
      setCodeCopyMsg('Code copied');
    } catch (e) {
      setCodeCopyMsg('Auto copy failed. Please try copying the code manually.');
    } finally {
      setShowCodeCopyMsg(true);
    }
  };

  const handleUrlCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(hostedFormUrl);
      setUrlCopyMsg('URL copied');
    } catch (e) {
      setUrlCopyMsg('Auto copy failed. Please try copying the URL manually.');
    } finally {
      setShowUrlCopyMsg(true);
    }
  };

  useEffect(() => {
    if (showCodeCopyMsg) setTimeout(() => {setShowCodeCopyMsg(null);}, 2500);
    if (showUrlCopyMsg) setTimeout(() => {setShowUrlCopyMsg(null);}, 2500);
  },[showCodeCopyMsg, showUrlCopyMsg]);

  useEffect(() => {
    mixpanel?.track('Page View', { page: 'Share/Embed', product: 'builder' });
  }, [mixpanel]);

  return (
    <div className="share-page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Form Builder | Share/Embed</title>
      </Helmet>
      <Col className="center-column">
        <div className="pt-2">
          <FeedbackRow
            classList={['allow-scroll-under-nav']}
            mixpanel={mixpanel}
            page={'Share/Embed'}
            messageContent={'sharing/embedding your form'} />
        </div>
        <AppAlerts />
        {form && !form.published &&
          <Alert variant="info" className="mt-3" closeVariant="white">
            <div className="alert-svg-icon my-auto"><VscInfo size="20px" className="me-2"/></div>
            <p className="alert-text m-0">Please first pubilsh your form from the <Link to={`/builder/forms/${form.uuid}/build`}>Build page</Link>.</p>
          </Alert>}
        {formsError && <p className="p-3 text-center">{formsError}</p>}
        {!formsError && <>
          <Card className="mt-3">
            <Card.Body>
              <Row className="g-0 card-title-row justify-content-between">
                <Col className="p-0 col-auto">
                  <Card.Title as="h3">Share a direct link</Card.Title>
                </Col>
              </Row>
              <Row className="g-0 card-content">
                <p>View and share your form which is hosted on this custom URL.</p>
                <Row className="g-0 pb-2">
                  {form && !form.published && <p className="fw-light fs-5">Available when published.</p>}
                  {!hostedFormUrl ? <FaSpinner size="20px" className="spinning-icon"/> : form?.published && <>
                    <Col className="p-0 col-auto d-flex align-items-center">
                      <a href={hostedFormUrl} target="_blank" rel="noopener noreferrer">{hostedFormUrl}</a>
                    </Col>
                    <Col className="p-0">
                      <OverlayTrigger show={showUrlCopyMsg ? true : false} placement="top"
                        onExited={() => setShowUrlCopyMsg(null)} // Safely remove the message once the tooltip is no longer visible
                        overlay={
                          <Tooltip id="copy-text-tooltip" className="position-fixed">{urlCopyMsg}</Tooltip>}>
                        <Button variant="outline-secondary" className="copy-text-button d-flex align-items-center" onClick={handleUrlCopyClick}>
                          <MdContentCopy size="20px" title="Copy URL" className="me-1"/> Copy URL</Button>
                      </OverlayTrigger>
                    </Col>
                  </>}
                </Row>
              </Row>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Row className="g-0 card-title-row justify-content-between">
                <Col className="p-0 col-auto">
                  <Card.Title as="h3">Embed into your site</Card.Title>
                </Col>
              </Row>
              <Row className="g-0 card-content">
                <p>Add this code to your site where you'd like your form to show.</p>
                <Row className="g-0 pb-3 flex-column">
                  {form && !form.published && <p className="fw-light fs-5">Available when published.</p>}
                  {!iframeCode ? <FaSpinner size="20px" className="spinning-icon"/> : form?.published && <>
                    <Col className="codeExample p-0">
                      <pre>{iframeCode}</pre>
                    </Col>
                    <Col className="px-0 pb-0 pt-3">
                      <OverlayTrigger show={showCodeCopyMsg ? true : false} placement="top"
                        onExited={() => setShowCodeCopyMsg(null)} // Safely remove the message once the tooltip is no longer visible
                        overlay={
                          <Tooltip id="copy-text-tooltip" className="position-fixed">{codeCopyMsg}</Tooltip>}>
                        <Button variant="outline-secondary" className="ms-0 copy-text-button d-flex align-items-center" onClick={handleCodeCopyClick}>
                          <MdContentCopy size="20px" title="Copy iframe embed code" className="me-1"/> Copy Code</Button>
                      </OverlayTrigger>
                    </Col>
                  </>}
                </Row>
              </Row>
            </Card.Body>
          </Card>
          <Card>
            <Card.Body>
              <Row className="g-0 card-title-row justify-content-between">
                <Col className="p-0 col-auto">
                  <Card.Title as="h3">Unpublish</Card.Title>
                </Col>
              </Row>
              <Row className="g-0 card-content">
                <p>If you wish to no longer use this form, you can unpublish the public version. Please make sure to remove any links you have to this form on your site.</p>
                <Row className="g-0 pb-3">
                  {form && !form.published && <p className="fw-light fs-5">Available when published.</p>}
                  {form?.published && <Button variant="outline-danger" className="ms-0" onClick={() => handleUnpublishForm()}>Unpublish Form</Button>}
                </Row>
              </Row>
            </Card.Body>
          </Card>
        </>}
      </Col>
    </div>
  );
};

export default Share;
