import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import BuilderContext from './Context';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Alert from 'react-bootstrap/Alert';
import FormGroup from 'react-bootstrap/FormGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import InputGroup from 'react-bootstrap/InputGroup';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import AppAlerts from '../Components/AppAlerts';
import FeedbackRow from '../Components/FeedbackRow';
import { MdContentCopy } from 'react-icons/md';
import { VscInfo } from 'react-icons/vsc';
import api from '../api';
import './Integrations.scss';

const Integrations = ({mixpanel}) => {
  const {
    form,
  } = useContext(BuilderContext);
  const history = useHistory();

  const [formUuidCopyMsg, setFormUuidCopyMsg] = useState();
  const [showFormUuidCopyMsg, setShowFormUuidCopyMsg] = useState(false);
  const [webhook, setWebhook] = useState();
  const [webhookLoadingError, setWebhookLoadingError] = useState();
  const [webhookSaving, setWebhookSaving] = useState(false);
  const [webhookSaveSuccessful, setWebhookSaveSuccessful] = useState(false);
  const [webhookSaveAttempted, setWebhookSaveAttempted] = useState(false);

  const handleFormUuidCopy = async () => {
    try {
      await navigator.clipboard.writeText(form.uuid);
      setFormUuidCopyMsg('Copied!');
    } catch (e) {
      setFormUuidCopyMsg('Sorry, failed to copy text to clipboard. Please try copying the text manually.');
    } finally {
      setShowFormUuidCopyMsg(true);
    }
  };

  const handleSaveWebhookEndpoint = useCallback(async (e) => {
    e.preventDefault();
    console.debug('Webhook save called', {webhook});
    if (webhook?.url && form?.uuid) {
      setWebhookSaveAttempted(true);
      setWebhookSaving(true);
      try {
        if (!webhook?.uuid) {
          // Create
          const { data: { subscription } } =
            await api.post(`/builder/forms/${form.uuid}/submissions/hooks/subscriptions`, {targetUrl: webhook.url, integrationType: 'webhook'});
          setWebhook(subscription);
        } else {
          // Update
          const { data: { subscription } } =
            await api.put(`/builder/forms/${form.uuid}/submissions/hooks/subscriptions/${webhook.uuid}`, {targetUrl: webhook.url});
          setWebhook(subscription);
        }
        setWebhookSaveSuccessful(true);
        console.debug('Webhook save completed successfully');
        setTimeout(() => setWebhookSaveAttempted(false), 3000);
      } catch (e) {
        setWebhookSaveSuccessful(false);
        console.warn('Webhook save completed with an error', e);
      } finally {
        setWebhookSaving(false);
        console.debug('Webhook save completed');
      }
    }
  }, [form?.uuid, webhook]);

  useEffect(() => {
    (async ()=> {
      if (form?.uuid && form.uuid !== 'new') {
        try {
          setWebhookLoadingError(null);
          console.debug('Loading webhook subscriptions');
          const { data: { subscriptions } } = await api.get(`/builder/forms/${form.uuid}/submissions/hooks/subscriptions`);
          console.debug('Retreived subscriptons', {subscriptions});
          const webhooks = subscriptions.filter((sub) => sub.integrationType === 'webhook');
          if (webhooks.length > 1) console.warn('There is more than one webhook subscription for this user+form');
          setWebhook(webhooks?.[0] || {});
        } catch (e) {
          switch (e?.response?.status) {
          case 401:
            setWebhookLoadingError('Not logged in');
            break;
          case 403:
            history.replace('/');
            break;
          default:
            setWebhookLoadingError('Something went wrong. Webhook could not load.');
          }
        }
      }
    })();
  }, [form?.uuid, history]);

  useEffect(() => {
    mixpanel?.track('Page View', { page: 'Integrations', product: 'builder' });
  }, [mixpanel]);

  // Remove copy msg after a short time
  useEffect(() => {
    if (showFormUuidCopyMsg) setTimeout(() => {setShowFormUuidCopyMsg(null);}, 2500);
  },[showFormUuidCopyMsg]);

  return (
    <div className="integrations-page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Form Builder | Integrations</title>
      </Helmet>
      <Col className="center-column">
        <div className="pt-2">
          <FeedbackRow
            classList={['allow-scroll-under-nav']}
            mixpanel={mixpanel}
            page={'Integrations'}
            messageContent={'integrations'} />
        </div>
        <AppAlerts />
        {form && !form.published &&
          <Alert variant="info" className="mt-3" closeVariant="white">
            <div className="alert-svg-icon my-auto"><VscInfo size="20px" className="me-2"/></div>
            <p className="alert-text m-0">Please first pubilsh your form from the <Link to={`/builder/forms/${form.uuid}/build`}>Build page</Link>.</p>
          </Alert>}
        <Card className="mt-3">
          <Card.Body>
            <Row className="g-0 card-title-row justify-content-between">
              <Col className="p-0 col-auto">
                <Card.Title as="h3">Integrations</Card.Title>
              </Col>
            </Row>
            <div className="card-content">
              <p>Use an integration to handle your form submissions in a way that most suits your business.</p>
              <div className="integration-info pt-3">
                <h4>Zapier</h4>
                <p>Within Zapier you are able to send your submission data to a wide range of connected apps.</p>

                <ul>
                  <li>Use this public link to connect to the Zuko Form Builder integration: <a target="_blank" rel="noopener noreferrer"
                    href="https://zapier.com/developer/public-invite/196279/f80bfaa173f0625b178d1e81df174507">https://zapier.com/developer/public-invite/196279/f80bfaa173f0625b178d1e81df174507</a>.</li>
                  <li>
                    <p>Here are the credentials you need to connect a Zap to your Zuko account for this form:</p>
                    <FormGroup className="mb-2 d-flex align-items-center" controlId="formID">
                      <Form.Label className="text-nowrap mb-0 me-2">Form ID</Form.Label>
                      <InputGroup>
                        <Form.Control readOnly type="text" defaultValue={form?.uuid !== 'new' ? form?.uuid : ''}/>
                        <OverlayTrigger show={showFormUuidCopyMsg ? true : false}
                          onExited={() => setFormUuidCopyMsg(null)} // Safely remove the message once the tooltip is no longer visible
                          overlay={
                            <Tooltip id="copy-text-tooltip" style={{position:"fixed"}}>{formUuidCopyMsg}</Tooltip>}>
                          <InputGroup.Text className="text-copy-container" onClick={handleFormUuidCopy}>
                            <MdContentCopy size="20px" className="grey-icon" title="Copy Form ID"/>
                          </InputGroup.Text>
                        </OverlayTrigger>
                      </InputGroup>
                    </FormGroup>

                    <FormGroup className="d-flex align-items-center">
                      <Form.Label className="text-nowrap mb-0 me-2">API Key</Form.Label>
                      <p className="ms-1 mb-0 value-info">View or generate your API Key in your <Link to="/builder/profile">profile</Link>.</p>
                    </FormGroup>
                  </li>
                </ul>

              </div>
              <div className="integration-info pt-3">
                <h4>Webhook</h4>
                <p>Your form submissions can be sent to a webhook endpoint as they are received.</p>
                {webhookLoadingError && <p>{webhookLoadingError}</p>}
                {!webhookLoadingError && <Form>
                  <Form.Group className="mb-3">
                    <Form.Label>Endpoint</Form.Label>
                    <Form.Control type="url" placeholder="https://example.com/api/hooks"
                      value={webhook?.url || ''} onChange={({target: {value: url}}) => setWebhook({...webhook, url})}/>
                    <Form.Text className="text-muted">
                      The endpoint where you would like your submissions to be sent.
                    </Form.Text>
                  </Form.Group>
                  <Button variant="primary" type="submit" className="ms-0" disabled={(webhookSaving || !webhook)}
                    onClick={handleSaveWebhookEndpoint}>Save</Button>

                  {!webhookSaving && webhookSaveAttempted && webhookSaveSuccessful &&
                    <Form.Text className="text-muted successful">
                      Save successful.
                    </Form.Text>
                  }
                  {!webhookSaving && webhookSaveAttempted && !webhookSaveSuccessful &&
                    <Form.Text className="text-muted failed">
                      Save failed. Please check the endpoint and try again.
                    </Form.Text>
                  }
                </Form>}
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
};

export default Integrations;
