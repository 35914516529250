import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import {Link, useLocation} from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Button from 'react-bootstrap/Button';
import React, { useState, useEffect, useCallback } from 'react';
import AppContext from './AppContext';
import './NavBar.scss';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import api from './api';
import { compileQueryString, compileFormOnlyQueryString, compileFieldComparisonQueryString, orgDetailsForMixpanel } from './utils';
import { HiExternalLink } from 'react-icons/hi';

// NB. For any steps added within the Nav Dropdowns, 'next-step-{number}' css style must be amended to keep the dropdown open
const introJsSteps = [
  {
    element: '#main-nav',
    intro: 'Welcome to Zuko - here\'s a quick overview of the different areas of the application.',
    tooltipClass: 'first-tooltip',
  },
  {
    element: '#insights-nav-link',
    intro: 'Get your headlines for a quick view on what is happening on your form in <strong>Insights</strong>.' +
      '<br><br>' +
      '<ul className=\'intro-list\'>' +
      '<li>View the most abandoned and returned to field</li>' +
      '<li>Snapshot of failed submission flow</li>' +
      '</ul>',
  },
  {
    element: '#form-aggregate-nav-link',
    intro: '<strong>Form Data</strong> gives you a top-line view of your forms\' performance.' +
      '<br><br>' +
      '<ul className=\'intro-list\'>' +
      '<li>Key performance metrics</li>' +
      '<li>Custom events you\'re sending to Zuko</li>' +
      '</ul>',
  },
  {
    element: '#field-aggregate-nav-link',
    intro: '<strong>Field Data</strong> helps you to understand visitor behaviour within the inputs and fields of your form.' +
      '<br><br>' +
      '<ul className=\'intro-list\'>' +
      '<li>Field-level metrics</li>' +
      '<li>Compare sessions that successfully completed vs. abandoned</li>' +
      '</ul>',
  },
  {
    element: '#session-replay-nav-link',
    intro: 'Watch a recording of anonymised user sessions on your forms with <strong>Session Replay</strong>.' +
      '<br><br>' +
      '<ul className=\'intro-list\'>' +
      '<li>Powerful search to narrow down problem fields</li>' +
      '<li>Visual insight into friction</li>' +
      '</ul>',
  },
  {
    element: '#form-segment-comparison-nav-link',
    intro: 'Use <strong>Form Segment Comparison</strong> to compare multiple segments\' performance across one form or multiple forms.' +
      '<br><br>' +
      '<ul className=\'intro-list\'>' +
      '<li>Compare key form metrics</li>' +
      '<li>Create unlimited comparisons</li>' +
      '</ul>',
  },
  {
    element: '#field-segment-comparison-nav-link',
    intro: 'Use <strong>Field Segment Comparison</strong> to compare multiple segments\' performance across multiple fields.' +
      '<br><br>' +
      '<ul className=\'intro-list\'>' +
      '<li>Compare key metrics for fields</li>' +
      '<li>Create multiple segments</li>' +
      '</ul>',
  },
  {
    element: '#field-flow-nav-link',
    intro: '<strong>Field Flow</strong> is useful to follow how your visitors move through your form.' +
      '<br><br>' +
      '<ul className=\'intro-list\'>' +
      '<li>Identify problem fields</li>' +
      '<li>See what abandoned visitors do after clicking Submit</li>' +
      '</ul>',
  },
  {
    element: '#funnel-nav-link',
    intro: 'Build a <strong>Funnel</strong> to group fields together and see data from different steps in your form.' +
      '<br><br>' +
      '<ul className=\'intro-list\'>' +
      '<li>View form progression through to completion</li>' +
      '<li>See how many sessions abandon at each step</li>' +
      '</ul>',
  },
  {
    element: '#session-explorer-nav-link',
    intro: '<strong>Session Explorer</strong> allows you to closely examine individual visitor journeys through your form.' +
      '<br><br>' +
      '<ul className=\'intro-list\'>' +
      '<li>Deep-dive to discover patterns of behaviours</li>' +
      '<li>Quickly find pain points in fields</li>' +
      '</ul>',
  },
  {
    element: '#alerts-nav-link',
    intro: 'Set up real-time <strong>Alerts</strong> to give you an early warning sign should any metrics change outside of set thresholds.' +
      '<br><br>' +
      '<ul className=\'intro-list\'>' +
      '<li>Form metrics evaluated hourly</li>' +
      '<li>Alerts then emailed if necessary</li>' +
      '</ul>',
  },
  {
    element: '#live-nav-link',
    intro: 'See real-time Views and Completions on your form via <strong>Live</strong> events.' +
      '<br><br>' +
      '<ul className=\'intro-list\'>' +
      '<li>Data refreshes every 10 seconds</li>' +
      '</ul>',
    tooltipClass: 'last-tooltip',
  },
];

const MoreNavWrapper = ({children, handleClick}) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth > 991 && windowWidth < 1280 ? // NB. Nav Collapse takes effect at 991px
    <NavDropdown title="More" className="more-dropdown" onClick={handleClick}>{children}</NavDropdown> : children;
};

const NavBar = (props) => {
  const { currentUser, setUser, currentOrg, query, resetSession, currentUserRoleInOrgs } = React.useContext(AppContext);
  const { form, time, filters, granularity, sessionOutcomes, fieldFlow, sessionExplorer, sessionFilters,
    submitFieldIdentifier,
    fieldSegmentComparison: { comparisonUuid, metric: fieldComparisonMetric } = {},
  } = query || {};
  const org = (currentOrg?.uuid && currentOrg) || (Array.isArray(currentUser.organisations) && currentUser.organisations[0]);
  const location = useLocation();
  const [currentUserFirstAgency, setCurrentUserFirstAgency] = useState();
  const [currentUserIsStandardInOrg, setCurrentUserIsStandardInOrg] = useState();
  const firstOrg = currentUser.organisations && (currentUser.organisations.find(o => (o.contractType === 'monthly' || o.contractType === 'fixed')) || currentUser.organisations.find(o => o.contractType === 'trial'));

  const logout = async () => {
    try {
      await api.post('/logout');
    } catch (e) {/* Continue with removing the user */}
    localStorage.clear();
    setUser(null);
    resetSession();
  };

  // Declare the showIntroJs flag on state with a default value of 'false'. And declare the associated call - setShowIntroJs - to set this state.
  const [showIntroJs, setShowIntroJs] = useState(false);
  const [currentStepReached, setCurrentStepReached] = useState(null);
  const [nextStep, setNextStep] = useState();
  const pathLookUp = window.location.pathname === '/' ? '/dashboard' :
    window.location.pathname.includes('alerts') ? '/alerts' :
      window.location.pathname.includes('organisations_usage') ? '/usage' :
        (currentUserFirstAgency && window.location.pathname.includes(`organisations/${currentUserFirstAgency.uuid}`)) ? '/agency' :
          window.location.pathname.includes('organisations') ? '/organisations' :
            location.pathname.replace(/\/$/, '');

  const showBuilderLink = useCallback(() => {
    if ((currentUser?.organisations?.[0]?.signUpProduct && currentUser?.organisations?.[0]?.signUpProduct === 'builder') ||
      currentUser?.accountManager) return true;
  }, [currentUser]);

  const showOrgSignUpBtn = useCallback(() => {
    if (
      currentUserIsStandardInOrg ||
      pathLookUp.includes('/new') ||
      currentUser.organisations?.length > 1 || // Don't show if they are part of multiple orgs
      !org ||
      org?.type === 'AgencyClient') return false;

    if (org && ((org.subscriptionStatus && org.subscriptionStatus === 'canceled') ||
      (org.contractType && org.contractType === 'trial'))) return true;
  }, [currentUser?.organisations, org, pathLookUp, currentUserIsStandardInOrg]);

  useEffect(() => {
    // Once rendered conditionally check the user and showIntroJs flag.
    // This condition must be within an effect method so that it only runs one.
    // Outside of useEffect this condition satisfies twice due to the time between the parent component re-rendering and setShowIntroJs.
    const pathWhiteListForIntro = ['/form_aggregate', '/field_aggregate', '/field-flow', '/funnel', '/session_explorer', '/form-segment-comparison', '/field-segment-comparison', '/alerts', '/live', '/insights'];
    if (!currentUser.hasDismissedIntro && !showIntroJs && pathWhiteListForIntro.includes(pathLookUp)) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant',
      });
      setShowIntroJs(true);
    }
  },
  // Passing these arguments tell React to skip applying an effect if the values haven't changed between re-renders.
  [currentUser.hasDismissedIntro, showIntroJs, pathLookUp]);

  useEffect(() => {
    props.mixpanel.identify(currentUser.email);
  }, [currentUser.email, props.mixpanel]);

  const handleOnExitIntro = async (currentStep) => {
    if (handleOnExitIntro < 0) return;
    setShowIntroJs(false);
    setCurrentStepReached(currentStep);
    setUser({...currentUser, hasDismissedIntro: true}); // Set back in context for use in rest of the app
  };

  // Once a step has been reached, update the user and send the mixpanel event
  useEffect(() => {
    if (typeof currentStepReached === 'number' && currentStepReached > -1 && currentUser.hasDismissedIntro) {
      try {
        api.patch(`/users/${currentUser.uuid}`, { user: { hasDismissedIntro: true } });
      } catch (e) { /* No further action. This is a background process */ }

      if (currentStepReached + 1 === introJsSteps.length) {
        props.mixpanel.track('Intro Completed', {...orgDetailsForMixpanel(firstOrg)});
      } else {
        props.mixpanel.track('Intro Ended Early', {'Intro Step Reached': currentStepReached + 1, ...orgDetailsForMixpanel(firstOrg)});
      }
    }

  }, [currentStepReached, props.mixpanel, currentUser.hasDismissedIntro, currentUser.uuid, firstOrg]);

  useEffect(() => {
    setCurrentUserFirstAgency(currentUser?.organisations.find(o => o.type === 'Agency'));
  }, [currentUser?.organisations]);

  useEffect(() => {
    if (!currentUser.accountManager && org) {
      setCurrentUserIsStandardInOrg(currentUserRoleInOrgs?.[org.uuid]?.name !== 'admin' ?? true);
    }
  }, [currentUser?.accountManager, currentUserRoleInOrgs, org]);

  return ( <>
    {showIntroJs && <Steps
      enabled={showIntroJs}
      steps={introJsSteps}
      initialStep={0}
      onExit={handleOnExitIntro} // NB in dev this will automatically exit due to Strict mode effectively closing and re-opening the component.
      options={{
        showStepNumbers: false,
        overlayOpacity: 0.5,
        showProgress: true,
        highlightClass: 'intro-helper-layer',
        tooltipClass: 'intro-reference-layer',
        // NB. exitOnOverlayClick and exitOnEsc are disabled as we can only log the currentStep from the onExit callback - when click Exit/Done
        exitOnOverlayClick: false,
        exitOnEsc: false,
        nextLabel: 'Next',
        prevLabel: 'Back',
      }}
      onBeforeChange={(nextStepIndex) => setNextStep(nextStepIndex)}
    />}
    <Navbar collapseOnSelect expand="lg" variant="dark" id="main-nav" data-testid="main-nav">
      <Container className="mw-100">
        <Navbar.Brand as={Link} to="/" onClick={props.handleClick}><div className="zuko-logo" role="img" alt="logo"></div></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className={`me-auto ${showIntroJs ? 'show-intro-js next-step-' + nextStep : ''}`} activeKey={pathLookUp}>
            <Nav.Link as={Link} to="/dashboard" eventKey="/dashboard" id="dashboard-nav-link" onClick={props.handleClick}>Dashboard</Nav.Link>
            <Nav.Link as={Link} to={`/insights${(form && time) ? compileQueryString({form, time, submitFieldIdentifier, filters}) : ''}`}
              eventKey="/insights" id="insights-nav-link" onClick={props.handleClick}>Insights</Nav.Link>
            <Nav.Link as={Link} to={`/form_aggregate${(form && time) ? compileQueryString({form, time, granularity, filters, submitFieldIdentifier}) : ''}`}
              eventKey="/form_aggregate" id="form-aggregate-nav-link" onClick={props.handleClick}>Form Data</Nav.Link>
            <Nav.Link as={Link} to={`/field_aggregate${(form && time) ? compileQueryString({form, time, granularity, filters}) : ''}`}
              eventKey="/field_aggregate" id="field-aggregate-nav-link" onClick={props.handleClick}>Field Data</Nav.Link>
            <Nav.Link as={Link} to={`/session-replay${(form && time) ? compileQueryString({form, time, filters, sessionOutcomes, sessionFilters}) : ''}`}
              eventKey="/session-replay" id="session-replay-nav-link" onClick={props.handleClick}><span className="text-with-beta">Session Replay</span>
              <sup><span className="badge badge-pill badge-primary beta-tab-badge">NEW</span></sup></Nav.Link>

            <MoreNavWrapper handleClick={props.handleClick}>
              <NavDropdown title="Segments" renderMenuOnMount={true} className="segments-dropdown" id="segments-dropdown" onClick={props.handleClick}>
                <NavDropdown.Item as={Link} to="/form-segment-comparison"
                  eventKey="/form-segment-comparison" id="form-segment-comparison-nav-link" onClick={props.handleClick} className="main-form-comparison-link">Form Segment Comparison</NavDropdown.Item>
                <NavDropdown.Item as={Link}
                  to={`/field-segment-comparison${compileFieldComparisonQueryString({
                    comparisonUuid,
                    time,
                    metric: fieldComparisonMetric,
                  })}`}
                  eventKey="/field-segment-comparison" id="field-segment-comparison-nav-link" onClick={props.handleClick} className="main-field-comparison-link">
                  Field Segment Comparison
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="User Journeys" renderMenuOnMount={true} className="user-journeys-dropdown" id="user-journeys-dropdown" onClick={props.handleClick}>
                <NavDropdown.Item as={Link} to={`/field-flow${(form && time) ? compileQueryString({form, time, filters, sessionOutcomes, fieldFlow}) : ''}`}
                  eventKey="/field-flow" id="field-flow-nav-link" onClick={props.handleClick} className="main-field-flow-link">Field Flow</NavDropdown.Item>
                <NavDropdown.Item as={Link} to={`/funnel${(form && time) ? compileQueryString({form, time, filters}) : ''}`}
                  eventKey="/funnel" id="funnel-nav-link" onClick={props.handleClick} className="main-funnel-link">Funnel Builder</NavDropdown.Item>
                <NavDropdown.Item as={Link} to={`/session_explorer${(form && time) ? compileQueryString({form, time, filters, sessionOutcomes, sessionExplorer, sessionFilters}) : ''}`}
                  eventKey="/session_explorer" id="session-explorer-nav-link" onClick={props.handleClick} className="main-explorer-link">Session Explorer</NavDropdown.Item>
              </NavDropdown>

              <NavDropdown title="Monitor" renderMenuOnMount={true} className="monitoring-dropdown" id="monitoring-dropdown" onClick={props.handleClick}>
                {org && <NavDropdown.Item as={Link} to={`/organisations/${org.uuid}/alerts`}
                  eventKey="/alerts" id="alerts-nav-link" onClick={props.handleClick} className="main-alerts-link">Alerts</NavDropdown.Item>}
                <NavDropdown.Item as={Link} to={`/live${form ? compileFormOnlyQueryString(form) : ''}`}
                  eventKey="/live" id="live-nav-link" onClick={props.handleClick} className="main-live-link">Live</NavDropdown.Item>
                {org && <NavDropdown.Item as={Link} to={`/users/${currentUser.uuid}/organisations_usage`} eventKey="/usage" onClick={props.handleClick}>Usage</NavDropdown.Item>}
              </NavDropdown>
            </MoreNavWrapper>
          </Nav>
          <Nav activeKey={pathLookUp}>
            {(org || currentUser.accountManager) &&
            <Nav.Link as={Link} to="/forms/new" eventKey="/forms/new"  id="add-form-nav-link" onClick={props.handleClick}>Add Form</Nav.Link>}
            {currentUserFirstAgency &&
            <Nav.Link as={Link} to={`/organisations/${currentUserFirstAgency.uuid}/edit`} eventKey="/agency" onClick={props.handleClick}>Agency</Nav.Link>}
            {showOrgSignUpBtn() &&
             <Link to={{pathname: `/organisations/${org.uuid}/choose-plan`, state: {organisation: {name: org.name}}}}><Button className="me-0 ms-1 sign-up-btn text-nowrap">Sign Up</Button></Link>}
            <NavDropdown title={`Hello, ${currentUser.name || ''}`} onClick={props.handleClick} className="user-dropdown" id="user-dropdown">
              <NavDropdown.Item to="/profile" as={Link} eventKey="/profile" onClick={props.handleClick}>Profile</NavDropdown.Item>
              {currentUser.accountManager &&
                <NavDropdown.Item as={Link} to="/accounts" eventKey="/accounts" onClick={props.handleClick}>Accounts</NavDropdown.Item>}
              {org && <NavDropdown.Item as={Link} to={`/organisations/${org.uuid}/edit`} eventKey="/organisations" onClick={props.handleClick}>Organisations</NavDropdown.Item>}
              <NavDropdown.Item target="_blank" rel="noopener noreferrer" href="https://www.zuko.io/guides" onClick={props.handleClick}>Guides <HiExternalLink /></NavDropdown.Item>
              <NavDropdown.Item target="_blank" rel="noopener noreferrer" href="https://docs.zuko.io" onClick={props.handleClick}>Documentation <HiExternalLink /></NavDropdown.Item>
              {showBuilderLink() && <NavDropdown.Item as={Link} to="/builder/forms" onClick={props.handleClick} id="nav-switch-app-btn-link">
                <Button className="switch-app-btn btn-less-padding m-0">Form Builder</Button>
              </NavDropdown.Item>}
              <NavDropdown.Divider/>
              <NavDropdown.Item onClick={() => logout()}>Logout</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </>);
};

export default NavBar;
