import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useHistory, useLocation, useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import FormGroup from 'react-bootstrap/FormGroup';
import Select from 'react-select';
import FiltersSelect from '../Components/Select/FiltersSelect';
import moment from 'moment-timezone';
import {
  LineController, LineElement, PointElement, LinearScale, TimeSeriesScale, // For time series line charts
  Tooltip, Legend // Additional utils
} from 'chart.js';
import { ReactChart } from 'chartjs-react';
import 'chartjs-adapter-moment';
import annotationPlugin from 'chartjs-plugin-annotation';
import ProgressBar from 'react-bootstrap/ProgressBar';
import TimePicker from 'react-time-picker';
import addrs from 'email-addresses';
import qs from 'qs';
import { formatCellToStr, formatNumToStringWithDecimals, formatFiltersForSelectDropDown } from '../utils';

import AppContext from '../AppContext';
import { usePrevious } from '../hooks';
import { orgDetailsForMixpanel } from '../utils';
import NavBar from '../NavBar';
import DatePicker from '../Components/DatePicker';
import AppAlerts from '../Components/AppAlerts';
import FeedbackRow from '../Components/FeedbackRow';
import AlertsInfoOverlay from './AlertsInfoOverlay';
import { VscChevronLeft } from 'react-icons/vsc';
import { FaInfoCircle } from 'react-icons/fa';
import { FaCheck } from "react-icons/fa6";
import api from '../api';

ReactChart.register(LineController, LineElement, PointElement, LinearScale, Tooltip, Legend, TimeSeriesScale, annotationPlugin);

const alertTypes = {
  eventCount: 'event_count',
  sessionMetric: 'session_metric',
  sessionOutcome: 'session_outcome',
};
const alertOptions = [
  {value: 'completed', label: 'Completed Session Count', type: alertTypes.sessionOutcome},
  {value: 'abandoned', label: 'Abandoned Session Count', type: alertTypes.sessionOutcome},
  {value: 'completion_rate', label: 'Completion Rate (%)', type: alertTypes.sessionMetric},
  {value: 'abandon_rate', label: 'Abandon Rate (%)', type: alertTypes.sessionMetric},
];
const legacyAlertOptions = [
  {value: 'completion', label: 'Completion Event Count', type: alertTypes.eventCount},
  {value: 'abandon', label: 'Abandon Event Count', type: alertTypes.eventCount},
];
const alertComparators = [{value: '<', label: 'less than'}, {value: '>', label: 'more than'}];

const AlertConfig = ({mixpanel, configType}) => {
  const {uuid: alertUuid} = useParams();
  const location = useLocation();
  const { currentUser, timeZone, setCurrentOrg } = useContext(AppContext);
  const history = useHistory();

  const [form, setForm] = useState({});
  const [formLoading, setFormLoading] = useState(false);
  const [formError, setFormError] = useState(null);
  const [alertOption, setAlertOption] = useState();
  const [chartData, setChartData] = useState(null);
  const [chartLoading, setChartLoading] = useState(false);
  const [chartProgress, setChartProgress] = useState(20);
  const [chartError, setChartError] = useState(null);
  const [threshold, setThreshold] = useState(null);
  const [comparator, setComparator] = useState({value: '<', label: 'less than'});
  const [thresholdError, setThresholdError] = useState(null);
  const [alertFrequency, setAlertFrequency] = useState('alert-anytime');
  const [alertBetweenFrom, setAlertBetweenFrom] = useState('08:00');
  const [alertBetweenTo, setAlertBetweenTo] = useState('18:00');
  const [recipients, setRecipients] = useState('');
  const [recipientsError, setRecipientsError] = useState(null);
  const [enabled, setEnabled] = useState(true);
  const [saveSuccessMessage, setSaveSuccessMessage] = useState(null);
  const [saveErrors, setSaveErrors] = useState(null);
  const [time, setTime] = useState({start: null, end: null});
  const [alert, setAlert] = useState({});
  const [alertLoading, setAlertLoading] = useState(false);
  const [alertError, setAlertError] = useState(null);
  const [deleteAlertError, setDeleteAlertError] = useState(null);
  const [saveDisabled, setSaveDisabled] = useState(false);
  const [deleteDisabled, setDeleteDisabled] = useState(false);
  const [filters, setFilters] = useState();
  const [availableFilters, setAvailableFilters] = useState();
  const [filtersLoading, setFiltersLoading] = useState();
  const [filtersLoadingError, setFiltersLoadingError] = useState();
  const [showInfo, setShowInfo] = useState(null);

  const prevTime = usePrevious(time);
  const prevForm = usePrevious(form);
  const prevFilters = usePrevious(filters);
  const prevAlertOption = usePrevious(alertOption);

  const displayFilters = alertOption?.type !== 'event_count';

  const fetchFilters = useCallback(async ({form, time}) => {
    try {
      setFiltersLoading(true);
      setFiltersLoadingError(false);
      const { data: { attributes } } = await api.get(`/data/sessions/attributes`, {
        params: {
          formUuid: form.uuid,
          time: {
            start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
            end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          },
        },
      });
      setAvailableFilters(formatFiltersForSelectDropDown(attributes));
    } catch (e) {
      setFiltersLoadingError('Error when fetching attributes');
    } finally {
      setFiltersLoading(false);
    }
  },[]);

  const handleFiltersChange = useCallback((selectedFilters) => {
    setFilters(selectedFilters || []);
  }, []);

  useEffect(() => {
    // Time set or changed
    if (form?.uuid && time?.start && time?.end &&
      (!prevTime.start || (prevTime?.start && prevTime?.end &&
       (prevTime.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') ||
       prevTime.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]') !== time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'))
      ))) {
      fetchFilters({form, time});
    }
    // Form set
    if (form?.uuid && time?.start && time?.end && !prevForm?.uuid) {
      fetchFilters({form, time});
    }
  }, [form, time, fetchFilters, prevTime, prevForm]);

  // Fetch requested form for add alert
  useEffect(() => {
    const loadFormWithOrg = async (formUuid) => {
      try {
        setFormLoading(true);
        setFormError(null);

        const { data: { form } } = await api.get(`/forms/${formUuid}`);
        setForm(form);
        if (form.organisation) setCurrentOrg(form.organisation);
        setFormLoading(false);
      } catch (e) {
        setFormLoading(false);
        setFormError((e.response && (e.response.status === 404)) ? 'Form not found' :
          (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
      }
    };

    if (configType === 'add') {
      const { form: requestedForm = {} } = qs.parse(location.search, { ignoreQueryPrefix: true });
      loadFormWithOrg(requestedForm.uuid);
    }
    if (configType === 'edit' && alert && alert.form && alert.form.uuid) loadFormWithOrg(alert.form.uuid);
  }, [location.search, configType, alert, setCurrentOrg]);

  // Fetch requested alert for edit alert
  useEffect(() => {
    const loadAlert = async () => {
      try {
        setAlertLoading(true);
        setAlertError(null);
        const { data: { alert } } = await api.get(`/alerts/${alertUuid}`);
        setAlert(alert);
        setAlertLoading(false);
      } catch (e) {
        setAlertLoading(false);
        setAlertError((e.response && (e.response.status === 404)) ? 'Alert not found' :
          (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
      }
    };

    if (configType === 'edit' && alertUuid) {
      loadAlert();
    }
  }, [configType, alertUuid]);

  useEffect(() => {
    if (configType === 'add') setAlertOption(alertOptions[0]);
  }, [configType]);

  // Alert received so populate settings
  useEffect(() => {
    if (Object.keys(alert).length) {
      setAlertOption(alertOptions.concat(legacyAlertOptions).find((e) => {
        if (alert.event) return e.value === alert.event;
        if (alert.sessionOutcome) return e.value === alert.sessionOutcome;
        if (alert.metric) return e.value === alert.metric;
        return false;
      }));
      setComparator(alertComparators.find((c) => c.value === alert.comparator));
      setThreshold(alert.threshold);
      if (alert.activeFrom && alert.activeTo) {
        setAlertFrequency('alert-between');
        setAlertBetweenFrom(alert.activeFrom);
        setAlertBetweenTo(alert.activeTo);
      }
      setEnabled(alert.enabled);
      setRecipients(alert.recipients.map((r) => r.email).join(','));
      if (alert?.alertFilters?.length) setFilters(alert.alertFilters.map(({key, value}) => ({key, value: `${key}:${value}`, label: value})));
    }
  }, [alert]);

  useEffect(() => {
    mixpanel.identify(currentUser.email);
  }, [mixpanel, currentUser.email]);

  useEffect(() => {
    if (form && configType) {
      mixpanel.track('Page View', { page: `Alerts - ${configType}`, ...orgDetailsForMixpanel(form?.organisation)});
    }
  }, [mixpanel, form, configType]);

  const handleDateTimeRangeChange = (start, end) => {
    const [utcStart, utcEnd] = [start, end].map((moment) => moment.clone().utc());
    mixpanel.track('Selected Time', {
      page: `Alerts - ${configType}`,
      start: utcStart.toISOString(),
      end: utcEnd.toISOString(),
      daysDiff: utcEnd.diff(utcStart, 'days'),
      daysFromNow: moment.utc().diff(utcStart, 'days'),
      ...orgDetailsForMixpanel(form?.organisation),
    });
    setTime({start, end});
  };

  useEffect(() => {
    if (timeZone) setTime({
      start: moment.tz(timeZone).subtract(30, 'days').startOf('day'),
      end: moment.tz(timeZone).subtract(1, 'days').endOf('day'),
    });
  }, [timeZone]);

  const loadChartData = useCallback(async () => {
    const progressID = setInterval(() => setChartProgress((prevProgress) => prevProgress + 30), 50);
    try {
      setChartLoading(true);
      setChartData(null);
      setChartError(null);
      let resp, data;
      switch (alertOption?.type) {
      case alertTypes.eventCount:
        resp = await api.get('/events', {
          params: {
            eventTypes: [alertOption?.value],
            form: {uuid: form.uuid},
            timePeriod: {
              start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
              end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
            },
            granularity: 'hour',
            timeZone,
          },
        });
        data = resp.data;
        break;
      case alertTypes.sessionMetric:
        resp = await api.get('/data/sessions/stats', {
          params: {
            metric: alertOption?.value,
            formUuid: form.uuid,
            timePeriod: {
              start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
              end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')
            },
            dimension: 'time',
            granularity: 'hour',
            timeZone,
            timeBucketFormat: 'epoch_millis',
            filters: filters?.reduce((acc, {key, label: value}) => {
              if (!acc.hasOwnProperty(key)) acc[key] = [];
              acc[key].push(value);
              return acc;
            }, {}),
          },
        });
        data = resp.data;
        break;
      case alertTypes.sessionOutcome:
        resp = await api.get('/visualisations/vsca-chart', {
          params: {
            form: {uuid: form.uuid},
            timePeriod: {start: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'), end: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')},
            timeZone: form.organisation?.timeZone,
            granularity: 'hour',
            filters: filters?.reduce((acc, {key, label: value}) => {
              if (!acc.hasOwnProperty(key)) acc[key] = [];
              acc[key].push(value);
              return acc;
            }, {}),
          },
        });
        data = resp.data.chart;
        const filterDatasetOn = alertOption?.value === 'completed' ? 'Completions' : 'Abandons';
        data.datasets = data.datasets.filter(d => d.label === filterDatasetOn);
        if (data.datasets.length) {
          data.datasets[0].metric = 'session_outcome';
          const values = data.datasets[0].data;
          data.datasets[0].thresholds = {
            min: Math.min(...values),
            max: Math.max(...values),
            mean: values.reduce((acc, v) => acc + v, 0) / values.length,
          };
        }
        break;
      default:
        break;
      }

      setChartProgress(100);
      const isData = (data && data.labels.length > 0);
      if (!isData) {
        setChartError('No data to display');
      } else {
        data.datasets = data.datasets.map((d) => {
          return {
            ...d,
            label: d.label || alertOptions.find((opt) => d.metric === opt.value)?.label,
            backgroundColor: d.label === 'Completions' || d.metric?.match(/completion/) ? '#0267BF' : '#F0764A',
            borderColor:  d.label === 'Completions' || d.metric?.match(/completion/) ? '#0267BF' : '#F0764A',
            fill: false,
            borderWidth: 1,
            pointRadius: 1,
          };
        });
        setChartData(data);
      }
      setChartLoading(false);
      setChartProgress(0);
      clearInterval(progressID);
    } catch (e) {
      setChartProgress(100);
      setChartError((e.response && (e.response.status === 404)) ? 'Form not found' :
        (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
      setChartLoading(false);
      setChartProgress(0);
      clearInterval(progressID);
    }
  }, [alertOption, form, time, timeZone, filters]);

  // Query changed so load chart
  useEffect(() => {
    if (form?.uuid && time?.start && time?.end && // Required
      (!prevForm?.uuid || (
        (JSON.stringify(prevFilters) !== JSON.stringify(filters)) ||
        (!prevTime?.start ||
          (prevTime &&
            (JSON.stringify(prevTime.start) !== JSON.stringify(time.start) ||
            (JSON.stringify(prevTime.end) !== JSON.stringify(time.end)))))
      ))) {
      loadChartData();
    }
  }, [loadChartData, form, time, timeZone, prevFilters, filters, prevTime, prevForm]);

  // Metric type changed so load chart
  useEffect(() => {
    if (
      form?.uuid && time?.start && time?.end && // Required
      prevAlertOption &&
      alertOption?.value !== prevAlertOption?.value) {
      loadChartData();
    }
  }, [loadChartData, alertOption, prevAlertOption, form?.uuid, time?.start, time?.end ]);

  useEffect(() => {
    if (configType === 'add' && threshold === null && chartData?.datasets?.[0]?.thresholds) {
      setThreshold(chartData.datasets[0].thresholds.min || 1);
    }
  }, [configType, threshold, chartData]);

  const saveAddAlert = async () => {
    try {
      setSaveErrors(null);
      await api.post('/alerts', {
        alert: {
          ...alertOption?.type === alertTypes.eventCount && {event: alertOption?.value},
          ...alertOption?.type === alertTypes.sessionMetric && {metric: alertOption?.value},
          ...alertOption?.type === alertTypes.sessionOutcome && {sessionOutcome: alertOption?.value},
          comparator: comparator.value,
          threshold,
          ...alertFrequency === 'alert-between' && {
            activeFrom: alertBetweenFrom, activeTo: alertBetweenTo
          },
          enabled,
          form: {uuid: form.uuid},
          recipients: recipients.split(',').map((r) => r.trim()),
          filters: filters?.reduce((acc, {key, label: value}) => {
            if (!acc.hasOwnProperty(key)) acc[key] = [];
            acc[key].push(value);
            return acc;
          }, {}),
        }
      });
      setSaveSuccessMessage('Alert saved.');
      mixpanel.track('Saved Alert', { page: `Alerts - ${configType}`, ...orgDetailsForMixpanel(form?.organisation) });
    } catch (e) {
      if ((e.response && (e.response.status === 422) && e.response.data && e.response.data.errors)) {
        setSaveErrors(e.response.data.errors.map(({message}) => message));
      } else {
        setSaveErrors(['Something went wrong saving the alert.']);
      }
    }
  };

  const saveEditAlert = async () => {
    try {
      setSaveErrors(null);
      await api.put(`/alerts/${alert.uuid}`, {
        alert: {
          ...alertOption?.type === alertTypes.eventCount && {event: alertOption?.value},
          ...alertOption?.type === alertTypes.sessionMetric && {metric: alertOption?.value},
          ...alertOption?.type === alertTypes.sessionOutcome && {sessionOutcome: alertOption?.value},
          comparator: comparator.value,
          threshold,
          ...alertFrequency === 'alert-between' && {
            activeFrom: alertBetweenFrom, activeTo: alertBetweenTo
          },
          ...alertFrequency === 'alert-anytime' && {
            activeFrom: null, activeTo: null
          },
          enabled,
          form: {uuid: form.uuid},
          recipients: recipients.split(',').map((r) => r.trim()),
          filters: filters?.reduce((acc, {key, label: value}) => {
            if (!acc.hasOwnProperty(key)) acc[key] = [];
            acc[key].push(value);
            return acc;
          }, {}),
        }
      });
      setSaveSuccessMessage('Alert saved.');
      mixpanel.track('Saved Alert', { page: `Alerts - ${configType}`, ...orgDetailsForMixpanel(form?.organisation) });
    } catch (e) {
      if ((e.response && (e.response.status === 422) && e.response.data && e.response.data.errors)) {
        setSaveErrors(e.response.data.errors.map(({message}) => message));
      } else {
        setSaveErrors(['Something went wrong saving the alert.']);
      }
    }
  };

  const handleSave = async (e) => {
    if (saveDisabled) return;
    setSaveDisabled(true);
    e.preventDefault();

    let alertValid = true;

    // Check threshold and recipients are valid
    if (!threshold || parseInt(threshold) < 1) {
      alertValid = false;
      setThresholdError('Threshold value must be at least 1');
    }

    if (!recipients) {
      alertValid = false;
      setRecipientsError('Please enter at least one recipient');
    } else if (recipients.split(',').length > 25) {
      alertValid = false;
      setRecipientsError('Enter a maximum of 25 valid email addresses');
    } else if (addrs.parseAddressList(recipients) === null) {
      alertValid = false;
      setRecipientsError('Valid email addresses must be separated by a comma');
    }

    try {
      if (alertValid && configType === 'add') await saveAddAlert();
      if (alertValid && configType === 'edit' && alert.uuid) await saveEditAlert();
    } finally {
      setSaveDisabled(false);
    }
  };

  const handleDelete = async () => {
    if (deleteDisabled) return;
    setDeleteDisabled(true);
    try {
      await api.delete(`/alerts/${alert.uuid}`);
      mixpanel.track('Deleted Alert', { page: `Alerts - ${configType}`, ...orgDetailsForMixpanel(form?.organisation) });
      history.push(`/organisations/${form.organisation.uuid}/alerts`);
    } catch (e) {
      setDeleteAlertError('Something went wrong. Alert could not be deleted.');
      setDeleteDisabled(false);
    }
  };

  // Once saved, show the success message briefly, then return to main Alerts page
  useEffect(() => {
    if (saveSuccessMessage && form.organisation && form.organisation.uuid) {
      setTimeout(() => {
        setSaveSuccessMessage(null);
        history.push(`/organisations/${form.organisation.uuid}/alerts`);
      }, 1000);
    }

  }, [saveSuccessMessage, form, history]);

  return (
    <Container fluid className="page" id="alerts-page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Alerts</title>
      </Helmet>
      <div className="nav-wrapper">
        <NavBar mixpanel={mixpanel}/>
      </div>
      <div className="main-content">
        <Col className="center-column justify-content-md-center">
          <div className="player-navigate-row browser-only">
            <Link to={`/organisations/${form?.organisation?.uuid}/alerts`} className="d-flex align-items-center"><VscChevronLeft className="mex-1" size="24px" /> Back to Alerts</Link>
          </div>
          <FeedbackRow
            classList={['allow-scroll-under-nav']}
            mixpanel={mixpanel}
            page={`Alerts - ${configType}`}
            org={form?.organisation}
            messageContent={configType === 'add' ? 'adding an Alert' : 'editing an Alert'} />
          <AppAlerts showOrgAlerts={true} />
          <Row className="title-row g-0">
            <Col className={`p-0 original-content ${showInfo ? 'background' : ''}`}>
              <h1 id="alerts-title">
                {form && form.label && `${form.label} | Alert Configuration`}
              </h1>
            </Col>
            <Col className="p-0 text-end title-tooltips align-self-center">
              <FaInfoCircle id="first-info-icon" size="20px" className="info-circle-icon" onClick={() => {setShowInfo(true); mixpanel.track('Clicked Alerts info', { page: `Alerts - ${configType}`, ...orgDetailsForMixpanel(form?.organisation) });}} title="How to use"/>
            </Col>
            <AlertsInfoOverlay
              showInfo={showInfo}
              setShowInfo={setShowInfo}
              mixpanel={mixpanel}
            />
          </Row>
          <Col className="p-0">
            <Card>
              <Card.Body>
                <Row className="g-0 card-title-row">
                  <Col className="p-0">
                    <Card.Title as="h3">{configType === 'add' ? 'Add Alert' : configType === 'edit' && 'Edit Alert'}</Card.Title>
                  </Col>
                </Row>
                <Row  className="g-0">
                  <p className="page-tagline mt-1 mb-0">Choose a metric type and review the historic hourly data over the selected time period.{displayFilters ? ' You can apply filters to this alert, to only be alerted to changes in a specific segment.' : ''}</p>
                </Row>
                <Row className="g-0 justify-content-between">
                  <Col className="ps-0 col-auto" id="datepicker">
                    {time?.start && time?.end &&
                        <DatePicker
                          startTime={time?.start}
                          endTime={time?.end}
                          onApply={handleDateTimeRangeChange}
                          minDate={moment.tz(timeZone).subtract(184, 'days').startOf('day')} // 6 months
                        />}
                  </Col>
                  <Col className="pe-0 d-flex align-items-center flex-row justify-content-end" id="event-type-select">
                    <Form.Label className="me-2 mb-0 text-nowrap">Metric type:</Form.Label>
                    <Select
                      styles={{
                        control: (styles, state) => ({...styles,
                          border: '1px solid #EBEDF2',
                          minWidth: '215px',
                        }),
                        option: (styles, state) => ({...styles,
                          color: '#3f4047',
                          backgroundColor: state.selectProps.value && (state.selectProps.value.label === state.label) ? "#E2E5EC" : null,
                          '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
                        }),
                        menu: (styles, state) => ({...styles,
                          marginTop: '1px',
                          borderRadius: '4px',
                          border: '1px solid #EBEDF2',
                          boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                        }),
                        dropdownIndicator: (styles, state) => ({...styles,
                          cursor: 'pointer',
                          transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                          transition: 'transform .5s ease',
                        }),
                      }}
                      options={alertOptions}
                      onChange={(e) => {
                        setAlertOption(e);
                        setSaveErrors(null);
                        if (e.type === 'event_count') setFilters(null);
                      }}
                      value={alertOption}
                      isSearchable={false}
                    />
                  </Col>
                </Row>
                {displayFilters &&
                  <Row className="g-0">
                    <Col></Col>
                    <Col lg={6} sm={12} className="pe-0 d-flex justify-content-end">
                      <FiltersSelect
                        form={form}
                        filters={filters}
                        filtersLoading={filtersLoading}
                        availableFilters={availableFilters}
                        filtersLoadingError={filtersLoadingError}
                        handleFiltersChange={handleFiltersChange} />
                    </Col>
                  </Row>}
                {(formLoading || alertLoading) && <p>Loading...</p>}
                {formError && <p className="text-center">{formError}</p>}
                {alertError && <p className="text-center">{alertError}</p>}
                {chartData ? <>
                  <div data-testid="alerts-chart-wrapper">
                    <ReactChart
                      type="line"
                      data={chartData}
                      width={1216}
                      height={300}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        scales: {
                          y: {
                            title: {
                              text: alertOption?.value.includes('rate') ? 'Rate' : 'Count',
                              display: true,
                            },
                            type: 'linear',
                            ticks: {
                              callback: (value) => formatCellToStr({value}) + (alertOption?.value.includes('rate') ? '%' : ''),
                            },
                          },
                          x: {
                            title: {
                              text: 'Hour',
                              display: true,
                            },
                            type: 'timeseries',
                            time: {
                              unit: 'hour',
                              displayFormats: {
                                hour: chartData.labels.length > 48 ? 'MMM DD' : 'HH[h]',
                              },
                              isoWeekday: true,
                              tooltipFormat: 'MMM D, HH:mm',
                            },
                          },
                        },
                        elements: {
                          line: {
                            tension: 0.05
                          }
                        },
                        interaction: {
                          intersect: false,
                          mode: 'nearest',
                        },
                        plugins: {
                          legend: {
                            labels: {
                              boxWidth: 20
                            }
                          },
                          tooltip: {
                            position: 'nearest',
                            callbacks: {
                              label: (context) => {
                                return `${context.dataset.label}: ${context.parsed.y.toLocaleString(undefined, {maximumFractionDigits: 2})}`;
                              },
                            },
                          },
                          annotation: {
                            annotations: [
                              {
                                type: 'line',
                                yMin: threshold,
                                yMax: threshold,
                                borderColor: '#e01f46',
                                borderWidth: 1,
                              },
                            ],
                          },
                        }
                      }}
                    />
                  </div>
                  {chartData.datasets && chartData.datasets.map((d, i) => {
                    const {min, max, mean} = d.thresholds;
                    return (max !== null || min !== null || mean !== null) &&
                      <div className="chartThresholds text-center justify-content-around" key={`threshold-${i}`}>
                        <div className="col-auto">
                          <span className="thresholdMetric">High: </span>
                          <span className="thresholdFigure">{formatNumToStringWithDecimals(max, 0)}{alertOption?.value.includes('rate') && '%'}</span>
                        </div>
                        <div className="col-auto">
                          <span className="thresholdMetric">Mean: </span>
                          <span className="thresholdFigure">{formatNumToStringWithDecimals(mean, 0)}{alertOption?.value.includes('rate') && '%'}</span>
                        </div>
                        <div className="col-auto">
                          <span className="thresholdMetric">Low: </span>
                          <span className="thresholdFigure">{formatNumToStringWithDecimals(min, 0)}{alertOption?.value.includes('rate') && '%'}</span>
                        </div>
                      </div>;
                  })} </>
                  : chartError ?
                    <p className="text-center" data-testid="alerts-chart-error">{chartError}</p>
                    : chartLoading &&
                <div className="progress-area">
                  <ProgressBar animated now={chartProgress}/>
                </div>
                }
                {!formError && form.organisation && form.organisation.uuid && <>
                  <div className="alert-settings">
                    <Form>
                      <Row className="g-0 justify-content-start align-items-center">
                        <Col className="p-0 d-flex align-items-center">
                          <Form.Label className="me-2 mb-0" htmlFor="thresholdInput">
                            Alert me when {alertOption?.label.toLowerCase()} is
                          </Form.Label>
                          <div id="comparator-select">
                            <Select
                              styles={{
                                control: (styles, state) => ({...styles,
                                  border: '1px solid #ced4da',
                                  width: '140px',
                                }),
                                option: (styles, state) => ({...styles,
                                  color: '#3f4047',
                                  backgroundColor: state.selectProps.value && (state.selectProps.value.label === state.label) ? "#E2E5EC" : null,
                                  '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
                                }),
                                singleValue: (styles, state) => ({...styles,
                                  fontSize: '16px',
                                  fontWeight: '400',
                                }),
                                menu: (styles, state) => ({...styles,
                                  marginTop: '1px',
                                  borderRadius: '4px',
                                  border: '1px solid #EBEDF2',
                                  boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                                  fontSize: '16px',
                                  fontWeight: '400',
                                }),
                                dropdownIndicator: (styles, state) => ({...styles,
                                  cursor: 'pointer',
                                  transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                                  transition: 'transform .5s ease',
                                }),
                              }}
                              options={alertComparators}
                              onChange={(e) => setComparator(e)}
                              value={comparator}
                              isSearchable={false}
                            />
                          </div>
                          <Form.Control className={`form-control align-middle ${thresholdError && 'invalid-input'}`} id="thresholdInput" type="number" required value={threshold || ''} min={1} onChange={({target: {value}}) => {setThreshold(value); setThresholdError(null);}}/>
                          <span className="d-inline-block">per hour.</span>
                          {thresholdError && (<p className="error m-0">{thresholdError}</p>)}
                        </Col>
                      </Row>
                      <Row className="g-0 justify-content-start align-items-center pt-2">
                        <Col className="p-0 d-flex align-items-center">
                          <FormGroup className="form-group" controlId="emailInput">
                            <Form.Label>Send alert to:</Form.Label>
                            <div>
                              <Form.Control className={`form-control align-middle ms-0 ${recipientsError && 'invalid-input'}`} type="email" multiple required value={recipients} onChange={({target: {value}}) => {setRecipients(value); setRecipientsError(null);}} />
                              <div className="input-feedback">Enter a comma separated list of email addresses, e.g. me@example.com,alert@example.com</div>
                              {recipientsError && (<p className="error m-0 ps-0">{recipientsError}</p>)}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row className="g-0 justify-content-start pt-2 mb-2">
                        <Col className="p-0 d-flex align-items-center">
                          <fieldset>
                            <FormGroup className="form-group mb-0" controlId="alert-frequency">
                              {[{label: 'Alert all hours', value: 'alert-anytime'}, {label:'Alert between', value: 'alert-between'}].map(({label, value}) => (
                                <Form.Check className={value !== alertFrequency && 'disabled-alert-frequency'} inline type="radio" label={label} id={value} value={value} data-testid={`${value}-radio`}
                                  checked={value === alertFrequency} onChange={(e) => setAlertFrequency(e.target.value)} key={value}/>
                              ))}
                            </FormGroup>
                          </fieldset>
                          <div id="alert-between-time" className="d-flex align-items-stretch">
                            <TimePicker
                              onChange={setAlertBetweenFrom}
                              value={alertBetweenFrom}
                              disableClock={true}
                              disabled={alertFrequency === 'alert-anytime'}
                              clearIcon={null}
                              format={'HH:mm'}
                            />
                            <TimePicker
                              onChange={setAlertBetweenTo}
                              value={alertBetweenTo}
                              disableClock={true}
                              disabled={alertFrequency === 'alert-anytime'}
                              clearIcon={null}
                              format={'HH:mm'}
                            />
                          </div>
                        </Col>
                      </Row>
                      {configType === 'edit' &&
                        <Row className="g-0 justify-content-start align-items-center pt-2">
                          <FormGroup className="form-group pt-1" controlId="weeklyEmail">
                            <div className="d-inline-flex align-items-center">
                              <Form.Check type="checkbox" checked={enabled} className={!enabled && 'disabled-alert-status'}  data-testid="alert-enabled-check"
                                onChange={(e) => setEnabled(e.target.checked)}/>
                              <Form.Label className="mb-0 ms-2">Enabled</Form.Label>
                            </div>
                            <div className="input-feedback">Alerts are sent when enabled is ticked.</div>
                          </FormGroup>
                        </Row>}
                      {saveErrors && saveErrors.map((e) => (<p className="error m-0">{e}</p>))}
                    </Form>
                  </div>
                  <Row className="g-0 justify-content-between">
                    <Col className="px-0 pb-0 pt-2">
                      <Button variant="primary" className="submit mx-0" type="submit" onClick={handleSave} data-testid="save-alert-config" disabled={saveDisabled}>Save</Button>
                      {saveSuccessMessage && <div className="m-0"><p><FaCheck className="success-tick-icon" />{saveSuccessMessage}</p></div>}
                    </Col>
                  </Row>
                  {configType === 'edit' &&
                    <div className="alert-actions">
                      <h4>Actions</h4>
                      <p className="card-tagline">Delete this alert entirely.</p>
                      <Row className="mt-2">
                        <Col className="p-0">
                          <Button variant="outline-danger" className="ms-0 mt-2" onClick={handleDelete} disabled={deleteDisabled} data-testid="delete-alert">Delete</Button>
                          {deleteAlertError && <p className="error-text m-auto">{deleteAlertError}</p>}
                        </Col>
                      </Row>
                    </div>}
                </>}
              </Card.Body>
            </Card>
          </Col>
        </Col>
      </div>
    </Container>
  );
};

export default AlertConfig;
