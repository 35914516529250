import React, { useEffect, useState, useContext, useCallback } from 'react';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';

import NavBar from '../../NavBar';
import AppAlerts from '../../Components/AppAlerts';
import FeedbackRow from '../../Components/FeedbackRow';
import api from '../../api';
import AppContext from '../../AppContext';
import OrgAgencyClientDetailsForm from '../../Components/OrgAgencyClientDetailsForm';
import { VscWarning } from 'react-icons/vsc';

import './OrgCreate.scss';

const OrgAgencyClientCreate = ({mixpanel}) => {
  const history = useHistory();
  const location = useLocation();
  const { uuid: orgAgencyUuid } = useParams();
  const { currentUser } = useContext(AppContext);

  const [mainError, setMainError] = useState(null);
  const [showErrorMessagesForField, setShowErrorMessagesForField] = useState();
  const [errorMessagesForField, setErrorMessagesForField] = useState(); // Object response with error messages per field
  const [errorMessages, setErrorMessages] = useState(); // Array of messages
  const [errorMessage, setErrorMessage] = useState(); // Standard catch all single message
  const [orgAgency, setOrgAgency] = useState(location.state?.agency);

  const handleResetErrorMessages = useCallback(() => {
    if (errorMessage) setErrorMessage(null);
    if (errorMessages) setErrorMessages(null);
    if (errorMessagesForField) setErrorMessagesForField(null);
  },[errorMessage, errorMessages, errorMessagesForField]);

  const handleResetErrorMessagesForField = useCallback((field) => {
    if (errorMessagesForField?.[field]) setErrorMessagesForField(messages => {
      const newMessages = {...messages};
      delete newMessages[field];
      return newMessages;
    });
  },[errorMessagesForField]);

  const handleSubmit = useCallback(async ({
    name, timeZone, notes, sessionLimit,
  }) => {
    try {
      handleResetErrorMessages();
      await api.post(`/organisations`, {
        organisation: {
          name,
          timeZone,
          notes,
          sessionLimit: sessionLimit || 0,
          type: 'AgencyClient',
          contractType: orgAgency.contractType,
          replayEnabled: orgAgency.replayEnabled,
          parent: {
            uuid: orgAgencyUuid,
          }
        },
      });

      mixpanel.track('Created Client', { page: 'Create Client'});

      history.push(`/organisations/${orgAgencyUuid}/edit#clients`, { newAgencyClient: true });
    } catch (e) {
      switch (e?.response?.status) {
      case e?.response?.data?.errors && 400:
      case e?.response?.data?.errors && 422:
        if (Array.isArray(e.response.data.errors)) {
          setErrorMessages(e.response.data.errors);
        } else {
          setErrorMessagesForField(e.response.data.errors);
          setShowErrorMessagesForField(true);
        }
        break;
      case 401:
        setMainError('Not logged in');
        break;
      case 403:
        history.replace('/');
        break;
      default:
        setErrorMessage('Something went wrong. The client has not been created, please try again.');
      }
    }
  }, [history, mixpanel, orgAgency?.contractType, orgAgency?.replayEnabled, orgAgencyUuid, handleResetErrorMessages]);

  useEffect(() => {
    mixpanel.identify(currentUser.email);
    mixpanel.track('Page View', {
      page: 'Create Client',
      agencyName: orgAgency?.name,
      agencyContractType: orgAgency?.contractType,
    });
  }, [mixpanel, currentUser.email, orgAgency]);

  // Fetch the agency if don't already have it
  useEffect(() => {
    if (orgAgencyUuid && !orgAgency) (async () => {
      try {
        const { data: { organisation } } = await api.get(`/organisations/${orgAgencyUuid}`);
        setOrgAgency(organisation);
      } catch (e) {
        switch (e?.response?.status) {
        case 401:
          setMainError('Not logged in');
          break;
        case 403:
          history.replace('/');
          break;
        default:
          setMainError("Something went wrong. The agency session limit details are not available.");
        }
      }
    })();
  },[orgAgencyUuid, history, orgAgency]);

  return (
    <Container fluid className="org-create page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Add Client</title>
      </Helmet>
      <div className="nav-wrapper">
        <NavBar mixpanel={mixpanel}/>
      </div>
      <div className="main-content">
        <Col className="center-column justify-content-md-center">
          <FeedbackRow
            classList={['allow-scroll-under-nav']}
            mixpanel={mixpanel}
            page={'Create Client'}
            org={{ name: orgAgency?.name, uuid: orgAgencyUuid }}
            messageContent={'creating a Client'} />
          <AppAlerts />
          <Row className="title-row g-0">
            <Col className="p-0">
              <h1 id="form-title">
                {orgAgency?.name}
              </h1>
            </Col>
          </Row>
          <Card id="org-create-card">
            <Card.Body>
              <Row className="g-0 card-title-row  justify-content-between">
                <Col className="p-0">
                  <Card.Title as="h3">Add Client</Card.Title>
                </Col>
                <Col className="p-0">
                </Col>
              </Row>
              <Row className="g-0 card-content flex-column">
                {mainError && <div id="load-error-message"><h3>{mainError}</h3></div>}
                {showErrorMessagesForField && (errorMessagesForField && Object.keys(errorMessagesForField).length > 0) &&
                  <Row className="alert-row g-0">
                    <Alert dismissible variant="danger" closeVariant="white"
                      onClose={() => setShowErrorMessagesForField(false)}>
                      <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                      <div>
                        {Object.keys(errorMessagesForField)
                          .map((field) => (
                            <p className="alert-text m-0 py-1">{field} {errorMessagesForField[field]?.join(', ')}.</p>))}
                      </div>
                    </Alert>
                  </Row>
                }
                {errorMessages?.length > 0 &&
                  <Row className="alert-row g-0">
                    <Alert dismissible variant="danger" closeVariant="white"
                      onClose={() => setErrorMessages(null)}>
                      <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                      <p className="alert-text m-0">{errorMessages.map((e) => e.message)}</p>
                    </Alert>
                  </Row>
                }
                {errorMessage &&
                  <Row className="alert-row g-0">
                    <Alert dismissible variant="danger" closeVariant="white"
                      onClose={() => setErrorMessage(null)}>
                      <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                      <p className="alert-text m-0">{errorMessage}</p>
                    </Alert>
                  </Row>
                }
                {!mainError && <>
                  <OrgAgencyClientDetailsForm
                    handleSubmit={handleSubmit}
                    type={'create'}
                    handleResetErrorMessagesForField={handleResetErrorMessagesForField}
                    errorMessagesForField={errorMessagesForField}
                    orgAgency={orgAgency}
                  />
                </>}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </div>
    </Container>
  );
};

export default OrgAgencyClientCreate;
