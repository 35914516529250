import React, { useContext, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ExplorerProvider from './explorerContext';
import Container from 'react-bootstrap/Container';

import NavBar from '../../NavBar';
import ScrollToTop from '../../Components/ScrollToTop';
import AppContext from '../../AppContext';
import SessionsSearch from './SessionsSearch';
import { useAppForms } from '../../hooks';
import { orgDetailsForMixpanel } from '../../utils';

import './SessionExplorer.scss';

const SessionExplorer = ({mixpanel}) => {
  const { currentUser,
    query,
  } = useContext(AppContext);

  const {
    form,
  } = query || {};

  useAppForms();

  useEffect(() => {
    mixpanel.identify(currentUser.email);
  }, [mixpanel, currentUser.email]);

  useEffect(() => {
    mixpanel.track('Page View', Object.assign({ page: 'SessionExplorer' }, orgDetailsForMixpanel(form?.organisation)));
  }, [mixpanel, form]);

  return (
    <Container fluid className="session-explorer page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Session Explorer</title>
      </Helmet>
      <div className="nav-wrapper browser-only">
        <NavBar mixpanel={mixpanel}/>
      </div>
      <main className="d-flex flex-column flex-grow-1">
        <ScrollToTop />
        <ExplorerProvider>
          <SessionsSearch mixpanel={mixpanel}/>
        </ExplorerProvider>
      </main>
    </Container>
  );
};

export default SessionExplorer;
