import { useContext, useState, useEffect, useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet';
import BuilderContext from '../Context';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import Alert from 'react-bootstrap/Alert';
import { InAppForm } from '../form-components';
import {
  useHistory,
  useLocation,
} from "react-router-dom";
import api from '../../api';
import Configure from './Configure';
import EditTypeWrapper from './EditTypeWrapper';
import { VscCheck, VscWarning, VscSettingsGear, VscArrowUp } from 'react-icons/vsc';
import { FaSpinner } from 'react-icons/fa';
import arrayMove from 'array-move';
import { usePrevious } from '../../hooks';
import FeedbackRow from '../../Components/FeedbackRow';
import StepActionsRow from './StepActionsRow';
import { hexToCssHsl } from '../utils';

import './index.scss';

export const componentTypeTitle = ({componentType}) => {
  switch(componentType) {
  case 'email':
    return "Email";
  case 'text':
    return 'Short Text';
  case 'telephoneNumber':
    return 'Telephone Number';
  case 'number':
    return 'Number';
  case 'textarea':
    return 'Long Text';
  case 'date':
    return 'Date';
  case 'address':
    return 'Address';
  case 'countrySelector':
    return 'Country';
  case 'radioButtonGroup':
    return 'Radio Buttons';
  case 'select':
    return 'Dropdown';
  case 'checkbox':
    return 'Single Checkbox';
  case 'checkboxGroup':
    return 'Multiple Checkboxes';
  default:
      // Do nothing
  }
};

const Build = ({mixpanel, showCheckoutSuccessMsg, setShowCheckoutSuccessMsg, showPreview, setCanPublish}) => {
  const history = useHistory();
  const location = useLocation();
  const {
    form, setForm, formsError,
  } = useContext(BuilderContext);
  const [queue, setQueue] = useState([]);
  const [queueItemBeingSaved, setQueueItemBeingSaved] = useState();
  const [identifyItem, setIdentifyItem] = useState();
  const [formIsSaving, setFormIsSaving] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [successMessageTimeout, setSuccessMessageTimeout] = useState();
  const [editError, setEditError] = useState();
  const [configureError, setConfigureError] = useState();
  const [configureErrorMessages, setConfigureErrorMessages] = useState();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [showSubmitted, setShowSubmitted] = useState();
  const [showConfigure, setShowConfigure] = useState();
  const [firstQuestion, setFirstQuestion] = useState();
  const [deviceType, setDeviceType] = useState();
  const showGettingStarted = (form?.uuid === 'new' && !form?.builderSteps);

  const prevIdentifyItem = usePrevious(identifyItem);

  const questionsOnly = useMemo(() => form?.builderSteps?.map(step => step.builderQuestions?.filter(q => q.componentType !== 'paragraphText')).flat(), [form]);
  const currentKeys = useMemo(() => questionsOnly?.map(question => question.key).filter(Boolean), [questionsOnly]);
  const questionKeysToUse = useMemo(() => questionsOnly?.map(question => question.key || question.title), [questionsOnly]);

  const handleRemoveMessages = useCallback(() => {
    setConfigureError(null);
    setConfigureErrorMessages(null);
    setSuccessMessage(null);
    if (setShowCheckoutSuccessMsg) setShowCheckoutSuccessMsg(null);
    setEditError(null);
  }, [setShowCheckoutSuccessMsg]);

  // Save a new step
  const handleCreateNewStep = useCallback(() => {
    const updatedForm = JSON.parse(JSON.stringify(form));

    updatedForm.builderSteps = updatedForm.builderSteps
      .concat({builderQuestions: []})
      .map((s, i) => ({...s, ...!s.title && {title: `Step ${i + 1}`}})); // Add step titles by default TBC if we are happy about this?

    setIdentifyItem(null);

    setForm(updatedForm);
    setCurrentStepIndex(updatedForm.builderSteps.length - 1);
    setCanPublish(false);
    setQueue(prev => (prev || []).concat({
      updatedForm,
      successMessage: 'Step created.',
    }));
  }, [form, setForm, setCanPublish]);

  // Immediately add and save a question
  const handleCreateQuestion = useCallback(({componentType}) => {
    const updatedForm = JSON.parse(JSON.stringify(form));
    if (!updatedForm.builderSteps) {
      setFirstQuestion(true);
      updatedForm.builderSteps = [{builderQuestions: []}];
    } else {
      setFirstQuestion(false);
    }
    const questionIndex = updatedForm.builderSteps[currentStepIndex].builderQuestions.length;

    const newQuestion = {
      componentType,
      title: componentTypeTitle({componentType}),
      required: true,
      type: 'question',
      ...(componentType === 'select' || componentType === 'checkboxGroup') && {
        options: ['Option 1', 'Option 2', 'Option 3'],
      },
      ...(componentType === 'radioButtonGroup') && {
        options: ['Option A', 'Option B'],
      },
      ...(componentType === 'address') && {
        subLabels: {
          addressLine1: 'Address 1',
          addressLine2: 'Address 2',
          addressLevel1: 'Town or city',
          postalCode: 'Postcode/ZIP code',
        },
      },
      ...(componentType === 'checkbox') && {
        key: 'single-checkbox', // Hard-code the key for this component to avoid a linked-to URL being part of the field id
      },
    };
    updatedForm.builderSteps[currentStepIndex].builderQuestions = updatedForm.builderSteps[currentStepIndex].builderQuestions.concat(newQuestion);
    setForm(updatedForm);
    setIdentifyItem({type: 'question', stepIndex: currentStepIndex, questionIndex});

    setCanPublish(false);
    setQueue(prev => (prev || []).concat({
      updatedForm,
      successMessage: 'Question created.'}));
  }, [form, currentStepIndex, setForm, setCanPublish]);

  // Immediately add and save paragraph text
  const handleCreateParagraphText = useCallback(() => {
    const updatedForm = JSON.parse(JSON.stringify(form));
    if (!updatedForm.builderSteps) updatedForm.builderSteps = [{builderQuestions: []}];
    const questionIndex = updatedForm.builderSteps[currentStepIndex].builderQuestions.length;
    const newItem = {
      componentType: 'paragraphText',
      title: 'Paragraph',
      type: 'paragraph-text',
    };
    updatedForm.builderSteps[currentStepIndex].builderQuestions = updatedForm.builderSteps[currentStepIndex].builderQuestions.concat(newItem);
    setForm(updatedForm);
    setIdentifyItem({type: 'paragraph-text', stepIndex: currentStepIndex, questionIndex});

    setCanPublish(false);
    setQueue(prev => (prev || []).concat({
      updatedForm,
      successMessage: 'Paragraph created.'}));
  }, [form, currentStepIndex, setForm, setCanPublish]);

  // Updating an item
  const handleSaveConfigureItem = useCallback(({item}) => {
    const { type, stepIndex, questionIndex } = identifyItem;

    const updatedForm = JSON.parse(JSON.stringify(form));
    switch (type) {
    case 'question':
    case 'paragraph-text':
      updatedForm.builderSteps[stepIndex].builderQuestions[questionIndex] = item;
      break;
    case 'step-title':
      updatedForm.builderSteps[stepIndex].title = item.title;
      break;
    case 'form-title':
      updatedForm.title = item.title;
      break;
    case 'form-description':
      updatedForm.description = item.description;
      break;
    case 'step-description':
      updatedForm.builderSteps[stepIndex].description = item.description;
      break;
    case 'completion-title':
      updatedForm.completion.title = item.title;
      break;
    case 'completion-message':
      updatedForm.completion.message = item.message;
      break;
    default:
      setConfigureError(`Unknown item type: ${type}`);
      return;
    }

    setForm(updatedForm);
    setCanPublish(false);
    setQueue(prev => (prev || []).concat({
      updatedForm,
      successMessage: 'Changes saved.'}));
  }, [form, identifyItem, setForm, setCanPublish]);

  // Updating an item directly in the form
  const handleSaveInlineEditItem = useCallback(({item, type, stepIndex, questionIndex, itemIndex}) => {
    const updatedForm = JSON.parse(JSON.stringify(form));

    // Prepare the item for update
    switch (type) {
    case'question-sub-label-address-line-1':
    case 'question-sub-label-address-line-2':
    case 'question-sub-label-address-level-1':
    case 'question-sub-label-postal-code':
      if (!updatedForm.builderSteps[stepIndex].builderQuestions[questionIndex].subLabels) updatedForm.builderSteps[stepIndex].builderQuestions[questionIndex].subLabels = {};
      break;
    default:
      // Not formatting to be done for any other types
    }

    // Update the item
    switch (type) {
    case 'step-title':
      updatedForm.builderSteps[stepIndex].title = item;
      break;
    case 'form-title':
      updatedForm.title = item;
      break;
    case 'form-description':
      updatedForm.description = item;
      break;
    case 'step-description':
      updatedForm.builderSteps[stepIndex].description = item;
      break;
    case 'paragraph-text':
      updatedForm.builderSteps[stepIndex].builderQuestions[questionIndex].title = item;
      break;
    case 'question-title':
      updatedForm.builderSteps[stepIndex].builderQuestions[questionIndex].title = item;
      break;
    case 'question-hint':
      updatedForm.builderSteps[stepIndex].builderQuestions[questionIndex].hint = item;
      break;
    case 'question-key':
      updatedForm.builderSteps[stepIndex].builderQuestions[questionIndex].key = item;
      break;
    case 'question-required':
      updatedForm.builderSteps[stepIndex].builderQuestions[questionIndex].required = item;
      break;
    case 'question-option':
      updatedForm.builderSteps[stepIndex].builderQuestions[questionIndex].options[itemIndex] = item;
      break;
    case 'question-options':
      updatedForm.builderSteps[stepIndex].builderQuestions[questionIndex].options = item;
      break;
    case 'question-other-option':
      updatedForm.builderSteps[stepIndex].builderQuestions[questionIndex].otherOption = item;
      break;
    case 'question-top-options':
      updatedForm.builderSteps[stepIndex].builderQuestions[questionIndex].topOptions = item;
      break;
    case 'question-sub-labels':
      updatedForm.builderSteps[stepIndex].builderQuestions[questionIndex].subLabels = item;
      break;
    case 'question-sub-label-address-line-1':
      updatedForm.builderSteps[stepIndex].builderQuestions[questionIndex].subLabels.addressLine1 = item;
      break;
    case 'question-sub-label-address-line-2':
      updatedForm.builderSteps[stepIndex].builderQuestions[questionIndex].subLabels.addressLine2 = item;
      break;
    case 'question-sub-label-address-level-1':
      updatedForm.builderSteps[stepIndex].builderQuestions[questionIndex].subLabels.addressLevel1 = item;
      break;
    case 'question-sub-label-postal-code':
      updatedForm.builderSteps[stepIndex].builderQuestions[questionIndex].subLabels.postalCode = item;
      break;
    case 'question-size':
      updatedForm.builderSteps[stepIndex].builderQuestions[questionIndex].size = item;
      break;
    case 'question-format':
      updatedForm.builderSteps[stepIndex].builderQuestions[questionIndex].format = item;
      break;
    case 'completion-message':
      updatedForm.completion.message = item;
      break;
    case 'completion-title':
      updatedForm.completion.title = item;
      break;
    default:
      setConfigureError(`Unknown item type: ${type}`);
      return;
    }

    setForm(updatedForm);
    setCanPublish(false);
    setQueue(prev => (prev || []).concat({
      updatedForm,
      successMessage: 'Changes saved.'}));
  }, [form, setForm, setCanPublish]);


  // Save a new step attribute
  const handleAddStepAttr = useCallback(({attr}) => {
    const updatedForm = JSON.parse(JSON.stringify(form));

    switch (attr) {
    case 'title':
      if (!form.builderSteps[currentStepIndex].title) {
        updatedForm.builderSteps[currentStepIndex].title = `Step ${currentStepIndex + 1}`;
        setForm(updatedForm);
        setCanPublish(false);
        setQueue(prev => (prev || []).concat({
          updatedForm,
          successMessage: 'Step saved.'})); // Auto save the step title as soon as added
      }
      setIdentifyItem({type: 'step-title', stepIndex: currentStepIndex});
      break;
    case 'description':

      if (!form.builderSteps[currentStepIndex].description) {
        updatedForm.builderSteps[currentStepIndex].description = 'Step Description';
        setForm(updatedForm);
        setCanPublish(false);
        setQueue(prev => (prev || []).concat({
          updatedForm,
          successMessage: 'Step saved.'}));
      }
      setIdentifyItem({type: 'step-description', stepIndex: currentStepIndex});
      break;
    default:
      setEditError(`Unknown attr: ${attr}`);
      return;
    }
  }, [form, currentStepIndex, setForm, setCanPublish]);

  const handleAddFormAttr = useCallback(({attr}) => {
    const updatedForm = JSON.parse(JSON.stringify(form));

    switch (attr) {
    case 'title':
      if (!form.title) {
        updatedForm.title = 'Form Title';
        if (!updatedForm.builderSteps) updatedForm.builderSteps = [{builderQuestions: []}];
        setForm(updatedForm);
        setCanPublish(false);
        setQueue(prev => (prev || []).concat({
          updatedForm,
          successMessage: 'Title saved.'})); // Auto save the title as soon as added
      }
      setIdentifyItem({type: 'form-title'});
      break;
    case 'description':
      if (!form.description) {
        updatedForm.description = 'Form Description';
        if (!updatedForm.builderSteps) updatedForm.builderSteps = [{builderQuestions: []}];
        setForm(updatedForm);
        setCanPublish(false);
        setQueue(prev => (prev || []).concat({
          updatedForm,
          successMessage: 'Form description saved.'}));
      }
      setIdentifyItem({type: 'form-description'});
      break;
    default:
      setEditError(`Unknown attr: ${attr}`);
      return;
    }
  }, [form, setForm, setCanPublish]);

  const handleAddCompletionAttr = useCallback(({attr}) => {
    const updatedForm = JSON.parse(JSON.stringify(form));

    if (!form.completion) updatedForm.completion = {};

    switch (attr) {
    case 'title':
      if (!updatedForm.completion.title) {
        updatedForm.completion.title = 'Form Submitted';
        setForm(updatedForm);
        setCanPublish(false);
        setQueue(prev => (prev || []).concat({
          updatedForm,
          successMessage: 'Completion title saved.'}));
      }
      setIdentifyItem({type: 'completion-title'});
      break;
    case 'message':
      if (!updatedForm.completion.message) {
        updatedForm.completion.message = 'Thank you';
        setForm(updatedForm);
        setCanPublish(false);
        setQueue(prev => (prev || []).concat({
          updatedForm,
          successMessage: 'Completion message saved.'}));
      }
      setIdentifyItem({type: 'completion-message'});
      break;
    default:
      setEditError(`Unknown attr type: ${attr}`);
      return;
    }
  }, [form, setForm, setCanPublish]);

  const handleCloseConfigure = useCallback(() => {
    setShowConfigure(false);
  }, []);

  const handleDeselectItem = useCallback(() => {
    setShowConfigure(null);
    setIdentifyItem(null);
    handleRemoveMessages();
  }, [handleRemoveMessages]);

  // Delete either a question or a step title
  const handleDeleteItem = useCallback(({ type, stepIndex,  questionIndex }) => {
    const updatedForm = JSON.parse(JSON.stringify(form));
    switch (type) {
    case 'question':
    case 'paragraph-text':
      updatedForm.builderSteps[stepIndex].builderQuestions.splice(questionIndex, 1);
      break;
    case 'step-title':
      updatedForm.builderSteps[stepIndex].title = null;
      break;
    case 'form-title':
      updatedForm.title = null;
      break;
    case 'form-description':
      updatedForm.description = null;
      break;
    case 'step-description':
      updatedForm.builderSteps[stepIndex].description = null;
      break;
    case 'completion-title':
      updatedForm.completion.title = null;
      break;
    case 'completion-message':
      updatedForm.completion.message = null;
      break;
    default:
      setEditError(`Unknown item type: ${type}`);
      return;
    }

    setIdentifyItem(null);

    setForm(updatedForm);
    setCanPublish(false);
    setQueue(prev => (prev || []).concat({
      updatedForm,
      successMessage: 'Item deleted.'
    }));
  }, [form, setForm, setCanPublish]);

  // Move a question up
  const handleMoveQuestion = useCallback(({ stepIndex, questionIndex, newQuestionIndex }) => {
    let updatedForm = JSON.parse(JSON.stringify(form));

    const identifyItemRefBeforeMove = identifyItem ? (updatedForm.builderSteps[identifyItem.stepIndex].builderQuestions[identifyItem.questionIndex]?.key ||
    updatedForm.builderSteps[identifyItem.stepIndex].builderQuestions[identifyItem.questionIndex]?.title) : null;

    updatedForm.builderSteps[stepIndex].builderQuestions = arrayMove(
      updatedForm.builderSteps[stepIndex].builderQuestions,
      questionIndex,
      newQuestionIndex);

    if (identifyItemRefBeforeMove) setIdentifyItem(prev => {
      const newQuestionIndex = updatedForm.builderSteps[prev.stepIndex].builderQuestions.findIndex(q => {
        if (q.key) return q.key === identifyItemRefBeforeMove;
        return q.title === identifyItemRefBeforeMove;
      });
      return {...prev, questionIndex: newQuestionIndex};
    });

    setForm(updatedForm);
    setCanPublish(false);
    setQueue(prev => (prev || []).concat({
      updatedForm,
    }));
  }, [form, setForm, identifyItem, setCanPublish]);

  const handleDeleteStep = useCallback(({ stepIndex }) => {
    const updatedForm = JSON.parse(JSON.stringify(form));
    updatedForm.builderSteps.splice(stepIndex, 1);
    setIdentifyItem(null);

    setForm(updatedForm);
    setCurrentStepIndex(updatedForm.builderSteps.length === 1 ? 0 : stepIndex - 1);
    setCanPublish(false);
    setQueue(prev => (prev || []).concat({
      updatedForm,
      successMessage: 'Step deleted.',
    }));
  }, [form, setForm, setCanPublish]);

  const handleSaveTheme = useCallback(({type, item}) => {
    const updatedForm = JSON.parse(JSON.stringify(form));
    if (!updatedForm.style) updatedForm.style = {};

    switch (type) {
    case 'font-family':
      updatedForm.style.fontFamily = item;
      break;
    case 'accent-colour':
      updatedForm.style.accentColor = item;
      // Progress bar shadow needs to be slightly lighter
      if (!updatedForm.style.progressBar) updatedForm.style.progressBar = {};
      updatedForm.style.progressBar.boxShadow = `inset 0 3px 6px 3px ${hexToCssHsl({hex: item, lightnessChange: 10})}`;
      break;
    case 'primary-button':
      if (!updatedForm.style.primaryButton) updatedForm.style.primaryButton = {};
      updatedForm.style.primaryButton = item;
      break;
    default:
      setConfigureError(`Unknown item type: ${type}`);
      return;
    }

    setForm(updatedForm);
    handleRemoveMessages();
    setCanPublish(false);
    setQueue(prev => (prev || []).concat({
      updatedForm,
      successMessage: 'Theme changes saved.'}));
  }, [form, setForm, handleRemoveMessages, setCanPublish]);

  // In-out of preview mode
  useEffect(() => {
    setIdentifyItem(null);
    if (showPreview) {
      handleRemoveMessages();
      setCurrentStepIndex(0); // Always go to start when going into preview
      setShowSubmitted(false);
      setShowConfigure(false);
      mixpanel?.track('Viewed Form Preview', { page: 'Build' });
    }
  }, [showPreview, mixpanel, handleRemoveMessages]);

  // Select a new item to configure when an item is identified
  useEffect(() => {
    if (identifyItem && (!prevIdentifyItem || (
      (identifyItem.showConfigure && (prevIdentifyItem?.showConfigure !== identifyItem.showConfigure)) ||
      (prevIdentifyItem?.type !== identifyItem.type) ||
      (prevIdentifyItem?.type === 'question' && (prevIdentifyItem.questionIndex !== identifyItem.questionIndex)) ||
      (prevIdentifyItem?.type === 'paragraph-text' && (prevIdentifyItem.questionIndex !== identifyItem.questionIndex)) ||
      (prevIdentifyItem?.type === 'step-title' && (prevIdentifyItem.stepIndex !== identifyItem.stepIndex))))
    ) {
      if (identifyItem.showConfigure) setShowConfigure(true);
    }
  }, [identifyItem, form, prevIdentifyItem]);

  // Remove messages when an item is identified
  useEffect(() => {
    if (identifyItem && (!prevIdentifyItem || (prevIdentifyItem &&
      ((prevIdentifyItem.type !== identifyItem.type) ||
      (prevIdentifyItem.type === 'question' && (prevIdentifyItem.key !== identifyItem.key)) || // TODO: do we need to check title too?
      (((prevIdentifyItem.type === 'step-title' || prevIdentifyItem.type === 'step-description') && (prevIdentifyItem.stepIndex !== identifyItem.stepIndex))) ||
      ((prevIdentifyItem.type === 'paragraph-text' && (prevIdentifyItem.questionIndex !== identifyItem.questionIndex)))
      ))
    )) {
      handleRemoveMessages();
    }
  }, [identifyItem, prevIdentifyItem, handleRemoveMessages]);

  // Reset states for a new form
  useEffect(() => {
    setShowConfigure(null);
    setIdentifyItem(null);
    setCurrentStepIndex(0);
    setShowSubmitted(false);
    setConfigureError(null);
    setConfigureErrorMessages(null);
    if (location.state?.newForm) {
      setSuccessMessage('Form created');
    } else {
      setSuccessMessage(null);
    }
  }, [location, setShowCheckoutSuccessMsg]);


  useEffect(() => {
    mixpanel?.track('Page View', { page: 'Build',
      product: 'builder',
    });
  }, [mixpanel]);

  useEffect(() => {
    if (!queueItemBeingSaved && queue?.length > 0) {
      setCanPublish(false);
      setQueueItemBeingSaved(queue[0]);
    }
  }, [queueItemBeingSaved, queue, setCanPublish]);

  // Process the first item in the queue, and remove once complete. Stops processing the queue if there is an error.
  useEffect(() => {
    if (queueItemBeingSaved && !formIsSaving && !configureError && !configureErrorMessages?.length) {
      setCanPublish(false);
      setFormIsSaving(true);
      const {
        updatedForm,
        successMessage,
      } = queueItemBeingSaved;

      (async () => {
        try {
          handleRemoveMessages();

          let savedForm;

          if (form?.uuid === 'new') {
            updatedForm.completion = {
              title: 'Form Submitted',
              message: 'Thank you',
            };
            const { data: { form: newForm } } = await api.post('/builder/forms', {
              builderForm: updatedForm,
            });
            savedForm = newForm;
            setForm(savedForm);
          } else {
            const { data: { form: newForm } } = await api.put(`/builder/forms/${form?.uuid}`, {
              builderForm: updatedForm,
            });
            savedForm = newForm;
          }

          if (form?.uuid === 'new') history.push(`/builder/forms/${savedForm.uuid}/build`, {newForm: true});

          setSuccessMessage(successMessage || 'Changes saved.');

          if (successMessageTimeout) clearTimeout(successMessageTimeout);
          setSuccessMessageTimeout(setTimeout(() => {setSuccessMessage(null);}, 4000));

          mixpanel?.track('Saved Form Changes', { page: 'Build' });
        } catch (e) {
          const defaultError = 'Sorry, something went wrong. The changes have not been saved, please try again.';
          if (e?.response) {
            switch (e.response?.status) {
            case e?.response?.data?.errors && 400:
            case e?.response?.data?.errors && 422:
              if (Array.isArray(e.response.data.errors)) {
                setConfigureErrorMessages(e.response.data.errors);
              } else {
                setConfigureError(defaultError);
              }
              break;
            case 401:
              setConfigureError('Not logged in');
              break;
            case 403:
              history.replace('/builder/forms');
              break;
            default:
              setConfigureError(defaultError);
            }
          } else {
            setConfigureError(defaultError);
          }
        } finally {
          setQueue(prev => prev.slice(1));
          setFormIsSaving(false);
          setQueueItemBeingSaved(null);
        }
      })();
    }
  }, [queueItemBeingSaved, formIsSaving, configureError, configureErrorMessages, setCanPublish,
    form?.uuid, setForm, history, currentStepIndex, handleRemoveMessages, mixpanel, successMessageTimeout]);

  useEffect(() => {
    if (queue?.length === 0 && !formIsSaving) setCanPublish(true);
  }, [queue?.length, formIsSaving, setCanPublish]);

  useEffect(() => {
    if (!deviceType) setDeviceType('desktop');
  }, [deviceType]);

  const formHasBeenCreated = form?.uuid !== 'new';

  return (
    <div className="build-page h-100">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Form Builder | Build</title>
      </Helmet>
      <Row className="g-0 h-100 d-flex">
        {formsError && <p className="p-3 text-center">{formsError}</p>}
        {!formsError && !showPreview && <>
          <EditTypeWrapper
            showSubmitted={showSubmitted}
            handleAddCompletionAttr={handleAddCompletionAttr}
            formHasBeenCreated={formHasBeenCreated}
            handleCreateQuestion={handleCreateQuestion}
            handleCreateParagraphText={handleCreateParagraphText}
            handleAddStepAttr={handleAddStepAttr}
            handleAddFormAttr={handleAddFormAttr}
            setQueue={setQueue}
            setCanPublish={setCanPublish}
            handleRemoveMessages={handleRemoveMessages}
            handleSaveTheme={handleSaveTheme} />
          <Col className="edit-form-area p-0 d-flex flex-column">
            <div className="scrollable-column position-relative">
              <div className="pt-2 me-3 position-absolute feedback-wrapper">
                <FeedbackRow
                  classList={['allow-scroll-under-nav']}
                  mixpanel={mixpanel}
                  page={'Build'}
                  messageContent={'editing and publishing your form'} />
              </div>
              <Row className={`g-0 alert-row justify-content-center top-sticky-row ${(successMessage || formIsSaving || editError || configureError || configureErrorMessages?.length > 0)
                ? 'solid-background' : ''}`}>
                {showCheckoutSuccessMsg &&
                  <Alert dismissible variant="success" closeVariant="white"
                    onClose={() => setShowCheckoutSuccessMsg(false)}>
                    <div className="alert-svg-icon my-auto"><VscCheck size="100%"/></div>
                    <p className="alert-text m-0">Thank you. Your payment has been successful and your upgrade has been applied.</p>
                  </Alert>}
                {showGettingStarted &&
                  <Alert dismissible={false} variant={'info'} className="d-flex flex-column align-items-start new-form-alert">
                    <h2 className="mb-3">Getting started</h2>

                    <h5 className="fw-bold">Essential actions</h5>
                    <ul>
                      <li className="pb-1">Add a <i>Short Text</i> field from the <i>Gallery</i> sidebar on the left.</li>
                      <li className="mb-1">Edit the field label directly on the form, or open the settings with <VscSettingsGear className="align-baseline" size="16px"/>.</li>
                      <li className="pb-1">Move fields up and down using the arrows <VscArrowUp className="align-baseline" size="16px"/>.</li>
                      <li className="pb-1">Edit the completion message via the <i>Completion Page</i> button on the bottom row.</li>
                    </ul>

                    <h5 className="fw-bold">Multi-step form</h5>
                    <ul>
                      <li className="pb-1">Click the <i>Add Step</i> button on the bottom row.</li>
                    </ul>

                    <h5 className="fw-bold">Publish</h5>
                    <ul className="mb-0">
                      <li className="pb-1">Once you're happy with the structure, <i>Publish</i> your form, and share it to start collecting submissions.</li>
                    </ul>
                  </Alert>}
                {!showGettingStarted && !showCheckoutSuccessMsg && !editError && !configureError && !configureErrorMessages?.length &&
                  <Alert variant="outline-success" className="mb-0" show={(successMessage || formIsSaving) ? true : false}>
                    {successMessage ? <>
                      <div className="alert-svg-icon my-auto"><VscCheck size="26px"/></div>
                      <p className="alert-text m-0">{successMessage}</p>
                    </> :
                      formIsSaving && <>
                        <div className="alert-svg-icon my-auto"><FaSpinner size="20px" className="spinning-icon"/></div>
                        <p className="alert-text m-0">Saving...</p></>}
                  </Alert>}
                {editError &&
                  <Alert variant="danger" closeVariant="white">
                    <div className="alert-svg-icon my-auto"><VscWarning size="20px"/></div>
                    <p className="alert-text m-0">{editError}</p>
                  </Alert>}
                {configureError &&
                  <Alert variant="danger" closeVariant="white">
                    <div className="alert-svg-icon my-auto"><VscWarning size="20px"/></div>
                    <p className="alert-text m-0">{configureError}</p>
                  </Alert>}
                {configureErrorMessages?.length > 0 &&
                  <Alert variant="danger" closeVariant="white">
                    <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                    <p className="alert-text m-0">{configureErrorMessages.map((e) => e.message)}</p>
                  </Alert>}
              </Row>
              <div className={`form-wrapper flex-grow-1 ${firstQuestion ? 'first-question' : ''}`}>
                {form && !showGettingStarted && <>
                  <InAppForm
                    key={form?.uuid}
                    form={form}
                    inEdit={true}
                    handleSaveInlineEditItem={handleSaveInlineEditItem}
                    handleRemoveMessages={handleRemoveMessages}
                    setIdentifyItem={setIdentifyItem}
                    identifyItem={identifyItem}
                    inAppStepIndex={currentStepIndex}
                    setInAppStepIndex={setCurrentStepIndex}
                    inAppShowSubmitted={showSubmitted}
                    setInAppShowSubmitted={setShowSubmitted}
                    handleMoveQuestion={handleMoveQuestion}
                    handleDeleteItem={handleDeleteItem} />
                </>}
              </div>
            </div>
            <StepActionsRow
              currentStepIndex={currentStepIndex}
              showSubmitted={showSubmitted}
              setShowSubmitted={setShowSubmitted}
              setIdentifyItem={setIdentifyItem}
              builderSteps={form?.builderSteps}
              setCurrentStepIndex={setCurrentStepIndex}
              handleDeleteStep={handleDeleteStep}
              handleCreateNewStep={handleCreateNewStep}
              formHasBeenCreated={formHasBeenCreated}
              showPreview={showPreview}
            />
          </Col>
          <Configure
            showConfigure={showConfigure}
            handleCloseConfigure={handleCloseConfigure}
            handleDeselectItem={handleDeselectItem}
            questionIndex={identifyItem?.questionIndex}
            stepIndex={identifyItem?.stepIndex}
            type={identifyItem?.type}
            handleSaveConfigureItem={handleSaveConfigureItem}
            handleSaveInlineEditItem={handleSaveInlineEditItem}
            handleRemoveMessages={handleRemoveMessages}
            currentKeys={currentKeys}
            questionKeysToUse={questionKeysToUse}
          />
        </>}
        {showPreview && form && !showGettingStarted &&
        <Col className="preview-form-area p-0 d-flex flex-column">
          <Row className="g-0 toggle-device-view-row justify-content-end position-absolute">
            <Col className="col-auto p-0">
              <ButtonGroup className="toggle-buttons">
                {[{label: 'Mobile', value: 'mobile'}, {label: 'Desktop', value: 'desktop'}].map((device) => (
                  <ToggleButton
                    key={device.value}
                    id={`radio-${device.value}`}
                    type="radio"
                    variant="outline-secondary"
                    className="builder-outline-secondary"
                    name="device-toggle"
                    value={device.value}
                    checked={deviceType && device.value === deviceType}
                    onChange={({target: {value}}) => {
                      setDeviceType(value);
                    }}>
                    {device.label}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </Col>
          </Row>
          <div className="d-flex justify-content-center scrollable-column position-relative">
            <div className={`d-flex ${deviceType === 'mobile' ? 'preview-mobile-device' : ''}`}>
              <div className={`d-flex ${deviceType === 'mobile' ? 'mobile-wrapper' : ''}`}>
                <div className={`form-wrapper flex-grow-1`}>
                  <InAppForm
                    key={form?.uuid}
                    form={form}
                    inAppStepIndex={currentStepIndex}
                    setInAppStepIndex={setCurrentStepIndex}
                    inAppShowSubmitted={showSubmitted}
                    setInAppShowSubmitted={setShowSubmitted} />
                </div>
              </div>
            </div>
          </div>
          <StepActionsRow
            currentStepIndex={currentStepIndex}
            showSubmitted={showSubmitted}
            setShowSubmitted={setShowSubmitted}
            setIdentifyItem={setIdentifyItem}
            builderSteps={form?.builderSteps}
            setCurrentStepIndex={setCurrentStepIndex}
            handleDeleteStep={handleDeleteStep}
            handleCreateNewStep={handleCreateNewStep}
            formHasBeenCreated={formHasBeenCreated}
            showPreview={showPreview}
          />
        </Col>
        }
      </Row>
    </div>
  );
};

export default Build;
