import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaCog } from "react-icons/fa";
import moment from 'moment-timezone';

import NavBar from '../../NavBar';
import DatePicker from '../../Components/DatePicker';
import NoFormsMsg from '../../Components/NoFormsMsg';
import AppAlerts from '../../Components/AppAlerts';
import PrintPageHeader from '../../Components/PrintPageHeader';
import ScrollToTop from '../../Components/ScrollToTop';
import FeedbackRow from '../../Components/FeedbackRow';
import CopyUrlIcon from '../../Components/CopyUrlIcon';
import FormSelect from '../../Components/Select/FormSelect';
import FiltersSelect from '../../Components/Select/FiltersSelect';
import useFetchFilters from '../../hooks/useFetchFilters.ts';
import AppContext from '../../AppContext';
import api from '../../api';
import { usePrevious, useAppQuery, useAppForms } from '../../hooks';
import {
  compileQueryString,
  labelForField,
  orgDetailsForMixpanel,
} from '../../utils';
import { zScoreOfProportions } from '../FieldData';

import './Insights.scss';
import Forms from '../../forms';
import Headlines from './Headlines';
import SegmentSplit from './SegmentSplit';

const Insights = ({mixpanel}) => {
  const history = useHistory();
  useAppForms();
  const { currentUser, formsGroupedByOrg, formsGroupedByFormUuid, formsLoading, formsLoadingError,
  } = useContext(AppContext);

  const { query, prevQuery } = useAppQuery();
  const { form, time, submitFieldIdentifier, filters } = query || {};

  // Query selections
  const [submitField, setSubmitField] = useState();
  const [submitFieldChangeFromSelect, setSubmitFieldChangeFromSelect] = useState(false);

  // Vis data
  const [topAbandonData, setTopAbandonData] = useState(null);
  const [topAbandonLoading, setTopAbandonLoading] = useState(true);
  const [topAbandonError, setTopAbandonError] = useState(null);
  const [topFieldReturnsGapData, setTopFieldReturnsGapData] = useState(null);
  const [topFieldReturnsGapLoading, setTopFieldReturnsGapLoading] = useState(true);
  const [topFieldReturnsGapError, setTopFieldReturnsGapError] = useState(null);
  const [topFieldsAfterSubmitData, setTopFieldsAfterSubmitData] = useState(null);
  const [topFieldsAfterSubmitLoading, setTopFieldsAfterSubmitLoading] = useState(true);
  const [topFieldsAfterSubmitError, setTopFieldsAfterSubmitError] = useState(null);
  const [highestReturnRateField, setHighestReturnRateField] = useState();

  const { filtersLoading, filtersError, availableFilters } = useFetchFilters({includeSessionOutcome: false});

  const prevSubmitField = usePrevious(submitField);

  const formUuid = form?.uuid;
  // TODO: get from org?
  const orgName = form?.organisation?.name;

  useEffect(() => {
    // Identify the mixpanel user on page load for any subsequent tracking
    mixpanel.identify(currentUser.email);
  }, [mixpanel, currentUser.email]);

  // Send the page view mixpanel event each time the  app-wide form is updated - which reloads the charts
  useEffect(() => {
    if (form && form.organisation) {
      mixpanel.track('Page View', Object.assign({ page: 'Insights' }, orgDetailsForMixpanel(form.organisation)));
    }
  }, [mixpanel, form]);

  useEffect(() => {
    if (!formsLoading && formsLoadingError) {
      setTopAbandonLoading(false);
      setTopAbandonError(formsLoadingError);
      setTopFieldReturnsGapError(formsLoadingError);
      setTopFieldsAfterSubmitError(formsLoadingError);
    }
  }, [formsLoading, formsLoadingError]);

  const loadTopAbandon = useCallback(async () => {
    try {
      setTopAbandonLoading(true);
      setTopAbandonData(null);
      setTopAbandonError(null);

      const res = await api.get('/visualisations/abandoned-fields', {
        params: {
          formUuid: form?.uuid,
          startTime: time?.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          endTime: time?.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          filter: filters?.reduce((acc, {key, label: value}) => {
            if (!acc.hasOwnProperty(key)) acc[key] = [];
            acc[key].push(value);
            return acc;
          }, {}),
        },
      });

      let data = res.data;
      const isData = (data && Array.isArray(data) && data.length > 0) ? true : false;
      data = isData && data
        .map(field => {
          field.label = field.fieldLabel;
          return {...field, label: labelForField(field)};
        })
        .filter(field => field.hasOwnProperty('hidden') ? !field.hidden : true);

      setTopAbandonLoading(false);

      if (isData) {
        const top5SortedCount = data.sort((a,b) => b.abandonCount - a.abandonCount).slice(0,5);
        const top5SortedRate = data.sort((a,b) => b.abandonRate - a.abandonRate).slice(0,5);

        setTopAbandonData({
          volume: {
            chart: {
              data: {
                labels: top5SortedCount.map(f => f.label),
                datasets: [{
                  data: top5SortedCount.map(f => f.abandonCount),
                  backgroundColor: 'rgba(253, 131, 43, 0.7)',
                  maxBarThickness: 30,
                }]
              },
              fieldIdentifiers: top5SortedCount.map(({identifier}) => identifier),
            }
          },
          rate: {
            chart: {
              data: {
                labels: top5SortedRate.map(f => f.label),
                datasets: [{
                  data: top5SortedRate.map(f => f.abandonRate),
                  backgroundColor: 'rgba(187, 48, 135, 0.7)',
                  maxBarThickness: 30,
                }]
              },
              fieldIdentifiers: top5SortedRate.map(({identifier}) => identifier),
            }
          }
        });
      }
      setTopAbandonError(!isData && 'No data to display');
    } catch (e) {
      setTopAbandonLoading(false);
      setTopAbandonError((e.response && (e.response.status === 404)) ? 'Form not found' :
        (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
    }
  }, [form?.uuid, time?.end, time?.start, filters]);

  const loadTopFieldsAfterSubmit =  useCallback(async () => {
    try {
      setTopFieldsAfterSubmitLoading(true);
      setTopFieldsAfterSubmitData(null);
      setTopFieldsAfterSubmitError(null);

      let { data: { fieldFlows, sampleSize, totalSessions } } = await api.get('/field-flows', {
        params: {
          formUuid: form?.uuid,
          time: {start: time?.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'), end: time?.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]')},
          filters: filters?.reduce((acc, {key, label: value}) => {
            if (!acc.hasOwnProperty(key)) acc[key] = [];
            acc[key].push(value);
            return acc;
          }, {}),
          sessionOutcome: ['abandoned'],
          targetFieldIdentifier: submitFieldIdentifier || submitField.identifier, // submitField.identifier is only used for a default selection
        },
      });

      fieldFlows = fieldFlows.filter((flow) => flow.to.type === 'field' && (flow.to.hasOwnProperty('hidden') ? !flow.to.hidden : true));

      const isData = (fieldFlows && fieldFlows.length > 0);
      if (isData) {
        const totalFlows = fieldFlows.reduce((acc, {flow}) => acc + flow, 0);
        const top5Sorted = fieldFlows
          .map(({to, flow}) => ({...to, label: labelForField(to), flow, percentOfFlows: Math.round(((flow/totalFlows)* 100) * 100) / 100}))
          .sort((a,b) => b.flow - a.flow).slice(0,5);

        setTopFieldsAfterSubmitData({
          sampleSize,
          totalSessions,
          chart: {
            percentOfFlows: top5Sorted.map(f => f.percentOfFlows),
            data: {
              labels: top5Sorted.map(f => f.label),
              datasets: [
                {
                  data: top5Sorted.map(f => f.flow),
                  backgroundColor: 'rgba(244, 85, 131, 0.7)',
                  maxBarThickness: 30,
                }
              ]
            },
            fieldIdentifiers: top5Sorted.map(({identifier}) => identifier),
          },
        });
      }
      setTopFieldsAfterSubmitLoading(false);
      setTopFieldsAfterSubmitError(!isData && 'No data to display');
    } catch (e) {
      setTopFieldsAfterSubmitError((e.response && (e.response.status === 404)) ? 'Form not found' :
        (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
      setTopFieldsAfterSubmitLoading(false);
    }
  },[form?.uuid, time?.end, time?.start, filters, submitFieldIdentifier, submitField]);

  const loadTopFieldReturnsGap = useCallback(async () => {
    try {
      setTopFieldReturnsGapLoading(true);
      setTopFieldReturnsGapError(null);
      setTopFieldReturnsGapData(null);
      setHighestReturnRateField(null);

      const res = await api.get('/visualisations/return-to-field', {
        params: {
          formUuid: form.uuid,
          startTime: time.start.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          endTime: time.end.clone().utc().format('YYYY-MM-DDTHH:mm:ss[Z]'),
          filter: filters?.reduce((acc, {key, label: value}) => {
            if (!acc.hasOwnProperty(key)) acc[key] = [];
            acc[key].push(value);
            return acc;
          }, {}),
        },
      });

      const data = res.data;
      const isData = (data && Array.isArray(data) && data.length > 0) ? true : false;

      const preparedData = isData && data
        .filter(field => field.hasOwnProperty('hidden') ? !field.hidden : true)
        .map(field => {
          field.label = field.fieldLabel;
          return {...field, label: labelForField(field)};
        });

      // TODO: sometimes multiple low traffic fields have 100% return rate. Do we need to improve how we choose this?
      if (preparedData) setHighestReturnRateField(preparedData.sort((a,b) => b.allSessionsReturned - a.allSessionsReturned)[0]);

      const dataInclSignificantDiff = isData && preparedData.map((row) => {
        const p1 = row.abandonedSessionsReturned/100;
        const p2 = row.completedSessionsReturned/100;
        const p = row.allSessionsReturned/100;
        const n1 = row.abandonedSessionsTotal;
        const n2 = row.completedSessionsTotal;
        const zScore = zScoreOfProportions({p1, p2, p, n1, n2});
        const significantDiff = zScore <= -1.65 || zScore >= 1.65;

        return {
          ...row,
          significantDiff,
          ...significantDiff && {
            zScore,
            rawDiff: row.abandonedSessionsReturned - row.completedSessionsReturned,
          },
        };
      });

      const dataWithDifferences = isData && dataInclSignificantDiff
        .filter(field => field.significantDiff && field.zScore > 0); // Only select differences in abandoned sessions

      setTopFieldReturnsGapLoading(false);

      if (isData && dataWithDifferences.length) {
        const top5Sorted = dataWithDifferences.sort((a,b) => b.rawDiff - a.rawDiff).slice(0,5);

        setTopFieldReturnsGapData({
          chart: {
            outcomes: top5Sorted.map(f => ({completed: f.completedSessionsReturned, abandoned: f.abandonedSessionsReturned})),
            data: {
              labels: top5Sorted.map(f => f.label),
              datasets: [
                {
                  label: 'Difference',
                  data: top5Sorted.map(f => f.rawDiff),
                  backgroundColor: 'rgba(97, 30,121, 0.8)',
                  borderColor: 'rgba(97, 30,121, 0.8)',
                  borderWidth: 1,
                  maxBarThickness: 30,
                }
              ]
            },
            fieldIdentifiers: top5Sorted.map(({identifier}) => identifier),
          },
        });
      }
      setTopFieldReturnsGapError(!isData ? 'No data to display' : !dataWithDifferences.length ? 'No big behaviour differences found' : null);
    } catch (e) {
      setTopFieldReturnsGapLoading(false);
      setTopFieldReturnsGapData(null);
      setTopFieldReturnsGapError((e.response && (e.response.status === 404)) ? 'Form not found' :
        (e.response && (e.response.status === 401)) ? 'Not logged in' : 'Something went wrong');
    }
  }, [form?.uuid, time?.end, time?.start, filters]);

  // Once a new query has been set - proceed to fetch data
  useEffect(() => {
    if (
      query?.form?.uuid && query?.time?.start && query?.time?.end && // Required
      (!prevQuery || (prevQuery && ( // Differences
        (prevQuery?.form?.uuid !== query?.form?.uuid) ||
        (JSON.stringify(prevQuery.filters) !== JSON.stringify(query.filters)) ||
        (!prevQuery.time || (prevQuery.time &&
          (JSON.stringify(prevQuery.time.start) !== JSON.stringify(query.time.start) ||
          (JSON.stringify(prevQuery.time.end) !== JSON.stringify(query.time.end)))))
      ))) &&
      !submitFieldChangeFromSelect // Don't reload all charts if only the submit field has changed
    ) {
      loadTopAbandon();
      loadTopFieldReturnsGap();

      setTopFieldsAfterSubmitLoading(true);
      setTopFieldsAfterSubmitError(null);
      setTopFieldsAfterSubmitData(null);
    }
  }, [prevQuery, query, loadTopAbandon, loadTopFieldReturnsGap, submitFieldChangeFromSelect]);

  // The first time a selected submit field is set (either set by the defualt or from the params) - load top fields after submit
  useEffect(() => {
    if (!prevSubmitField && submitField?.identifier && formsGroupedByFormUuid) {
      loadTopFieldsAfterSubmit();
    }
  }, [prevSubmitField, loadTopFieldsAfterSubmit, submitField?.identifier, formsGroupedByFormUuid]);

  // When submit field is changed - load top fields after submit
  useEffect(() => {
    if (prevSubmitField && submitField && (prevSubmitField.identifier !== submitField.identifier)) {
      setSubmitFieldChangeFromSelect(false);
      loadTopFieldsAfterSubmit();
    }
  }, [prevSubmitField, submitField, loadTopFieldsAfterSubmit]);

  const handleDateTimeRangeChange = useCallback((start, end) => {
    const [utcStart, utcEnd] = [start, end].map((moment) => moment.clone().utc());
    mixpanel.track('Selected Time', {
      page: 'Insights',
      start: utcStart.toISOString(),
      end: utcEnd.toISOString(),
      daysDiff: utcEnd.diff(utcStart, 'days'),
      daysFromNow: moment.utc().diff(utcStart, 'days'),
      ...orgDetailsForMixpanel(form?.organisation),
    });

    history.push(compileQueryString({
      form,
      time: {start, end},
      submitFieldIdentifier,
      filters,
    }));
  }, [mixpanel, form, history, submitFieldIdentifier, filters]);

  const handleFormChange = useCallback(({value: uuid}) => {
    mixpanel.track('Selected Form', { page: 'Insights', ...orgDetailsForMixpanel(form?.organisation) });
    if (uuid !== form?.uuid) {
      history.push(compileQueryString({
        form: formsGroupedByFormUuid[uuid],
        time,
        submitFieldIdentifier: null,
        filters, // Allowing filters to persist across forms
      }));
    }
  }, [formsGroupedByFormUuid, history, mixpanel, form?.uuid, form?.organisation, time, filters]);

  const handleFiltersChange = useCallback((input) => {
    mixpanel.track('Selected Filters', { page: 'Insights', ...orgDetailsForMixpanel(form?.organisation) });

    history.push(compileQueryString({
      form,
      time,
      filters: input,
      submitFieldIdentifier,
    }));
  }, [mixpanel, form, history, time, submitFieldIdentifier]);

  return (
    <Container fluid className="insights page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Insights</title>
      </Helmet>
      <div className="nav-wrapper browser-only">
        <NavBar mixpanel={mixpanel}/>
        <Row className="g-0 nav-primary">
          <Col className="col-md-auto col-sm-12 pt-0 pb-2 pe-md-1 mt-1 d-inline-flex" id="datepicker">
            <DatePicker
              startTime={time?.start}
              endTime={time?.end}
              onApply={handleDateTimeRangeChange}
              timeZone={form?.organisation?.timeZone}
              selectedFormTimeZone={form?.organisation?.timeZone}
            />
          </Col>
          <Col md={3} className="pt-0 pb-2 px-md-1 mt-1">
            <FormSelect
              id={'form-select'}
              handleFormChange={handleFormChange}
              selectedForm={form}
            />
          </Col>
          <Col className="pt-0 pb-2 ps-md-1 mt-1">
            <FiltersSelect
              form={form}
              filters={filters}
              filtersLoading={filtersLoading}
              availableFilters={availableFilters}
              filtersLoadingError={filtersError}
              handleFiltersChange={handleFiltersChange} />
          </Col>
        </Row>
      </div>
      <ScrollToTop />
      <div className="main-content">
        {(formsLoading || formsLoadingError || !formsGroupedByOrg || Forms.length > 0) ?
          <Col className="center-column justify-content-md-center">
            <div className="pb-1">
              <FeedbackRow
                classList={['allow-scroll-under-nav']}
                mixpanel={mixpanel}
                page={'Insights'}
                org={form?.organisation}
                messageContent={'Insights'} />
            </div>
            <AppAlerts showOrgAlerts={true} />
            <Row className="title-row g-0 browser-only">
              {orgName && form.label && <>
                <Col className="p-0">
                  <h1 id="form-title" data-testid="page-title">
                    {`${orgName} | ${form.label} | `}
                    <a href={form.url} target="_blank" rel="noopener noreferrer">{form.url}</a>
                  </h1>
                </Col>
                <Col className="p-0 text-end my-auto ms-auto col-auto">
                  <CopyUrlIcon
                    queryString={compileQueryString({form, time, submitFieldIdentifier, filters})}/>
                  <Link to={`/forms/${formUuid}/edit`}><FaCog size="20px" className="grey-icon" title="Form settings"/></Link>
                </Col> </>
              }
            </Row>
            <Col className="chart-content p-0">
              <PrintPageHeader
                pageTitle={'Insights'}
                orgName={orgName}
                formLabel={form?.label}
                formUrl={form?.url}
                startTime={time?.start}
                endTime={time?.end}
                timeZone={form?.organisation?.timeZone}
                searchParams={form?.uuid && time?.start && time?.end && compileQueryString({form, time, submitFieldIdentifier, filters})}
              />
              <Headlines
                mixpanel={mixpanel}
                submitField={submitField}
                prevSubmitField={prevSubmitField}
                setSubmitField={setSubmitField}
                submitFieldChangeFromSelect={submitFieldChangeFromSelect}
                setSubmitFieldChangeFromSelect={setSubmitFieldChangeFromSelect}
                topAbandonData={topAbandonData}
                topAbandonLoading={topAbandonLoading}
                topAbandonError={topAbandonError}
                topFieldReturnsGapData={topFieldReturnsGapData}
                topFieldReturnsGapLoading={topFieldReturnsGapLoading}
                topFieldReturnsGapError={topFieldReturnsGapError}
                highestReturnRateField={highestReturnRateField}
                topFieldsAfterSubmitData={topFieldsAfterSubmitData}
                topFieldsAfterSubmitLoading={topFieldsAfterSubmitLoading}
                topFieldsAfterSubmitError={topFieldsAfterSubmitError}
                setTopFieldsAfterSubmitLoading={setTopFieldsAfterSubmitLoading}
                loadTopFieldsAfterSubmit={loadTopFieldsAfterSubmit}
              />
              <SegmentSplit
                submitFieldChangeFromSelect={submitFieldChangeFromSelect}
              />
            </Col>
          </Col> :
          <NoFormsMsg mixpanel={mixpanel} page={'Insights'}/>
        }
      </div>
    </Container>
  );
};

export default Insights;
