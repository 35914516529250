import React, { useEffect, useState, useCallback } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { orgDetailsForMixpanel } from '../utils';

import DashboardFormCard from './DashboardFormCard';
import { sortDashboardCards, sortOptions } from './Dashboard';

const DashboardFavsCard = ({mixpanel, favForms, favFormsSessionData, favFormsError, handleRemoveFavForm, firstOrg}) => {
  const [formsSessionCount, setFormsSessionCount] = useState();
  const [forms, setForms] = useState();
  const [sortedOn, setSortedOn] = useState();
  const [sortInitiated, setSortInitiated] = useState();

  useEffect(() => {
    setForms(favForms);
  }, [favForms]);

  // Cards sorting
  useEffect(() => {
    if (favForms?.length && sortedOn && sortInitiated) {
      setForms(sortDashboardCards({parentForms: [...favForms], sortedOn, formsSessionCount}));
      setSortInitiated(false);
    }
  }, [favForms, sortedOn, sortInitiated, formsSessionCount]);

  const handleFormsSort = (opt) => {
    setSortInitiated(true);
    setSortedOn(opt);
    mixpanel.track('Sorted Forms', { page: 'Dashboard', sortedOn: opt.value,  ...orgDetailsForMixpanel(firstOrg)});
  };

  const handleSessionCountFromForm = useCallback(({formUuid, currentCount}) => {
    setFormsSessionCount(prev => ({...prev, [formUuid]: {currentCount}}));
  }, []);

  return (
    <Card className="fav-forms-card" data-testid="fav-forms-card">
      <Card.Body className="pb-0">
        <Row className="g-0 card-title-row justify-content-between mb-2">
          <Col className="p-0 d-flex align-items-end col-auto">
            <Card.Title as="h3">Favourite Forms</Card.Title>
          </Col>
        </Row>
        <div className="card-vis">
          {forms?.length > 0 && <>
            <Row className="g-0 sort-tools-row">
              <Col className="p-0 col-auto d-flex align-items-center">
                <h4 className="card-tagline my-auto">Total Sessions over the last 30 days</h4>
              </Col>
              <Col className="p-0 d-flex justify-content-end">
                <Form className="d-inline-flex align-items-center">
                  <Form.Label className="me-2 mb-0" htmlFor={`sort-select-fav-forms`}>Sort by:</Form.Label>
                  <Select
                    id={`sort-select-fav-forms`}
                    styles={{
                      control: (styles, state) => ({...styles,
                        border: '1px solid #EBEDF2',
                      }),
                      option: (styles, state) => ({...styles,
                        color: '#3f4047',
                        backgroundColor: state.selectProps.value && (state.selectProps.value.label === state.label) ? "#E2E5Ec" : null,
                        '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
                      }),
                      menu: (styles, state) => ({...styles,
                        marginTop: '1px',
                        borderRadius: '4px',
                        border: '1px solid #EBEDF2',
                        boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                      }),
                      singleValue: (styles, state) => ({...styles,
                        color: state.isDisabled ? '#C3C3C3' : '#3F4047',
                      }),
                      dropdownIndicator: (styles, state) => ({...styles,
                        cursor: 'pointer',
                        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                        transition: 'transform .5s ease',
                      }),
                    }}
                    options={sortOptions}
                    onChange={handleFormsSort}
                    placeholder="Select..."
                    value={sortedOn}
                    aria-label={'Sorted On'}
                  />
                </Form>
              </Col>
            </Row>
            <div className="form-grid">
              <TransitionGroup component={null}>
                {forms.map((form) => (
                  <CSSTransition key={form.uuid} appear={true} timeout={100} classNames="plan-fade">
                    <DashboardFormCard
                      form={form}
                      key={`fav-form-${form.uuid}`}
                      providedSessionData={favFormsSessionData?.[form.uuid]}
                      handleSessionCountFromForm={handleSessionCountFromForm}
                      displayForms={!sortInitiated}
                      handleRemoveFavForm={handleRemoveFavForm}
                      isFavForm={true}
                      showingOnFavFormsCard={true}
                    />
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </div>
          </>}
          {!forms?.length > 0 &&
            <Row className="g-0">
              <Col className="p-0 col-auto d-flex align-items-center">
                <p className="my-auto">Click the star on a form to add it to your favourites.</p>
              </Col>
            </Row>
          }
          {favFormsError && <p className="mb-0">{favFormsError}</p>}
        </div>
      </Card.Body>
    </Card>
  );
};

export default DashboardFavsCard;
