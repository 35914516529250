import React, { useEffect, useState, useContext, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Alert from 'react-bootstrap/Alert';
import { VscWarning } from "react-icons/vsc";

import NavBar from '../../NavBar';
import AppAlerts from '../../Components/AppAlerts';
import FeedbackRow from '../../Components/FeedbackRow';
import FormDetailsForm from '../../Components/FormDetailsForm';
import api from '../../api';
import AppContext from '../../AppContext';
import Forms from '../../forms';
import * as Sentry from '@sentry/react';

import './FormAdd.scss';

const FormAdd = ({mixpanel}) => {
  const slug = '1ed4a38d66bd401c';

  const history = useHistory();
  const { currentUser, currentOrg, setCurrentOrg } = useContext(AppContext);

  const [showErrorMessagesForField, setShowErrorMessagesForField] = useState();
  const [errorMessagesForField, setErrorMessagesForField] = useState(); // Object response with error messages per field
  const [errorMessages, setErrorMessages] = useState(); // Array of messages
  const [errorMessage, setErrorMessage] = useState(); // Standard catch all single message
  const [mainError, setMainError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false); // Disable the button during submit

  const zukoRef = useRef(null);

  const handleResetErrorMessages = useCallback(() => {
    if (errorMessage) setErrorMessage(null);
    if (errorMessages) setErrorMessages(null);
    if (errorMessagesForField) setErrorMessagesForField(null);
  },[errorMessage, errorMessages, errorMessagesForField]);

  const handleResetErrorMessagesForField = useCallback((field) => {
    if (errorMessagesForField?.[field]) setErrorMessagesForField(messages => {
      const newMessages = {...messages};
      delete newMessages[field];
      return newMessages;
    });
  },[errorMessagesForField]);

  useEffect(() => {
    if (!currentOrg && currentUser.organisations.length) setCurrentOrg(currentUser.organisations[0]);
  }, [currentOrg, currentUser, setCurrentOrg]);

  useEffect(() => {
    mixpanel.identify(currentUser.email);
    mixpanel.track('Page View', {
      page: 'Add Form',
      ...currentOrg && {
        'Organisation Name': currentOrg.name,
        'Organisation Uuid': currentOrg.uuid,
        'Organisation Contract Type': currentOrg.contractType,
      }
    });
  }, [mixpanel, currentUser.email, currentOrg]);

  useEffect(() => {
    const zukoReplayScript = document.createElement('script');
    zukoReplayScript.src = 'https://assets.zuko.io/replay/v1/record.min.js';
    document.body.appendChild(zukoReplayScript);

    const s = document.createElement('script');
    s.src = 'https://assets.zuko.io/js/v2/client.min.js';
    document.body.appendChild(s);

    s.addEventListener('load', () => {
      const Zuko = window.Zuko;
      zukoRef.current = Zuko
        ?.trackForm({target: document.getElementById('form-details-form'), slug})
        ?.trackEvent(Zuko.FORM_VIEW_EVENT);
    });

    zukoReplayScript.addEventListener('load', () => {
      window.ZukoReplay?.recordForm({slug});
    });
  }, []);

  const handleSubmit = async ({contact, label, url, weeklyEmailEnabled, industry, purpose, selectedOrg}) => {
    if (isSubmitting) return;
    setIsSubmitting(true);

    try {
      handleResetErrorMessages();
      const { data: { form } } = await api.post('/forms', {
        form: {
          organisationUuid: selectedOrg.uuid,
          contactUuid: contact.uuid,
          label,
          url,
          weeklyEmailEnabled: true,
          industry,
          purpose,
        }
      });
      zukoRef?.current?.trackEvent(zukoRef?.current?.constructor.COMPLETION_EVENT);

      mixpanel.track('Created Form', { 'Form Label': label,
        page: 'Add Form',
        ...selectedOrg && {
          'Organisation Name': selectedOrg.name,
          'Organisation Uuid': selectedOrg.uuid,
          'Organisation Contract Type': selectedOrg.contractType,
        },
      });

      Forms.reload(); // Trigger re-loading of forms list (app-wide)

      if (form.uuid) history.push(`/forms/${form.uuid}/tracking_code`, { newForm: true });
    } catch (e) {
      switch (e?.response?.status) {
      case e?.response?.data?.errors && 400:
      case e?.response?.data?.errors && 422:
        if (Array.isArray(e.response.data.errors)) {
          setErrorMessages(e.response.data.errors);
          for (const {message} of e.response.data.errors) {
            zukoRef?.current?.trackEvent({type: `Field errors: ${message}`});
          }
        } else {
          setErrorMessagesForField(e.response.data.errors);
          setShowErrorMessagesForField(true);
          for (const [field, values] of Object.entries(e.response.data.errors)) {
            zukoRef?.current?.trackEvent({type: `Field in error. ${field}: ${values?.join(', ')}`});
            if (errorMessagesForField?.hasOwnProperty('url') || errorMessagesForField?.hasOwnProperty('domain')) {
              Sentry.captureMessage(`Field in error. ${field}: ${values?.join(', ')}. URL value: ${url}`);
            }
          }
        }
        break;
      case 401:
        setMainError('Not logged in');
        break;
      case 403:
        history.replace('/');
        break;
      default:
        setErrorMessage('Something went wrong. The form has not been created, please try again.');
        zukoRef?.current?.trackEvent({type: 'Unknown error occurred.'});
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container fluid className="form-add page">
      <Helmet titleTemplate="%s | Zuko" defaultTitle="Zuko" defer={false}>
        <title>Add Form</title>
      </Helmet>
      <div className="nav-wrapper">
        <NavBar mixpanel={mixpanel}/>
      </div>
      <div className="main-content">
        <Col className="center-column justify-content-md-center">
          <FeedbackRow
            classList={['allow-scroll-under-nav']}
            mixpanel={mixpanel}
            page={'Add Form'}
            org={currentOrg}
            messageContent={'adding a Form'} />
          <AppAlerts />
          <Row className="title-row g-0">
            <Col className="p-0">
            </Col>
          </Row>
          <Card className="form-add-card">
            <Card.Body>
              <Row className="g-0 card-title-row  justify-content-between">
                <Col className="p-0">
                  <Card.Title as="h3">Add Form</Card.Title>
                </Col>
                <Col className="p-0">
                </Col>
              </Row>
              <Row className="g-0 card-content flex-column">
                {mainError && <div id="load-error-message"><h3>{mainError}</h3></div>}
                {showErrorMessagesForField && (errorMessagesForField && Object.keys(errorMessagesForField).length > 0) &&
                  <Row className="alert-row g-0">
                    <Alert dismissible variant="danger" closeVariant="white"
                      onClose={() => setShowErrorMessagesForField(false)}>
                      <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                      <div>
                        {Object.keys(errorMessagesForField)
                          .map((field, i) => (
                            <p key={i} className="alert-text m-0 py-1">{field} {errorMessagesForField[field]?.join(', ')}.</p>))}
                      </div>
                    </Alert>
                  </Row>
                }
                {errorMessages?.length > 0 &&
                  <Row className="alert-row g-0">
                    <Alert dismissible variant="danger" closeVariant="white"
                      onClose={() => setErrorMessages(null)}>
                      <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                      <p className="alert-text m-0">{errorMessages.map((e) => e.message)}</p>
                    </Alert>
                  </Row>
                }
                {errorMessage &&
                  <Row className="alert-row g-0">
                    <Alert dismissible variant="danger" closeVariant="white"
                      onClose={() => setErrorMessage(null)}>
                      <div className="alert-svg-icon my-auto"><VscWarning size="100%"/></div>
                      <p className="alert-text m-0">{errorMessage}</p>
                    </Alert>
                  </Row>
                }
                {!mainError && <>
                  <FormDetailsForm
                    handleSubmit={handleSubmit}
                    type={'create'}
                    handleResetErrorMessages={handleResetErrorMessages}
                    handleResetErrorMessagesForField={handleResetErrorMessagesForField}
                    errorMessagesForField={errorMessagesForField}
                    zuko={zukoRef}
                    saveDisabled={isSubmitting}
                  />
                </>}
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </div>
    </Container>
  );
};

export default FormAdd;
