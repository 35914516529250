import commaNumber from 'comma-number';
import React, { useEffect, useContext, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import { VscWarning } from "react-icons/vsc";

import AppContext from '../AppContext';
import api from '../api';
import DashboardFormCard from './DashboardFormCard';
import { sortDashboardCards, sortOptions } from './Dashboard';
import { orgDetailsForMixpanel } from '../utils';

const formatNumber = commaNumber.bindWith(',', '.');

const DashboardOrgCard = ({mixpanel, org, handleSaveFavForm, handleRemoveFavForm, favFormUuids, firstOrg}) => {
  const { currentUser, currentUserRoleInOrgs } = useContext(AppContext);

  const [orgSessionData, setOrgSessionData] = useState();
  const [orgSessionDataError, setOrgSessionDataError] = useState(null);
  const [formsSessionCount, setFormsSessionCount] = useState();
  const [forms, setForms] = useState();
  const [sortedOn, setSortedOn] = useState();
  const [sortInitiated, setSortInitiated] = useState();
  const [agencyOrg, setAgencyOrg] = useState();
  const [currentUserIsStandardInOrg, setCurrentUserIsStandardInOrg] = useState();

  const showOrgSignUpBtn = () => {
    if (
      currentUserIsStandardInOrg ||
      !org ||
      currentUser.organisations?.length === 1 || // Don't show if they are only part of 1 org as it will show in the nav
      org?.type === 'AgencyClient') return false;

    if (org && ((org.subscriptionStatus && org.subscriptionStatus === 'canceled') ||
     (org.contractType && org.contractType === 'trial'))) return true;
  };

  // Fetch session data for each org
  useEffect(() => {
    const fetchOrgSessionStats = async (org) => {
      try {
        switch (org.contractType) {
        case 'trial':
        case 'fixed': {
          const {data: {count: sessionUsage}} = await api.get('/data/sessions/stats', {
            params: {
              organisationUuid: org.uuid,
              metric: 'count',
            },
          });
          setOrgSessionData({sessionUsage, contractType: org.contractType, sessionLimit: org.sessionLimit});

          break;
        }
        default: {
          const {data: {count: sessionUsage}} = await api.get('/data/sessions/stats', {
            params: {
              organisationUuid: org.uuid,
              metric: 'count',
              timePeriod: {start: 'now/M', end: 'now'},
            },
          });
          setOrgSessionData({sessionUsage, contractType: org.contractType, sessionLimit: org.sessionLimit});
        }}
      } catch (e) {
        setOrgSessionDataError('Oops, sorry something went wrong fetching session totals. Please try again.');
      }
    };

    // Only request org session data for orgs that have forms
    if (org.hasOwnProperty('forms') && org.forms.length > 0) fetchOrgSessionStats(org);

  }, [org]);

  useEffect(() => {
    setForms(org.forms);
  }, [org]);

  // Org form cards sorting
  useEffect(() => {
    if (org?.forms.length && sortedOn && sortInitiated) {
      setForms(sortDashboardCards({parentForms: [...org.forms], sortedOn, formsSessionCount}));
      setSortInitiated(false);
    }

  }, [org?.forms, sortedOn, sortInitiated, formsSessionCount]);

  const handleFormsSort = (opt) => {
    setSortInitiated(true);
    setSortedOn(opt);
    mixpanel.track('Sorted Forms', { page: 'Dashboard', sortedOn: opt.value,  ...orgDetailsForMixpanel(firstOrg)});
  };

  const handleSessionCountFromForm = useCallback(({formUuid, currentCount}) => {
    setFormsSessionCount(prev => ({...prev, [formUuid]: {currentCount}}));
  }, []);

  useEffect(() => {
    if (org.type === 'Agency') {
      (async () => {
        try {
          const {data: { organisation } } =  await api.get(`/organisations/${org.uuid}`, {
            params: {
              include: ['clients'],
            }});

          setAgencyOrg(organisation);
        } catch (e) { /* Do nothing as this is a background update */ }
      })();
    }
  }, [org]);

  useEffect(() => {
    if (!currentUser.accountManager && org) {
      setCurrentUserIsStandardInOrg(currentUserRoleInOrgs?.[org.uuid]?.name !== 'admin' ?? true);
    }
  }, [currentUser?.accountManager, currentUserRoleInOrgs, org]);

  return (
    <Card key={`org-${org.uuid}`} data-testid={`org-${org.uuid}`}>
      <Card.Body className="pb-0">
        <Row className="g-0 card-title-row justify-content-between mb-2">
          <Col className="p-0 d-flex align-items-end col-auto">
            <Card.Title as="h3">{org.type === 'Agency' ? 'Agency | ' : null}{org.name}</Card.Title>
          </Col>
          <Col className="p-0 d-inline-flex align-items-center justify-content-end col-auto">
            {orgSessionDataError && <p className="fw-light mb-0" data-testid="orgs-session-error">{orgSessionDataError}</p>}
            {orgSessionData && orgSessionData.hasOwnProperty('sessionUsage') && orgSessionData.hasOwnProperty('sessionLimit') && orgSessionData.contractType &&
              <p className="session-usage-stats mb-0">{formatNumber(orgSessionData.sessionUsage)} / {formatNumber(orgSessionData.sessionLimit)} {(orgSessionData.contractType)} allowance</p>}
            {showOrgSignUpBtn() &&
              <Link to={{pathname: `/organisations/${org.uuid}/choose-plan`, state: {organisation: {name: org.name}}}}><Button className="sign-up-btn mr-0">Sign Up</Button></Link>}
          </Col>
        </Row>
        <div className="card-vis">
          {org?.type === 'AgencyClient' && org.sessionLimit === 0 &&
            <Row className="alert-row g-0 pb-2" id="users-info-alert">
              <Alert dismissible={false} variant={'warning'}>
                <div className="page-alert-svg-icon d-flex"><VscWarning size="100%"/></div>
                <p className="alert-text m-0">This client does not have a session limit allocated to it. The forms will not currently be tracking.</p>
              </Alert>
            </Row>}
          {org && org.type !== 'Agency' &&
          ((org.forms && org.forms.length <= 0) || (!org.hasOwnProperty('forms'))) && <>
            <p data-testid="no-forms-msg">No forms created yet.</p>
            <Link to="/forms/new"><Button className="btn-less-padding ms-0">Add Form</Button></Link>
          </>}
          {org?.type === 'Agency' && agencyOrg?.clients?.length > 0 && <>
            <p>View forms within your clients.</p>
            <Link to={{
              pathname: `/organisations/${org.uuid}/edit`,
              hash: '#clients'}}><Button className="btn-less-padding ms-0">Manage Clients</Button></Link>
          </>}
          {org?.type === 'Agency' && agencyOrg?.clients?.length === 0 && <p>No clients created yet, please <Link to={`/organisations/${org.uuid}/agency_clients/new`}>add a client</Link>.</p>}
          {forms?.length > 0 && <>
            <Row className="g-0 sort-tools-row">
              <Col className="p-0 col-auto d-flex align-items-center">
                <h4 className="card-tagline my-auto">Total Sessions over the last 30 days</h4>
              </Col>
              <Col className="p-0 d-flex justify-content-end">
                <Form className="d-inline-flex align-items-center">
                  <Form.Label className="me-2 mb-0" htmlFor={`sort-select-${org.uuid}`}>Sort by:</Form.Label>
                  <Select
                    id={`sort-select-${org.uuid}`}
                    styles={{
                      control: (styles, state) => ({...styles,
                        border: '1px solid #EBEDF2',
                      }),
                      option: (styles, state) => ({...styles,
                        color: '#3f4047',
                        backgroundColor: state.selectProps.value && (state.selectProps.value.label === state.label) ? "#E2E5Ec" : null,
                        '&:hover': {backgroundColor: state.isFocused ? '#F4F5F8' : null}
                      }),
                      menu: (styles, state) => ({...styles,
                        marginTop: '1px',
                        borderRadius: '4px',
                        border: '1px solid #EBEDF2',
                        boxShadow: '0 0 15px 1px rgba(113,106,202,.2)',
                      }),
                      singleValue: (styles, state) => ({...styles,
                        color: '#3f4047',
                      }),
                      dropdownIndicator: (styles, state) => ({...styles,
                        cursor: 'pointer',
                        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                        transition: 'transform .5s ease',
                      }),
                    }}
                    options={sortOptions}
                    onChange={handleFormsSort}
                    placeholder="Select..."
                    value={sortedOn}
                    aria-label={'Sorted On'}
                  />
                </Form>
              </Col>
            </Row>
            <div className="form-grid">
              {forms.map((form, i) => (
                <DashboardFormCard
                  form={form}
                  key={`form-${form.uuid}`}
                  handleSessionCountFromForm={handleSessionCountFromForm}
                  displayForms={!sortInitiated}
                  handleSaveFavForm={handleSaveFavForm}
                  handleRemoveFavForm={handleRemoveFavForm}
                  isFavForm={favFormUuids?.includes(form.uuid)}
                />
              ))}
            </div>
          </>}
        </div>
      </Card.Body>
    </Card>
  );
};

export default DashboardOrgCard;
